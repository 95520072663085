@charset "utf-8";
/*------------------------------------------------------------------
[Master Stylesheet]

Project:        epetcare
Version:        1.0
Last change:    03.29.2016
Primary use:    Pet Care, Pets Club, Veterinary & Shop HTML5 Template
Author:         ThemeMascot
URL:            http://themeforest.net/user/ThemeMascot
-------------------------------------------------------------------*/
/*
This file contains the common styles of this theme.
*/
/*------------------------------------------------------------------
[Table of contents]

		* Typography.less
		* Common.less
		* Extra.less
		* Header.less
		* Nav.less
		* Inner-Header-Title.less
		* Vertical-Nav.less
		* Boxed-layout.less
		* Form.less
		* Side-Push-Panel.less
		* Box-Hover-Effect.less
		* gallery-isotope.less
		* Slider.less
		* Home.less
		* About.less
		* Contact.less
		* Event.less
		* Practice-Area.less
		* Services.less
		* Job.less
		* Shop.less
		* Blog.less
		* Shortcode.less 
			* Alerts.less
			* Buttons.less
			* Blockquote.less
			* Clients.less
			* Countdown.less
			* Countdown-Timer.less
			* Dividers.less
			* Facebook-Disqus-Comments.less
			* Faqs.less
			* Flexslider.less
			* Flip-Box.less
			* Font-Icons-Style.less
			* Funfacts.less
			* Icon-Boxes.less
			* Infinitescroll.less
			* Instagram.less
			* Light-Box.less
			* Map.less
			* Pegination.less
			* Pricing.less
			* Process-Steps.less
			* Progress-Bar.less
			* Promo.less
			* Section-Title.less
			* Separators.less
			* styled-icons.less
			* Tabs.less
			* Teams.less
			* Testimonials.less
			* Toggles-Accordions.less
			* Vertical-Timeline.less
			* Working-Process.less
			* Widgets.less
	* Footer
* ===============================================
*/
@import "../css/font-awesome.min.css";
@import "../css/font-awesome-animation.min.css";
@import "../css/pe-icon-7-stroke.css";
/* @import "../css/elegant-icons.css"; */
/* @import "../css/stroke-gap-icons.css"; */
@import "../css/flaticon-set-pet.css";
@import "../css/utility-classes.css";
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700,800|Raleway:400,300,200,500,700,600,800|Roboto+Slab:400,700,300,100);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500;600;700&display=swap);
/*
 * Typography.less
 * -----------------------------------------------
*/
::selection {
  background: #0663d3;
  color: #fff;
  text-shadow: none;
}
::-moz-selection {
  background: #0663d3;
  /* Firefox */
  color: #fff;
  text-shadow: none;
}
::-webkit-selection {
  background: #0663d3;
  /* Safari */
  color: #fff;
  text-shadow: none;
}
:active,
:focus {
  outline: none !important;
}
html {
  font-size: 100%;
}
body {
  line-height: 1.7;
  color: #a9a9a9;
  font-size: 14px;
  font-family: "IBM Plex Sans", "Open Sans", sans-serif !important;
  background-color: #fff;
}
a {
  color: #808080;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #595959;
  text-decoration: none;
}
a img {
  border: none;
}
img {
  max-width: 100%;
}
iframe {
  border: none !important;
}
/* -------- Headings ---------- */
h1,
.h1 {
  font-size: 3.4rem;
}
h2,
.h2 {
  font-size: 2rem;
}
h3,
.h3 {
  font-size: 24px;
}
h4,
.h4 {
  font-size: 17px;
}
h5,
.h5 {
  font-size: 14px;
}
h6,
.h6 {
  font-size: 12px;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Roboto Slab", serif;
  font-weight: 700;
  line-height: 1.42857143;
  color: #555555;
}
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small {
  font-weight: normal;
  line-height: 1;
  color: #555555;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a,
.h1 a,
.h2 a,
.h3 a,
.h4 a,
.h5 a,
.h6 a {
  color: #555555;
}
/* -------- Body Text ---------- */
p,
pre,
ul,
ol,
dl,
dd,
blockquote,
address,
table,
fieldset,
form {
  margin-bottom: 10px;
}
/* -------- Custom Font Family ---------- */
.font-opensans {
  font-family: "Open Sans", sans-serif;
}
.font-raleway {
  font-family: "Raleway", sans-serif;
}
.font-roboto {
  font-family: "Roboto Slab", serif;
}
.font-droid {
  font-family: "Droid Sans", sans-serif;
}
.font-playfair {
  font-family: "Playfair Display", serif;
}
/* breadcrumb */
.breadcrumb {
  background-color: transparent;
  padding-left: 0;
}
.breadcrumb > li + li::before {
  content: "\f22d";
  font-family: fontawesome;
  font-size: 9px;
}
.breadcrumb li.active {
  color: #111111;
}
.breadcrumb.white li.active {
  color: #fff;
}
.breadcrumb.white li a {
  color: #808080;
}

@media only screen and (min-width: 768px) {
  .breadcrumb.text-right {
    text-align: right !important;
  }
}
/*Drop Caps*/
.drop-caps p {
  text-align: justify;
}
.drop-caps p:first-child:first-letter {
  color: #111111;
  display: block;
  float: left;
  font-size: 48px;
  line-height: 48px;
  margin: 6px 3px;
  padding: 10px 18px;
}
.drop-caps.text-colored p:first-child:first-letter {
  display: block;
  float: left;
  font-size: 48px;
  line-height: 48px;
  margin: 6px 3px;
  padding: 10px 18px;
}
.drop-caps.border p:first-child:first-letter {
  border: 1px solid;
  margin: 8px 16px 0 0;
  padding: 15px 20px;
}
.drop-caps.border-rounded p:first-child:first-letter {
  border: 1px solid;
  border-radius: 50%;
  margin: 8px 16px 0 0;
  padding: 15px 20px;
}
.drop-caps.colored-square p:first-child:first-letter {
  color: #fff;
  margin: 8px 16px 0 0;
  padding: 15px 20px;
}
.drop-caps.colored-rounded p:first-child:first-letter {
  color: #fff;
  margin: 8px 16px 0 0;
  padding: 15px 20px;
  border-radius: 50%;
}
.drop-caps.dark-square p:first-child:first-letter {
  background: #222222;
  color: #fff;
  margin: 8px 16px 0 0;
  padding: 15px 20px;
}
.drop-caps.dark-rounded p:first-child:first-letter {
  background: #222222;
  color: #fff;
  margin: 8px 16px 0 0;
  padding: 15px 20px;
  border-radius: 50%;
}
/*Text Highlight*/
.text-highlight {
  background: none repeat scroll 0 0 #111111;
  border-radius: 4px;
  color: #fff;
  padding: 0 5px 3px;
}
.text-highlight.light {
  background: #808080;
}
/*
 * common.less
 * -----------------------------------------------
*/
/* -------- Basic Structure ---------- */
html {
  font-size: 100%;
  max-width: 100%;
}

@media only screen and (max-width: 991px) {
  html {
    font-size: 90%;
  }
  .breadcrumb-content-show {
    display: block;
    max-height: 400px;
  }
  .breadcrumb-content-hide {
    display: none;
    max-height: 400px;
  }
}
@media only screen and (max-width: 767px) {
  html {
    font-size: 85%;
  }
}
@media only screen and (max-width: 479px) {
  html {
    font-size: 80%;
  }
}
html,
html a {
  -webkit-font-smoothing: antialiased;
}
body {
  max-width: 100%;
  overflow-x: hidden;
}
img {
  max-width: 100%;
}
ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}
.fluid-video-wrapper {
  padding: 0;
  position: relative;
  width: 100%;
}
iframe {
  border: none;
  width: 100%;
}
.lead {
  font-size: 18px;
}
.text-highlight {
  background: none repeat scroll 0 0 #111111;
  border-radius: 4px;
  color: #fff;
  padding: 0 5px 3px;
}
.text-highlight.light {
  background: #808080;
}
.inline-block {
  display: inline-block;
}
.display-block {
  display: block;
}
.fullwidth {
  width: 100%;
}
.bg-hover-theme-colored {
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
/* Text-decoration */
.text-underline {
  text-decoration: underline;
}
/* -------- Line-Bottom ---------- */
.heading-line-bottom {
  border-bottom: 1px dashed #808080;
  display: inline-block;
  margin: 0;
  position: relative;
  margin: 20px 0;
}
.heading-line-bottom::after {
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  top: 4px;
  width: 20px;
}
.heading-title {
  margin-top: 10px;
}
.line-bottom {
  position: relative;
  margin-bottom: 30px;
  padding-bottom: 10px;
}
.line-bottom:after {
  bottom: -1px;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  width: 50px;
}
.line-bottom-centered {
  position: relative;
  margin: 10px 0 20px;
}
.line-bottom-centered:after {
  background: #2b96cc none repeat scroll 0 0;
  bottom: -8px;
  content: "";
  height: 1px;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  position: absolute;
  right: 0;
  width: 30px;
}
.title-icon [class^="flaticon-"]::after,
.title-icon [class^="flaticon-"]::before {
  font-size: 48px;
  margin-left: 10px;
}
.title-icon {
  margin: 0 auto;
  max-width: 160px;
  position: relative;
}
.title-icon::after {
  content: "";
  height: 2px;
  left: 24px;
  position: absolute;
  top: 10px;
  width: 30px;
}
.title-icon::before {
  content: "";
  height: 2px;
  position: absolute;
  right: 20px;
  top: 10px;
  width: 30px;
}
/* -------- List ---------- */
ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}
ul ul,
ol ul {
  margin-left: 15px;
}
ul.list {
  list-style: outside none none;
  margin-top: 13px;
  padding-left: 0;
}
ul.list li {
  margin: 5px 0;
  font-weight: 500;
  padding-left: 15px;
  position: relative;
}
ul.list li:before {
  border: none;
  color: #a9a9a9;
  content: "\f111";
  display: inline-block;
  font-family: fontawesome;
  font-size: 9px;
  margin-right: 10px;
  padding: 0;
  left: 0;
  position: absolute;
  top: 1px;
}
ul.list li:hover:before {
  background: none;
  border-color: transparent;
}
ul.list li a {
  text-decoration: none;
  -webkit-transition: all 300ms ease-in;
  -o-transition: all 300ms ease-in;
  transition: all 300ms ease-in;
}
/* -------- List White ---------- */
ul.list.list-white li:before {
  color: #fff;
}
ul.list.list-white li:hover:before {
  background: none;
  border-color: transparent;
}
ul.list.list-white li a {
  color: #fff;
  text-decoration: none;
  -webkit-transition: all 300ms ease-in;
  -o-transition: all 300ms ease-in;
  transition: all 300ms ease-in;
}
/* -------- List border bottom ---------- */
ul.list-border-bottom li {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 10px;
}
ul.list.list-border-bottom li:last-child {
  border-bottom: none;
}
ul.list-border-bottom.no-padding li {
  padding-bottom: 0px;
}
ul.list-border-bottom.no-padding li:last-child {
  border-bottom: none;
}
/* -------- List Icons ---------- */
.list-border li {
  border-bottom: 1px dashed #d3d3d3;
  padding: 10px 0;
}
.dark .list-border li {
  border-bottom: 1px dashed #333333;
}
.list-icon li {
  font-size: 14px;
  margin: 0;
}
.list-icon li i {
  color: #555555;
  font-size: 14px;
  height: 28px;
  line-height: 28px;
  margin: 5px 10px 5px 0;
  text-align: center;
  width: 28px;
}
.list-icon.square li i {
  background: #c0c0c0;
  color: #555555;
  font-size: 12px;
  height: 28px;
  line-height: 28px;
  margin: 5px 10px 5px 0;
  text-align: center;
  width: 28px;
}
.list-icon.rounded li i {
  color: #555555;
  background: #c0c0c0;
  border-radius: 50%;
  font-size: 12px;
  height: 28px;
  line-height: 28px;
  margin: 5px 10px 5px 0;
  text-align: center;
  width: 28px;
}
.list-icon.theme-colored.square li i {
  color: #fff;
}
.list-icon.theme-colored.rounded li i {
  color: #fff;
}
/* -------- Personal Info List ---------- */
.list-info li {
  clear: both;
  position: relative;
}
.list-info li label {
  display: inline-block;
  font-weight: bold;
  padding: 5px 0 0;
}
.list-info li span {
  left: 110px;
  padding: 5px 0;
  position: absolute;
}
.list-features {
  -moz-column-count: 3;
  column-count: 3;
}
.list-dashed > article {
  border-bottom: 1px dashed #d3d3d3 !important;
  margin-bottom: 7px;
  padding-bottom: 7px;
}
.list-dashed > div {
  border-bottom: 1px dashed #d3d3d3 !important;
  margin-bottom: 7px;
  padding-bottom: 7px;
}
.list-dashed > li {
  border-bottom: 1px dashed #d3d3d3 !important;
  margin-bottom: 7px;
  padding-bottom: 7px;
}
.list-dashed.dark-dash > article {
  border-bottom: 1px dashed #333333 !important;
}
.list-dashed.dark-dash > div {
  border-bottom: 1px dashed #333333 !important;
}
.list-dashed.dark-dash > li {
  border-bottom: 1px dashed #333333 !important;
}
ul.list.check li:before {
  content: "\f00c";
  font-family: fontawesome;
  font-size: 12px;
}
ul.list.angle-double-right li:before {
  content: "\f101";
  font-family: fontawesome;
  font-size: 12px;
}
ul.list.angle-right li:before {
  content: "\f105";
  font-family: fontawesome;
}
ul.list.check-circle li:before {
  content: "\f058";
  font-family: fontawesome;
  font-size: 14px;
}
/* -------- Call To Actions ---------- */
.call-to-action {
  width: 100%;
  padding-bottom: 20px;
  padding-top: 20px;
}
/* -------- Section & Divider ---------- */
section {
  position: relative;
}
section > .container,
section > .container-fluid {
  padding-top: 60px;
  padding-bottom: 60px;
}
/* -------- Background Video ---------- */
.bg-video {
  height: 100%;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: -1;
}
.bg-video .video {
  height: 100%;
  left: 0;
  margin: 0 !important;
  position: relative;
  top: 0;
  width: 100%;
}
.bg-video .video .mbYTP_wrapper {
  z-index: -1 !important;
}
/* -------- Box Table ---------- */
.display-table-parent {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.display-table {
  display: table;
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 1;
}
.display-table-cell {
  display: table-cell;
  height: 100%;
  vertical-align: middle;
}
/* -------- Scroll To Top ---------- */
.scrollToTop {
  background: rgba(0, 0, 0, 0.1);
  bottom: 15px;
  display: none;
  height: 50px;
  padding: 2px;
  position: fixed;
  right: 15px;
  text-align: center;
  text-decoration: none;
  width: 50px;
  z-index: 99999;
}
.scrollToTop i {
  color: #fff;
  font-size: 42px;
}
.scrollToTop:hover {
  background: rgba(0, 0, 0, 0.6);
  text-decoration: none;
}
/* -------- magnific popup ---------- */
.mfp-close-btn-in .mfp-close {
  color: #eee;
  font-size: 64px;
}
/*  
 * Extra.less 
 * -----------------------------------------------
*/
/* -------- Heading Style ---------- */
.heading-border {
  border-bottom: 1px solid #d3d3d3;
  margin: 30px 0;
  padding: 20px 0;
}
/* -------- FullCalendar - Plugin ---------- */
.fc-day-number {
  color: #333333;
}
.fc-day-number.fc-other-month {
  opacity: 0.4;
}
/* scrolltofixed-container */
.scrolltofixed-container {
  position: static;
}
/* CSS Floation Animation */
.floating {
  float: left;
  -webkit-animation-name: Floatingx;
  -webkit-animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-name: Floating;
  -moz-animation-duration: 3s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: ease-in-out;
  margin-top: 10px;
}
@-webkit-keyframes Floatingx {
  from {
    -webkit-transform: translate(0, 0px);
  }
  65% {
    -webkit-transform: translate(0, 15px);
  }
  to {
    -webkit-transform: translate(0, 0px);
  }
}
@-moz-keyframes Floating {
  from {
    -moz-transform: translate(0, 0px);
  }
  65% {
    -moz-transform: translate(0, 15px);
  }
  to {
    -moz-transform: translate(0, 0px);
  }
}
.scaling {
  float: left;
  -webkit-animation-name: scalex;
  -webkit-animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-name: scale;
  -moz-animation-duration: 3s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: ease-in-out;
}
@-webkit-keyframes scalex {
  from {
    -webkit-transform: scale(0.9);
  }
  65% {
    -webkit-transform: scale(1);
  }
  to {
    -webkit-transform: scale(0.9);
  }
}
@-moz-keyframes scale {
  from {
    -moz-transform: scale(0.9);
  }
  65% {
    -moz-transform: scale(1);
  }
  to {
    -moz-transform: scale(0.9);
  }
}
/* stylish-header */
.stylish-header {
  background: #ec3642;
  position: relative;
  min-height: 80px;
  padding: 0 0 20px;
}
.stylish-header:before {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  left: -650px;
  width: 650px;
  background: #ec3642;
}
.stylish-header:after {
  content: "";
  position: absolute;
  left: 100%;
  z-index: 999;
  border-top: 80px solid #ec3642;
  border-left: 0px solid transparent;
  border-right: 105px solid transparent;
  bottom: 0;
}
/*
 * Overlays.less
 * -----------------------------------------------
*/
.layer-overlay {
  position: relative;
  /* overlay-dark */
  /* overlay-white */
}
.layer-overlay::before {
  background: #ff6b00;
  /* background: -webkit-linear-gradient(right, #ff7701, #ff6201);
  background: -moz-linear-gradient(right, #ff7701, #ff6201);
  background: linear-gradient(to left, #ff7701, #ff6201); */
  /* background: #fe7321;
  background: -webkit-linear-gradient(right, #fe7321, #fb5d01);
  background: -moz-linear-gradient(right, #fe7321, #fb5d01);
  background: linear-gradient(to left, #fe7321, #fb5d01); */

  /* background: #ff670e none repeat scroll 0 0; */
  content: " ";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
.layer-overlay.overlay-light::before {
  background-color: rgba(255, 255, 255, 0.4);
}
.layer-overlay.overlay-lighter::before {
  background-color: rgba(255, 255, 255, 0.3);
}
.layer-overlay.overlay-deep::before {
  background-color: rgba(255, 255, 255, 0.9);
}
.layer-overlay.overlay-dark::before {
  background-color: rgba(17, 17, 17, 0.85);
}
.layer-overlay.overlay-dark-1::before {
  background-color: rgba(17, 17, 17, 0.1);
}
.layer-overlay.overlay-dark-2::before {
  background-color: rgba(17, 17, 17, 0.2);
}
.layer-overlay.overlay-dark-3::before {
  background-color: rgba(17, 17, 17, 0.3);
}
.layer-overlay.overlay-dark-4::before {
  background-color: rgba(17, 17, 17, 0.4);
}
.layer-overlay.overlay-dark-5::before {
  background-color: rgba(17, 17, 17, 0.5);
}
.layer-overlay.overlay-dark-6::before {
  background-color: rgba(17, 17, 17, 0.6);
}
.layer-overlay.overlay-dark-7::before {
  background-color: rgba(17, 17, 17, 0.7);
}
/* .layer-overlay.overlay-dark-8::before {
  background-color: rgba(17, 17, 17, 0.8);
} */
.layer-overlay.overlay-dark-9::before {
  background-color: rgba(17, 17, 17, 0.9);
}
.layer-overlay.overlay-white::before {
  background-color: rgba(255, 255, 255, 0.4);
}
.layer-overlay.overlay-white-1::before {
  background-color: rgba(255, 255, 255, 0.1);
}
.layer-overlay.overlay-white-2::before {
  background-color: rgba(255, 255, 255, 0.2);
}
.layer-overlay.overlay-white-3::before {
  background-color: rgba(255, 255, 255, 0.3);
}
.layer-overlay.overlay-white-4::before {
  background-color: rgba(255, 255, 255, 0.4);
}
.layer-overlay.overlay-white-5::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.layer-overlay.overlay-white-6::before {
  background-color: rgba(255, 255, 255, 0.6);
}
.layer-overlay.overlay-white-7::before {
  background-color: rgba(255, 255, 255, 0.7);
}
.layer-overlay.overlay-white-8::before {
  background-color: rgba(255, 255, 255, 0.8);
}
.layer-overlay.overlay-white-9::before {
  background-color: rgba(255, 255, 255, 0.9);
}
.layer-overlay.layer-pattern::before {
  background-image: url(../images/pattern1.png);
  background-color: rgba(0, 0, 0, 0.6);
}
.layer-overlay.layer-pattern2::before {
  background-image: url(../images/pattern2.png);
  background-color: rgba(0, 0, 0, 0.8);
}
.layer-overlay.layer-pattern3::before {
  background-image: url(../images/pattern3.png);
  background-color: rgba(0, 0, 0, 0.8);
}
.layer-overlay.maximage-layer-overlay::before {
  z-index: 1;
}
.layer-overlay.overlay-blue::before {
  background-color: rgba(1, 162, 208, 0.9);
}
.layer-overlay.overlay-blue-light::before {
  background-color: rgba(1, 162, 208, 0.45);
}
.layer-overlay.overlay-pink::before {
  background-color: rgba(235, 110, 142, 0.9);
}
.layer-overlay.overlay-pink-light::before {
  background-color: rgba(235, 110, 142, 0.45);
}
.layer-overlay.overlay-brown::before {
  background-color: rgba(60, 171, 111, 0.9);
}
.layer-overlay.overlay-brown-light::before {
  background-color: rgba(60, 171, 111, 0.45);
}
.layer-overlay.overlay-yellow::before {
  background-color: rgba(255, 187, 34, 0.9);
}
.layer-overlay.overlay-yellow-light::before {
  background-color: rgba(255, 187, 34, 0.45);
}
.layer-overlay.overlay-green::before {
  background-color: rgba(16, 196, 92, 0.9);
}
.layer-overlay.overlay-green-light::before {
  background-color: rgba(16, 196, 92, 0.45);
}
.layer-overlay.overlay-theme-color-1::before {
  background-color: rgba(173, 216, 230, 0.9);
}
.layer-overlay.overlay-theme-color-2::before {
  background-color: rgba(230, 173, 188, 0.9);
}
.layer-overlay.overlay-theme-color-3::before {
  background-color: rgba(230, 216, 173, 0.9);
}
.half-divider .overlay-theme-color-1,
.half-divider .overlay-theme-color-2,
.half-divider .overlay-theme-color-3 {
  content: "";
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
}
/*
 * Section: Header
 * -----------------------------------------------
*/
.header-top {
  padding: 2px 0;
}
.header-top .header-widget .styled-icons a i:hover {
  background: none;
}
.header-top .header-widget .list-border li {
  border-right: 1px solid rgba(0, 0, 0, 0.06);
  padding: 5px 15px;
}
.header-top .header-widget .list-border li:last-child {
  border-right: 0;
}
@media only screen and (max-width: 767px) {
  .header-top .header-widget .contact-info {
    margin-bottom: 20px;
  }
  .header-top .header-widget .contact-info li {
    display: block;
    text-align: center;
  }
}
/* .menuzord-brand img {
  max-height: 55px;
} */
/* Inner Header */
.inner-header .title {
  text-transform: uppercase;
}
/* Custom theme style */
.header-nav .header-nav-wrapper .menuzord {
  padding: 0;
}
.menuzord-menu > li {
  margin-bottom: 0;
  margin-top: 0;
}
.menuzord-menu > li > a {
  color: #555555;
  font-size: 13px;
  font-weight: 400;
}
/*
 * Nav.less
 * -----------------------------------------------
*/
/* -------- Nav Sidebar ---------- */
.nav-sidebar li {
  background: #f1f1f1;
  padding-bottom: 0 !important;
}
.nav-sidebar li a {
  border-radius: 0;
}
.nav-pills li a:hover,
.nav-pills li.active > a,
.nav-pills li.active > a:hover,
.nav-pills li.active > a:focus {
  background: #d3d3d3;
  color: #777777;
}
/* -------- Header Nav ---------- */
.header-nav {
  position: relative;
}
.header-nav .header-nav-absolute-wrapper {
  width: 100%;
}
.header-nav .header-nav-wrapper .menuzord {
  padding: 0;
}
/* -------- Header Nav Floating ---------- */
@media only screen and (min-width: 901px) {
  .header-nav-floating {
    position: absolute;
    margin: 0px auto;
    left: 0px;
    right: 0px;
    top: -36px;
    z-index: 1111;
  }
  .header-nav-floating .menuzord-menu > li > a {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
  }
  .header-nav-floating .menuzord.green .menuzord-menu > li.active > a,
  .header-nav-floating .menuzord.green .menuzord-menu > li:hover > a,
  .header-nav-floating .menuzord.green .menuzord-menu ul.dropdown li:hover > a {
    background: #37bc9b none repeat scroll 0 0;
  }
}
/* -------- Menuzord ---------- */
.menuzord-menu > li > a {
  /* color: #333333; */
  color: #ffe1d9;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  font-family: Montserrat;
  font-weight: 500;
  border-bottom: 2px solid #fc623a;
}
.menuzord-menu.white > li > a {
  color: #fff;
}
.menuzord .menuzord-menu > li.active > a i,
.menuzord .menuzord-menu > li:hover > a i,
.menuzord .menuzord-menu ul.dropdown li:hover > a i {
  /*color: #fff;*/
}
.menuzord .menuzord-menu ul.dropdown,
.menuzord .menuzord-menu .megamenu {
  border: 1px solid #f0f0f0;
}
.menuzord-menu ul.dropdown li {
  margin: 0;
}
.menuzord-menu ul.dropdown li a {
  padding: 7px 25px 7px 22px;
}
.menuzord-menu ul.dropdown li .indicator {
  top: 4px;
}
/* -------- Megamenu-Row ---------- */
.menuzord-menu > li > .megamenu .megamenu-row li {
  border: 0 none;
  clear: both;
  font-size: 12px;
  margin: 0;
  padding: 2px;
  position: relative;
  width: 100%;
}
.menuzord-menu > li > .megamenu .megamenu-row li a {
  display: block;
  padding: 8px 10px;
  -webkit-transition: padding 0.2s linear;
  -o-transition: padding 0.2s linear;
  transition: padding 0.2s linear;
}
.menuzord-menu > li > .megamenu .megamenu-row li.active > a,
.menuzord-menu > li > .megamenu .megamenu-row li:hover > a {
  color: #111;
  padding-left: 15px;
}
.menuzord-menu > li > .megamenu .megamenu-row li a i {
  color: #444;
}
.menuzord-menu > li > .megamenu .megamenu-row li.active a i,
.menuzord-menu
  > li
  > .menuzord-menu
  > li
  > .megamenu
  .megamenu-row
  li:hover
  a
  i {
  color: #fff;
}
@media only screen and (max-width: 900px) {
  .menuzord-menu {
    background: transparent;
  }
}
/* -------- Scrolltofixed ---------- */
/* .header-nav .header-nav-wrapper.scroll-to-fixed-fixed.scroll-to-fixed-fixed {
  border-bottom: 1px solid #eeeeee;
} */
.header-nav .header-nav-wrapper.scroll-to-fixed-fixed.scroll-to-fixed-fixed {
  background-color: transparent !important;
}
.header-nav
  .header-nav-absolute-wrapper.scroll-to-fixed-fixed.scroll-to-fixed-fixed
  .menuzord {
  border-right: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  border-left: 1px solid #eeeeee;
}
.header .inner-sticky-wrapper {
  height: 80px;
}
@media only screen and (min-width: 901px) {
  .header-nav .header-nav-absolute-wrapper {
    bottom: -36px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    z-index: 1111;
  }
  .header-nav
    .header-nav-absolute-wrapper.scroll-to-fixed-fixed.scroll-to-fixed-fixed {
    bottom: auto;
  }
}
/* -------- Navbar Fixed  ---------- */
.header-nav {
  -webkit-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
.header-nav.navbar-fixed-top {
  left: 0;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 1030;
}
/* -------- Navbar Sticky Animated ---------- */
.navbar-sticky-animated .header-nav-wrapper .container {
  -webkit-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
.navbar-sticky-animated .add-padding {
  padding-top: 10px;
  padding-bottom: 10px;
}
.navbar-sticky-animated.extra-padding-top .add-padding {
  padding-top: 50px;
}
/* -------- Header White ---------- */
.navbar-white {
  background-color: rgba(255, 255, 255, 0.95);
  border: medium none;
}
.navbar-white .menuzord {
  background: transparent;
}
.navbar-dark {
  background-color: rgba(17, 17, 17, 0.95);
  border: medium none;
}
.navbar-dark .menuzord {
  background: transparent;
}
.navbar-dark .menuzord-menu > li > a {
  color: #ddd;
}
.navbar-dark.header-nav-wrapper,
.navbar-dark .header-nav-wrapper {
  border-bottom-color: #333333 !important;
}
@media only screen and (max-width: 900px) {
  .navbar-dark .menuzord-responsive .menuzord-menu li a {
    background-color: #333;
    border-color: #444;
  }
  .navbar-dark .menuzord-responsive .menuzord-menu ul.dropdown,
  .navbar-dark .menuzord-responsive .menuzord-menu ul.dropdown li ul.dropdown {
    background-color: #333;
    border-color: #444;
  }
  .navbar-dark .menuzord-responsive .menuzord-menu ul.dropdown li a {
    border-bottom-color: #444;
  }
  .navbar-dark .menuzord-responsive .menuzord-menu li .indicator {
    border-left-color: #444;
  }
}
/* -------- Header Transparent ---------- */
.header-nav.navbar-transparent .menuzord {
  background: transparent;
}
.header-nav.navbar-transparent .header-nav-wrapper {
  border-bottom: 1px solid #eeeeee;
}
.header-nav.navbar-transparent.animated-active {
  background-color: rgba(0, 0, 0, 0);
}
.header-nav.navbar-transparent.animated-active.bg-transparent-1 {
  background-color: rgba(0, 0, 0, 0.05);
}
.header-nav.navbar-transparent.animated-active.bg-transparent-2 {
  background-color: rgba(0, 0, 0, 0.1);
}
.header-nav.navbar-transparent.animated-active.bg-transparent-3 {
  background-color: rgba(0, 0, 0, 0.2);
}
.header-nav.navbar-transparent.animated-active.bg-transparent-4 {
  background-color: rgba(0, 0, 0, 0.3);
}
.header-nav.navbar-transparent.animated-active.bg-transparent-5 {
  background-color: rgba(0, 0, 0, 0.4);
}
.header-nav.navbar-transparent.animated-active .header-nav-wrapper {
  border-bottom: none !important;
}
.header-nav.navbar-transparent.animated-active .menuzord-menu > li > a {
  color: #fff;
}
.header-nav.navbar-transparent.transparent-dark.animated-active {
  background-color: rgba(0, 0, 0, 0.25);
}
.header-nav.navbar-transparent.transparent-dark-deep.animated-active {
  background-color: rgba(0, 0, 0, 0.8);
}
.header-nav.navbar-transparent.transparent-dark-deep .menuzord-menu > li > a {
  color: #808080;
}
.header-nav.navbar-transparent.transparent-white.animated-active {
  background-color: rgba(255, 255, 255, 0.5);
}
.header-nav.navbar-transparent.transparent-white-deep.animated-active {
  background-color: rgba(255, 255, 255, 0.8);
}
.header-nav.navbar-transparent.transparent-white-deep .menuzord-menu > li > a {
  color: #808080;
}
@media only screen and (max-width: 900px) {
  .header-nav.navbar-transparent.animated-active .menuzord-menu > li > a {
    color: #808080;
  }
}
@media only screen and (min-width: 901px) {
  /* menuzord text white */
  .menuzord-text-white .menuzord-menu > li > a {
    color: #fff;
  }
  .menuzord-text-white .menuzord-menu > li.active > a,
  .menuzord-text-white .menuzord-menu > li:hover > a {
    color: rgba(255, 255, 255, 0.8) !important;
  }
}
/*
 * Inner-Header-Title.less
 * -----------------------------------------------
*/
.inner-header .title {
  font-size: 2rem;
}
/*
 *  Vertical-Nav.less
 * -----------------------------------------------
*/
.vertical-nav {
  /* vertical-nav-black*/
}
.vertical-nav .header {
  width: 260px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background: #fff;
  z-index: 101;
  padding: 0;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
  background-size: cover !important;
}
.vertical-nav .main-content,
.vertical-nav .footer {
  margin-left: 260px;
}
.vertical-nav .container {
  max-width: 940px;
}
.vertical-nav .menuzord {
  background: none;
}
.vertical-nav .menuzord .menuzord-menu ul.dropdown li:hover > a {
  background: #eeeeee none repeat scroll 0 0;
  color: #111111;
}
.vertical-nav .menuzord-menu li {
  padding: 0;
  position: relative;
  width: 100%;
}
.vertical-nav .menuzord-menu li .indicator {
  float: right;
  display: block;
  margin-right: 6px;
}
.vertical-nav .menuzord-menu li a {
  padding: 5px 0 5px 30px;
  width: 100%;
}
.vertical-nav .menuzord-menu > li {
  padding: 5px 0;
}
.vertical-nav .menuzord-menu ul.dropdown,
.vertical-nav .menuzord-menu .megamenu {
  left: 100%;
  top: 4px;
}
.vertical-nav .menuzord-brand img {
  max-height: 52px;
}
.vertical-nav .vertical-nav-black {
  background: #111111;
}
.vertical-nav .vertical-nav-black .menuzord-menu ul.dropdown {
  background: #111111;
  border-color: #222222;
}
.vertical-nav .vertical-nav-black .menuzord-menu ul.dropdown li a {
  background: #111111;
}
.vertical-nav .vertical-nav-black .menuzord-menu ul.dropdown li a .indicator i {
  color: #fff;
}
.vertical-nav .vertical-nav-black .menuzord-menu ul.dropdown li:hover > a {
  background: #111111 none repeat scroll 0 0;
  color: #fff;
}
.vertical-nav .vertical-nav-black .menuzord-menu > li > a {
  color: #808080;
}
.vertical-nav.vertical-nav-right .header {
  left: auto;
  right: 0;
}
.vertical-nav.vertical-nav-right .main-content,
.vertical-nav.vertical-nav-right .footer {
  margin-left: auto;
  margin-right: 260px;
  padding-top: 0;
}
.vertical-nav.vertical-nav-right .menuzord-menu ul.dropdown,
.vertical-nav.vertical-nav-right .menuzord-menu .megamenu {
  left: auto !important;
  right: 100% !important;
  top: 0;
}
@media only screen and (max-width: 1199px) {
  .vertical-nav .container {
    width: 100%;
  }
}
@media only screen and (max-width: 900px) {
  .vertical-nav .header {
    border: 0;
    padding: 0;
    position: relative;
    width: 100%;
  }
  .vertical-nav .vertical-nav-widget {
    display: none;
  }
  .vertical-nav .menuzord {
    background: none;
  }
  .vertical-nav .menuzord-menu {
    background: none;
  }
  .vertical-nav .menuzord-brand {
    margin: 0 !important;
    padding: 0 !important;
  }
  .vertical-nav .menuzord-brand img {
    max-height: 65px !important;
    padding: 10px;
  }
  .vertical-nav .header-nav-wrapper {
    background: #111111 none repeat scroll 0 0;
    position: fixed;
    top: 0;
    width: 100%;
  }
  .vertical-nav .vertical-nav-black .menuzord .menuzord-menu li > a,
  .vertical-nav .vertical-nav-black .menuzord .menuzord-menu li .indicator {
    border-color: #222222;
  }
  .vertical-nav .main-content,
  .vertical-nav .footer {
    margin-left: 0;
  }
  .vertical-nav.vertical-nav-right .main-content,
  .vertical-nav.vertical-nav-right .footer {
    margin-right: 0;
  }
}
/*
 *  menu-full-page.less
 * -----------------------------------------------
*/
/* menu-full-page plugin code */
.menu-full-page #fullpage-nav {
  background: rgba(255, 255, 255, 0.97);
  z-index: -1;
  position: fixed;
  /*left: -100%;*/
  /*left or right and the width of your navigation panel*/
  left: 0 !important;
  /*left or right and the width of your navigation panel*/
  opacity: 0;
  width: 100%;
  /*should match the above value*/
  -webkit-transition: all 0.5s ease-in-out !important;
  -moz-transition: all 0.5s ease-in-out !important;
  -ms-transition: all 0.5s ease-in-out !important;
  -o-transition: all 0.2s ease-in-out !important;
  transition: all 0.5s ease-in-out !important;
}
.menu-full-page #fullpage-nav ul {
  margin-top: 50px;
  -webkit-transition: all 0.7s ease-in-out !important;
  -moz-transition: all 0.7s ease-in-out !important;
  -ms-transition: all 0.7s ease-in-out !important;
  -o-transition: all 0.7s ease-in-out !important;
  transition: all 0.7s ease-in-out !important;
}
.menu-full-page #fullpage-nav li {
  text-align: center;
  transition: all 0.4s ease;
  padding: 10px 0;
  position: relative;
}
.menu-full-page #fullpage-nav li a {
  color: #111111;
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  text-decoration: none;
  font-weight: 400;
  text-transform: uppercase;
  padding: 0;
  position: relative;
}
.menu-full-page #fullpage-nav li a:hover {
  color: #808080;
  -webkit-transition: color 2s time;
  -o-transition: color 2s time;
  transition: color 2s time;
}
.menu-full-page #fullpage-nav li:hover:after a {
  position: absolute;
  content: "";
  color: #fff;
}
.menu-full-page #fullpage-nav ul ul {
  display: none;
}
.menu-full-page #fullpage-nav ul ul li a {
  font-size: 13px;
}
.menu-full-page #fullpage-nav ul ul li a:hover {
  color: #808080;
}
.menu-full-page .fullpage-nav-toggle.menu-open + #fullpage-nav {
  opacity: 1;
  z-index: 998;
}
.menu-full-page .fullpage-nav-toggle.menu-open + #fullpage-nav > ul {
  margin-top: 70px;
}
.menu-full-page .fullpage-nav-toggle {
  background: #fff;
  z-index: 999;
  display: block;
  position: fixed;
  top: 5px;
  right: 5px;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 55px;
  height: 48px;
  font-size: 0;
  text-indent: -9999px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  border-radius: none;
  border: none;
  cursor: pointer;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
  /* active state, i.e. menu open */
}
.menu-full-page .fullpage-nav-toggle i {
  text-decoration: none;
  font-size: 40px;
  color: #fff;
}
.menu-full-page .fullpage-nav-toggle:focus {
  outline: none;
}
.menu-full-page .fullpage-nav-toggle span {
  display: block;
  position: absolute;
  top: 22px;
  left: 12px;
  right: 12px;
  height: 4px;
  border-radius: 2px;
  background: #808080;
  -webkit-transition: background 0 0.3s;
  transition: background 0 0.3s;
}
.menu-full-page .fullpage-nav-toggle span:before,
.menu-full-page .fullpage-nav-toggle span:after {
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: 4px;
  border-radius: 0;
  background-color: #808080;
  content: "";
  -webkit-transition-duration: 0.3s, 0.3s;
  transition-duration: 0.3s, 0.3s;
  -webkit-transition-delay: 0.3s, 0;
  transition-delay: 0.3s, 0;
}
.menu-full-page .fullpage-nav-toggle span:before {
  top: -10px;
  -webkit-transition-property: top, -webkit-transform;
  transition-property: top, transform;
}
.menu-full-page .fullpage-nav-toggle span:after {
  bottom: -10px;
  -webkit-transition-property: bottom, -webkit-transform;
  transition-property: bottom, transform;
}
.menu-full-page .fullpage-nav-toggle.menu-open {
  background-color: transparent;
}
.menu-full-page .fullpage-nav-toggle.menu-open span {
  background: none;
}
.menu-full-page .fullpage-nav-toggle.menu-open span:before,
.menu-full-page .fullpage-nav-toggle.menu-open span:after {
  background-color: #111111;
  -webkit-transition-delay: 0, 0.3s;
  transition-delay: 0, 0.3s;
}
.menu-full-page .fullpage-nav-toggle.menu-open span:before {
  top: 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.menu-full-page .fullpage-nav-toggle.menu-open span:after {
  bottom: 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
/* menu-full-page custom code */
.menu-full-page .header #fullpage-nav li {
  padding: 5px 0;
}
.menu-full-page .header #fullpage-nav li a {
  background: none;
  display: inline;
  font-size: 18px;
  text-transform: none;
}
.menu-full-page .header #fullpage-nav ul ul {
  margin-top: 0;
}
.menu-full-page .header #fullpage-nav ul ul li {
  padding: 3px 0;
}
.menu-full-page .header #fullpage-nav ul ul li a {
  font-size: 12px;
}
.menu-full-page .header #fullpage-nav ul li .dropdown {
  background: none;
  box-shadow: none;
  border: none;
  float: none;
  padding: 0;
  position: relative;
  max-height: 0;
  overflow: hidden;
  -webkit-transition: all 0.9s cubic-bezier(0.55, 0, 0.175, 1) !important;
  -moz-transition: all 0.9s cubic-bezier(0.55, 0, 0.175, 1) !important;
  -ms-transition: all 0.9s cubic-bezier(0.55, 0, 0.175, 1) !important;
  -o-transition: all 0.9s cubic-bezier(0.55, 0, 0.175, 1) !important;
  transition: all 0.9s cubic-bezier(0.55, 0, 0.175, 1) !important;
  display: block !important;
  visibility: visible;
}
.menu-full-page .header #fullpage-nav ul li:hover .dropdown {
  max-height: 700px;
}
/*
 *  Boxed-layout.less
 * -----------------------------------------------
*/
@media only screen and (min-width: 768px) {
  .boxed-layout .container {
    max-width: 1170px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .boxed-layout .header,
  .boxed-layout .main-content,
  .boxed-layout .footer,
  .boxed-layout #footer {
    margin: 0 auto;
    max-width: 1170px;
  }
}
.boxed-layout .header,
.boxed-layout section,
.boxed-layout .footer,
.boxed-layout #footer {
  background-color: #fff;
}
.boxed-layout .header {
  margin-bottom: 0;
}
.boxed-layout .header .navbar-default {
  background-color: #fff;
  border-color: #fff;
  padding: 0;
}
.boxed-layout .header .navbar-default .navbar-nav > li > a {
  padding: 30px 5px;
}
.boxed-layout .header .navbar-default .navbar-collapse {
  background-color: #fff;
  border-color: #fff;
  margin-right: 30px;
}
.boxed-layout .header #header-logo {
  margin: 0;
  padding-top: 27px;
}
.boxed-layout .maximage-slider #maximage {
  z-index: 0;
}
.boxed-layout .stylish-header:before {
  position: relative;
  left: auto;
}
@media only screen and (max-width: 991px) {
  .boxed-layout {
    padding-top: 0;
  }
  .boxed-layout .header .navbar-default .navbar-nav > li > a {
    padding: 10px;
  }
  .boxed-layout .header .navbar-default .navbar-collapse {
    margin-right: 15px;
  }
  .boxed-layout .navbar-header {
    padding: 15px 0;
  }
  .boxed-layout .navbar-collapse .navbar-nav li a .caret {
    margin-right: 0;
  }
}
/*
 * Form.less
 * -----------------------------------------------
*/
/* -------- Form ---------- */
.input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
  font-size: 14px;
}
.form-control {
  height: 45px;
  border-radius: 0;
}
.input-group-addon {
  border-radius: 0;
}
.input-group-addon i {
  color: #999999;
}
label {
  font-weight: normal;
}
.form-control:focus,
form input[type="text"]:focus,
form input[type="email"]:focus,
form input[type="number"]:focus,
form input[type="url"]:focus,
form input[type="search"]:focus,
form input[type="tel"]:focus,
form input[type="password"]:focus,
form input[type="date"]:focus,
form input[type="color"]:focus,
form select:focus,
form textarea:focus {
  color: #404040;
  border-color: rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  outline: none;
}
.form-transparent .form-control {
  background: transparent;
  color: #555555;
}
.form-transparent .form-control:focus,
.form-transparent form input[type="text"]:focus,
.form-transparent form input[type="email"]:focus,
.form-transparent form input[type="number"]:focus,
.form-transparent form input[type="url"]:focus,
.form-transparent form input[type="search"]:focus,
.form-transparent form input[type="tel"]:focus,
.form-transparent form input[type="password"]:focus,
.form-transparent form input[type="date"]:focus,
.form-transparent form input[type="color"]:focus,
.form-transparent form select:focus,
.form-transparent form textarea:focus {
  color: #555555;
}
.form-white .form-control {
  background: #fff;
}
.form-transparent.form-line .form-control {
  border: none;
  border-bottom: 1px solid #c0c0c0;
  padding-left: 0;
}
.form-transparent.form-line textarea.form-control {
  height: 70px;
}
.form-text-white .form-control {
  color: #f7f7f7;
}
.form-text-white .form-control {
  color: #dcdcdc;
}
.form-text-white .form-control:focus {
  color: #f7f7f7;
  border-color: #f7f7f7;
}
.form-text-white select.form-control:focus {
  color: #555555;
}
form label {
  font-weight: bold;
}
/* form error */
form .form-control.error {
  border: 2px solid #ee163b;
}
form .error {
  color: #ee163b;
}
/* -------- Donation Form ---------- */
.donation-form .form-group label.radio-inline {
  color: #808080 !important;
}
/*-------- Form-Text-White ---------*/
.form-text-white .form-group label {
  color: #fff;
}
/*-------- Booking Form ---------*/
.booking-form input,
.booking-form .btn {
  border-radius: 36px;
}
.booking-form .form-group {
  margin-bottom: 30px;
  position: relative;
}
.booking-form .styled-select::after {
  border-radius: 0 36px 36px 0;
  bottom: 0;
  color: #333333;
  content: "\f107";
  font-size: 18px;
  font-family: fontAwesome;
  padding: 12px 20px 0;
  pointer-events: none;
  position: absolute;
  right: 0px;
  top: 0;
}
.booking-form .styled-select select {
  border-radius: 36px;
  color: #333333;
  font-size: 13px;
  height: 45px;
  margin: 0;
  outline: medium none;
  padding: 6px 5%;
  width: 100%;
  -moz-appearance: none;
}
.booking-form .styled-select .form-control option {
  color: #333333;
  font-size: 14px;
  padding: 2px 0;
}
/*-------- Booking Form Blue ---------*/
.booking-form.form-blue input {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 36px;
}
.booking-form.form-blue .form-group {
  position: relative;
}
.booking-form.form-blue input::-webkit-placeholder,
.booking-form.form-blue input::-moz-placeholder,
.booking-form.form-blue input::-ms-placeholder {
  color: #eeeeee;
}
.booking-form.form-blue .form-control::-moz-placeholder {
  color: #eeeeee;
}
.booking-form.form-blue .form-control:focus,
.booking-form.form-blue .form-control:active {
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: #eeeeee;
}
.booking-form.form-blue .form-control {
  border-color: #c0c0c0;
  color: #fff;
}
.booking-form.form-blue .styled-select::after {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0 36px 36px 0;
  bottom: 0;
  color: #fff;
  content: "\f107";
  font-size: 18px;
  font-family: fontAwesome;
  padding: 12px 20px 0;
  pointer-events: none;
  position: absolute;
  right: 0px;
  top: 0;
}
.booking-form.form-blue .styled-select select {
  background: rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.2);
  border-radius: 36px;
  color: #eeeeee;
  font-size: 13px;
  height: 45px;
  margin: 0;
  outline: medium none;
  padding: 6px 5%;
  width: 100%;
  -moz-appearance: none;
}
.booking-form.form-blue .styled-select .form-control option {
  background: #fff none repeat scroll 0 0;
  color: #333333;
  font-size: 14px;
  padding: 2px 0;
}
.booking-form.form-blue .btn {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: #eeeeee;
  border-radius: 36px;
}
.booking-form.form-blue .btn:hover {
  background-color: #333333 !important;
}
.btn-submit:active,
.btn-submit:focus {
  border: 1px solid transparent !important;
  box-shadow: none;
}
/*-------- Booking Form Home ---------*/
.booking-form.form-home input,
.booking-form.form-home a.btn,
.booking-form.form-home .styled-select select {
  border-radius: inherit;
}
.booking-form.form-home {
  border-radius: 4px;
}
.booking-form.form-home input {
  background: #fff;
}
.booking-form.form-home .form-group {
  margin-bottom: 15px;
}
.booking-form.form-home .form-control:focus,
.booking-form.form-home .form-control:active {
  background: rgba(255, 255, 255, 0.9);
}
/* Project Started */
.half-divider .half {
  content: "";
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
}
.relative {
  position: relative;
}
/*
 *  Side-Push-Panel.less
 * -----------------------------------------------
*/
.side-push-panel {
  overflow-x: hidden;
}
.side-push-panel.fullwidth-page #wrapper {
  right: 0;
  position: relative;
}
.side-push-panel.fullwidth-page
  header
  .header-nav-wrapper.scroll-to-fixed-fixed
  .container {
  right: 0;
}
.side-push-panel.fullwidth-page.side-panel-left #wrapper {
  left: 0;
  right: auto;
}
.side-push-panel.fullwidth-page.side-panel-left
  header
  .header-nav-wrapper.scroll-to-fixed-fixed
  .container {
  left: 0;
  right: auto;
}
.side-push-panel.fullwidth-page.side-panel-open #wrapper {
  right: 300px;
}
.side-push-panel.fullwidth-page.side-panel-open
  header
  .header-nav-wrapper.scroll-to-fixed-fixed
  .container {
  right: 300px;
}
.side-push-panel.fullwidth-page.side-panel-left.side-panel-open #wrapper {
  left: 300px;
  right: auto;
}
.side-push-panel.fullwidth-page.side-panel-left.side-panel-open
  header
  .header-nav-wrapper.scroll-to-fixed-fixed
  .container {
  left: 300px;
  right: auto;
}
/* -------- Has Side Panel ---------- */
.side-push-panel.has-side-panel .menuzord .showhide {
  float: left;
}
/* -------- Body Overlay ---------- */
.has-side-panel .body-overlay {
  -webkit-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -1;
}
.has-side-panel.side-panel-open .body-overlay {
  opacity: 1;
  pointer-events: auto;
  z-index: 1111;
}
.side-panel-open:not(.device-xxs):not(.device-xs):not(.device-sm)
  .body-overlay:hover {
  cursor: url("../images/close.png") 15 15, default;
}
/* -------- Side Panel ---------- */
#side-panel {
  -webkit-backface-visibility: hidden;
  background-color: #f3f3f3;
  height: 100%;
  overflow: hidden;
  position: fixed;
  right: -300px;
  top: 0;
  width: 300px;
  z-index: 1112;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
#side-panel .side-panel-wrap {
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: auto;
  padding: 50px 70px 50px 40px;
  position: absolute;
  top: 0;
  width: 330px;
}
#side-panel .widget {
  margin-top: 40px;
  padding-top: 40px;
  width: 220px;
}
#side-panel .widget:first-child {
  border-top: 0;
  margin: 0;
  padding-top: 0;
}
.side-panel-open #side-panel {
  right: 0;
}
.side-panel-left #side-panel {
  left: -300px;
  right: auto;
}
.side-panel-left.side-panel-open #side-panel {
  left: 0;
  right: auto;
}
.device-touch #side-panel .side-panel-wrap {
  overflow-y: scroll;
}
/* -------- Side-Panel-Nav ---------- */
#side-panel .side-panel-nav .nav li a {
  padding: 5px 0;
}
#side-panel .side-panel-nav .nav li a:hover {
  background-color: transparent;
  color: #a9a9a9;
}
#side-panel .side-panel-nav .nav ul.tree {
  display: none;
  margin-left: 15px;
}
/* -------- Side-Panel-Trigger And Close ---------- */
#side-panel-trigger {
  cursor: pointer;
  display: block;
  float: right;
  margin-left: 10px;
  margin-right: 5px;
  margin-top: 25px;
  z-index: 11;
}
@media only screen and (max-width: 767px) {
  #side-panel-trigger {
    margin-top: 15px;
  }
}
#side-panel-trigger-close {
  left: 0;
  margin: 10px;
  position: absolute;
  top: 0;
}
/* -------- Transition ---------- */
.side-push-panel.fullwidth-page #wrapper,
.side-push-panel.fullwidth-page
  header
  .header-nav-wrapper.scroll-to-fixed-fixed
  .container {
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
/*
 *  Box hover effect
 * -----------------------------------------------
*/
.box-hover-effect {
  position: relative;
  overflow: hidden;
  margin: 0;
  width: 100%;
}
.box-hover-effect img {
  position: relative;
  display: block;
  max-height: 100%;
  max-width: 100%;
  opacity: 1;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.box-hover-effect .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.box-hover-effect .overlay::before,
.box-hover-effect .overlay::after {
  pointer-events: none;
}
.box-hover-effect .overlay .details {
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.box-hover-effect .overlay .title {
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.box-hover-effect .overlay .para {
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.box-hover-effect .overlay .entry-meta {
  bottom: 10px;
  font-size: 70%;
  opacity: 0;
  position: absolute;
  width: 89%;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.box-hover-effect .overlay .entry-meta.top {
  top: 10px;
}
.box-hover-effect .overlay .entry-meta i {
  color: #fff;
}
.box-hover-effect .overlay .icons {
  left: 0;
  opacity: 0;
  position: absolute;
  text-align: center;
  top: 40%;
  width: 100%;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.box-hover-effect:hover .overlay {
  background: rgba(0, 0, 0, 0.85);
}
.box-hover-effect:hover .overlay.white {
  background: rgba(255, 255, 255, 0.75);
}
.box-hover-effect:hover .overlay.black {
  background: rgba(0, 0, 0, 0.85);
}
.box-hover-effect:hover .overlay .entry-meta {
  opacity: 1;
}
.box-hover-effect:hover .overlay .icons {
  opacity: 1;
}
.box-hover-effect:hover .overlay .icons i {
  color: #fff;
}
.box-hover-effect:hover .overlay .icons.twin i {
  padding: 10px;
}
.box-hover-effect:hover .overlay .icons.bordered i {
  border: 1px solid #fff;
}
.box-hover-effect:hover .overlay .icons.bordered i.text-black {
  border-color: #000;
}
.shadow-overlay {
  /* background-image: url("../images/shadow-overlay.png"); */
  background-position: center bottom;
  background-repeat: repeat-x;
  bottom: 0;
  color: #fff;
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
}
/* Individual effects */
/*---------------*/
/***** effect1 *****/
/*---------------*/
.box-hover-effect.effect1 .overlay {
  position: relative;
}
.box-hover-effect.effect1 .thumb {
  position: relative;
  overflow: hidden;
}
.box-hover-effect.effect1 .thumb:after {
  background-color: rgba(255, 255, 255, 0.15);
  bottom: 0;
  content: "";
  height: 0;
  position: absolute;
  right: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  width: 0;
}
.box-hover-effect.effect1 .thumb:before {
  left: 0;
  content: "";
  height: 0;
  position: absolute;
  top: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  width: 0;
  z-index: 1;
}
.box-hover-effect.effect1:hover .thumb:before,
.box-hover-effect.effect1:hover .thumb:after {
  height: 100%;
  width: 100%;
  -webkit-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}
/*---------------*/
/***** effect2 *****/
/*---------------*/
.box-hover-effect.effect2 .thumb {
  position: relative;
  overflow: hidden;
}
.box-hover-effect.effect2 .overlay .overlay-details {
  opacity: 0;
  -webkit-transform: scale(0.8, 0.8);
  -ms-transform: scale(0.8, 0.8);
  -o-transform: scale(0.8, 0.8);
  transform: scale(0.8, 0.8);
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.box-hover-effect.effect2:hover .overlay-details {
  opacity: 1;
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1);
}
.box-hover-effect.effect2:hover .details {
  opacity: 0;
}
.box-hover-effect.effect2:hover img {
  -webkit-transform: scale(2);
  -ms-transform: scale(2);
  -o-transform: scale(2);
  transform: scale(2);
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
/*Custom theme style*/
.box-hover-effect.effect2 .overlay.white .overlay-details p {
  color: #777777;
}
.thumb {
  overflow: hidden;
}
.thumb.zoom-effect img {
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.thumb.zoom-effect img:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}
/*border-effect*/
.border-effect {
  position: relative;
}
.border-effect::after {
  border: 3px solid #abc502;
  content: "";
  height: 94%;
  left: 2.3%;
  position: absolute;
  right: 0;
  top: 3%;
  transform: scale(1.4);
  transition: all 500ms ease 0s;
  width: 96%;
}
.border-effect:hover::after {
  transform: scale(1);
}
.border-effect img {
  transition: all 700ms ease 0s;
}
.border-effect:hover img {
  transform: scale(1.18) rotate(5deg);
}
/*
 * gallery-isotope.less
 * -----------------------------------------------
*/
/* -------- Portfolio Isotop Filter ---------- */
.portfolio-filter {
  margin-bottom: 30px;
}
.portfolio-filter a {
  border-left: 3px solid transparent;
  background-color: #f8f8f8;
  display: inline-block;
  float: none;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  margin-right: 5px;
  outline: medium none;
  padding: 5px 15px;
  -webkit-transition: all 100ms ease-in-out 0s;
  -o-transition: all 100ms ease-in-out 0s;
  transition: all 100ms ease-in-out 0s;
}
.portfolio-filter a.active,
.portfolio-filter a:hover {
  color: #fff;
}
@media only screen and (max-width: 479px) {
  .portfolio-filter a {
    margin-bottom: 10px;
  }
}
/* -------- Portfolio Isotop Gallery ---------- */
.gallery-isotope {
  /*gutter*/
  /* by default 4 grids */
  /*portfolio 2 grids*/
  /*portfolio 2 grids*/
  /*portfolio 3 grids*/
  /*portfolio 4 grids*/
  /*portfolio 5 grids*/
  /*portfolio 6 grids*/
  /*portfolio 7 grids*/
  /*portfolio 8 grids*/
  /*portfolio 9 grids*/
  /*portfolio 10 grids*/
}
.gallery-isotope.gutter .gallery-item {
  padding-right: 5px;
  padding-bottom: 5px;
}
.gallery-isotope.gutter-small .gallery-item {
  padding-right: 2px;
  padding-bottom: 2px;
}
.gallery-isotope.gutter-30 .gallery-item {
  padding-right: 30px;
  padding-bottom: 30px;
}
.gallery-isotope.gutter-40 .gallery-item {
  padding-right: 40px;
  padding-bottom: 40px;
}
.gallery-isotope.gutter-50 .gallery-item {
  padding-right: 50px;
  padding-bottom: 50px;
}
.gallery-isotope.gutter-60 .gallery-item {
  padding-right: 60px;
  padding-bottom: 60px;
}
.gallery-isotope .gallery-item {
  width: 25%;
}
.gallery-isotope .gallery-item.wide {
  width: 50%;
}
.gallery-isotope.grid-1 .gallery-item {
  width: 100%;
}
.gallery-isotope.grid-1 .gallery-item.wide {
  width: 100%;
}
.gallery-isotope.grid-2 .gallery-item {
  width: 50%;
}
.gallery-isotope.grid-2 .gallery-item.wide {
  width: 100%;
}
.gallery-isotope.grid-3 .gallery-item {
  width: 33.333%;
}
.gallery-isotope.grid-3 .gallery-item.wide {
  width: 66.6667%;
}
.gallery-isotope.grid-4 .gallery-item {
  width: 25%;
}
.gallery-isotope.grid-4 .gallery-item.wide {
  width: 50%;
}
.gallery-isotope.grid-5 .gallery-item {
  width: 20%;
}
.gallery-isotope.grid-5 .gallery-item.wide {
  width: 40%;
}
.gallery-isotope.grid-6 .gallery-item {
  width: 16.666666667%;
}
.gallery-isotope.grid-6 .gallery-item.wide {
  width: 33.333%;
}
.gallery-isotope.grid-7 .gallery-item {
  width: 14.2857143%;
}
.gallery-isotope.grid-7 .gallery-item.wide {
  width: 28.5714286%;
}
.gallery-isotope.grid-8 .gallery-item {
  width: 12.5%;
}
.gallery-isotope.grid-8 .gallery-item.wide {
  width: 25%;
}
.gallery-isotope.grid-9 .gallery-item {
  width: 11.11%;
}
.gallery-isotope.grid-9 .gallery-item.wide {
  width: 22.22%;
}
.gallery-isotope.grid-10 .gallery-item {
  width: 10%;
}
.gallery-isotope.grid-10 .gallery-item.wide {
  width: 20%;
}
/* -------- Portfolio Isotop Item Media Query ---------- */
@media only screen and (max-width: 991px) {
  .gallery-isotope .gallery-item {
    width: 33.333% !important;
  }
  .gallery-isotope .gallery-item.wide {
    width: 66.6667% !important;
  }
}
@media only screen and (max-width: 767px) {
  .gallery-isotope .gallery-item {
    width: 50% !important;
  }
  .gallery-isotope .gallery-item.wide {
    width: 100% !important;
  }
}
@media only screen and (max-width: 479px) {
  .gallery-isotope .gallery-item {
    width: 100% !important;
  }
  .gallery-isotope .gallery-item.wide {
    width: 100% !important;
  }
}
/* -------- Portfolio Isotop Overlay Effect ---------- */
.gallery-isotope .gallery-item {
  overflow: hidden;
}
.gallery-isotope .gallery-item .thumb {
  position: relative;
  overflow: hidden;
}
.gallery-isotope .gallery-item .hover-link {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  background: none !important;
  color: transparent !important;
  z-index: 11;
}
.gallery-isotope .gallery-item .overlay-shade {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 12;
  background-color: #333;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}
.gallery-isotope .gallery-item:hover .overlay-shade {
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.gallery-isotope .gallery-item .icons-holder {
  position: absolute;
  top: 0%;
  left: 50%;
  display: inline-block;
  width: auto;
  height: 36px;
  vertical-align: middle;
  z-index: 13;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.gallery-isotope .gallery-item .icons-holder .icons-holder-inner {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: -18px 0 0 -50%;
}
.gallery-isotope .gallery-item .icons-holder .icons-holder-inner a {
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.gallery-isotope .gallery-item:hover .icons-holder {
  top: 50%;
}
.gallery-isotope .gallery-item:hover .icons-holder .icons-holder-inner a {
  opacity: 1;
  filter: alpha(opacity=1);
}
.gallery-isotope .gallery-item .portfolio-description {
  padding: 10px 15px;
  background-color: #f8f8f8;
}
.gallery-isotope .gallery-item .portfolio-description .title {
  margin: 0;
}
.gallery-isotope .gallery-item .portfolio-description .title a {
  color: #555555;
}
.gallery-isotope .gallery-item .text-holder {
  box-sizing: border-box;
  display: block;
  height: 100%;
  left: 0;
  opacity: 0;
  overflow: hidden;
  padding: 25px 30px 60px;
  position: absolute;
  top: 0;
  -webkit-transition: opacity 0.3s ease-in-out 0s;
  -o-transition: opacity 0.3s ease-in-out 0s;
  transition: opacity 0.3s ease-in-out 0s;
  width: 100%;
  z-index: 13;
}
.gallery-isotope .gallery-item .text-holder .title {
  color: #fff;
}
.gallery-isotope .gallery-item:hover .text-holder {
  opacity: 1;
}
.gallery-isotope .flex-control-nav {
  z-index: 111;
}
/*
 * Slider.less
 * -----------------------------------------------
*/
/* -------- Rev Slider ---------- */
#home .revslider-fullscreen {
  min-height: auto;
}
/* -------- Home Revolution Slider For Minimal Layouts ---------- */
.minimal-revslider-wrapper {
  left: 0 !important;
  width: 100% !important;
}
.minimal-revslider-wrapper .tp-leftarrow {
  left: 0 !important;
}
.minimal-revslider-wrapper .tp-rightarrow {
  right: 0 !important;
}
/* -------- Tab-Slider ---------- */
.tab-slider .tab-content {
  padding: 0;
  border: 0;
}
.tab-slider .nav.nav-pills > div {
  background-color: #fff;
  float: left;
  margin-bottom: 15px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.tab-slider .nav.nav-pills i {
  color: #d3d3d3;
}
.tab-slider .nav.nav-pills a {
  display: block;
  padding: 40px 30px;
  background: #f3f3f3;
}
.tab-slider .nav.nav-pills a:hover i,
.tab-slider .nav.nav-pills a:hover h6,
.tab-slider .nav.nav-pills a.active i,
.tab-slider .nav.nav-pills a.active h6 {
  color: #fff;
}
/* -------- MaxImage Slider ---------- */
.maximage-slider .fullscreen-controls .img-prev,
.maximage-slider .fullscreen-controls .img-next {
  background: rgba(0, 0, 0, 0.3);
}
.clients-logo.carousel {
  text-align: center;
}
.clients-logo.carousel .item {
  box-shadow: none;
  margin: 0 10px;
}
/* -------- Widget-Testimonial-Carousel ---------- */
.widget-testimonial-carousel .item img {
  max-width: 75px;
  float: right;
}
/* -------- Owl Carousel ---------- */
.owl-controls {
  margin: 0;
}
.owl-controls .owl-nav > div {
  background: none repeat scroll 0 0 rgba(240, 240, 240, 0.8);
  border-radius: 0;
  display: block;
  margin: 0;
  padding: 10px;
  position: absolute;
  top: 45%;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  z-index: 6;
}
.owl-controls .owl-next {
  right: 0px;
}
.owl-controls .owl-next i {
  color: #888;
  font-size: 20px;
}
.owl-controls .owl-prev i {
  color: #888;
  font-size: 20px;
}
/* owl dots */
.owl-theme .owl-dots {
  text-align: center;
  margin-top: 10px;
}
.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
}
.owl-theme .owl-dots .owl-dot span {
  background: #eeeeee;
  border: 2px solid #555555;
  border-radius: 50%;
  transform: rotate(45deg);
  display: block;
  height: 12px;
  margin: 5px 7px;
  opacity: 0.8;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  width: 12px;
}
.owl-theme .owl-dots .owl-dot.active span {
  background: #333333;
}
/* Image Carousel */
.image-carousel .owl-controls .owl-nav > div {
  opacity: 0;
}
.image-carousel:hover .owl-controls .owl-nav > div {
  opacity: 1;
}
/* Owl top Dots style */
.dots-right .owl-dots {
  text-align: right;
}
.dots-left .owl-dots {
  text-align: left;
}
/* Owl top nav style */
.owl-carousel.owl-nav-top.bullet-white .owl-dots span {
  background: transparent;
}
.owl-carousel.owl-nav-top .owl-controls .owl-nav > div {
  background-color: transparent;
  top: -45px;
  right: 0;
}
.owl-carousel.owl-nav-top .owl-controls .owl-nav > div:hover i {
  color: #fff;
}
.owl-carousel.owl-nav-top .owl-controls .owl-nav .owl-prev {
  right: 30px;
}
.owl-carousel.owl-nav-top .owl-controls .owl-nav .owl-next {
  right: 0;
}
.owl-carousel.owl-nav-top .owl-controls .owl-nav .owl-prev,
.owl-carousel.owl-nav-top .owl-controls .owl-nav .owl-next {
  border: none;
  line-height: 22px;
  padding: 0 9px;
  text-align: center;
}
.owl-carousel.owl-nav-top .owl-controls .owl-nav .owl-prev i,
.owl-carousel.owl-nav-top .owl-controls .owl-nav .owl-next i {
  font-size: 14px;
}
.owl-carousel.no-bullet .owl-nav {
  display: none;
}
.recent-project .owl-controls .owl-nav > div {
  top: 38%;
}
.recent-project .owl-controls .owl-nav .owl-prev i,
.recent-project .owl-controls .owl-nav .owl-next i {
  padding: 2px 16px;
}
.featured-gallery.style2 {
  float: right;
  width: 92%;
}
.featured-gallery.style2 .owl-controls {
  bottom: 58px;
  left: -80px;
  margin: 0;
  position: absolute;
}
.featured-gallery.style2 .owl-controls .owl-nav > div {
  top: 95%;
}
.featured-gallery.style2 .owl-controls .owl-nav .owl-prev {
  left: 0px;
}
.featured-gallery.style2 .owl-controls .owl-nav .owl-next {
  display: inline-table;
  left: 40px;
}
.featured-gallery.style2 .owl-controls .owl-nav .owl-prev i,
.featured-gallery.style2 .owl-controls .owl-nav .owl-next i {
  color: #fff;
  font-size: 35px;
}
@media only screen and (max-width: 991px) {
  .featured-gallery.style2 {
    float: none;
    margin-top: 58px;
    width: 100%;
  }
  .featured-gallery.style2 .owl-controls {
    bottom: auto;
    left: auto;
    top: -45px;
  }
}
.image-carousel .owl-controls .owl-nav > div {
  opacity: 0;
}
.image-carousel:hover .owl-controls .owl-nav > div {
  opacity: 1;
}
/* -------- Owl Fullwidth Carousel ---------- */
.fullwidth-carousel .owl-controls .owl-nav > div {
  top: 45%;
}
.fullwidth-carousel .carousel-item {
  min-height: 615px;
  width: 100%;
}
.fullwidth-carousel.blog-fullwidth-carousel .carousel-item {
  height: 600px;
  min-height: auto;
  width: 100%;
}
.fullwidth-carousel.blog-fullwidth-carousel .owl-controls .owl-nav > div {
  background: none;
}
.news-carousel-3col .item {
  margin-right: 25px;
}
/* -------- Twitter Feed Carousel ---------- */
.twitter-feed.twitter-carousel {
  color: #111111;
  font-size: 16px;
}
.twitter-feed.twitter-carousel a {
  color: #111111;
}
.twitter-feed .date {
  font-size: 12px;
}
.twitter-feed.twitter-white,
.twitter-feed.twitter-white a {
  color: #fff;
}
/* -------- BX Slider ---------- */
.bx-wrapper {
  margin: 0 auto 7px;
  padding: 0;
  position: relative;
}
.bx-wrapper .bx-viewport {
  background: inherit;
  border: 0 solid #fff;
  box-shadow: 0 0 0 #ccc;
  left: 0;
  transform: translateZ(0px);
}
/* bx top nav style */
.bx-controls .bx-controls-direction > a {
  background: #eee none repeat scroll 0 0;
  border: medium none;
  height: auto;
  line-height: 28px;
  padding: 0 12px;
  width: auto;
  text-indent: 0;
  top: -30px;
  z-index: 0;
}
.bx-controls .bx-controls-direction > a:hover {
  background: #ddd;
}
.bx-controls .bx-controls-direction > a i {
  color: #888;
}
.bx-controls .bx-controls-direction .bx-prev {
  right: 35px;
  left: auto;
}
.bx-controls .bx-controls-direction .bx-next {
  right: 0;
}
/* -------- Typed Animation Slider ---------- */
.typed-cursor {
  opacity: 1;
  -webkit-animation: blink 0.7s infinite;
  -moz-animation: blink 0.7s infinite;
  animation: blink 0.7s infinite;
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* -------- Layer Slider ---------- */
/*
 * Home
 * -----------------------------------------------
*/
/* setion-bottom-absolute-div */
.setion-bottom-absolute-div {
  background: rgba(255, 255, 255, 0.5) none repeat scroll 0 0;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
}
/*
 * About
 * -----------------------------------------------
*/
/* bg img box */
.bg-img-box {
  position: relative;
}
.bg-img-box .caption {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  position: absolute;
  top: 0;
}
.bg-img-box .caption .title {
  font-size: 30px;
  line-height: 39px;
}
.bg-img-box .caption p {
  max-width: 280px;
}
/*
 * Contact.less
 * -----------------------------------------------
*/
.contact-wrapper {
  padding: 50px 50px 50px 100px;
}
.contact-form .form-group {
  margin-bottom: 20px;
}
.contact-form .form-control {
  background-image: none;
  height: 48px;
}
.contact-form .form-control:focus,
.contact-form .form-control:active {
  box-shadow: none;
  color: #333333;
}
.contact-form .form-control2 {
  background-color: rgba(0, 0, 0, 0.8);
  color: #c0c0c0;
}
.contact-form .form-group button {
  margin-top: 0;
}
.contact-form textarea.form-control {
  height: 110px;
  resize: none;
}
textarea {
  resize: none;
}
.contact-icon-box {
  border: 1px solid #666;
}
.contact-icon-box .contact-icon {
  line-height: 50px;
  text-align: center;
  position: absolute;
  top: -30px;
  width: 50px;
  height: 50px;
}
/*
 * event.less
 * -----------------------------------------------
*/
.event {
  background: #f6f6f6;
  border: 1px solid transparent;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}
.event:hover {
  background: #fafafa;
}
.event .event-content .media-heading a {
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}
.event .event-content ul li {
  font-size: 12px;
  line-height: 1;
  margin-top: 10px;
  margin-bottom: 10px;
}
.event .event-date ul li {
  line-height: 1;
}
.event .event-date ul li:first-child {
  margin-bottom: 10px;
}
/* -------- Schedule-Box Style ---------- */
.schedule-box .thumb {
  position: relative;
  overflow-x: hidden;
}
.schedule-box .thumb .overlay {
  background-color: rgba(255, 255, 255, 0.85);
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: center;
  -webkit-transition: 0.3s ease-in-out 0s;
  -o-transition: 0.3s ease-in-out 0s;
  transition: 0.3s ease-in-out 0s;
  top: -100%;
}
.schedule-box .thumb .overlay a i {
  background-color: #333;
  color: #fff;
  margin: 0 auto;
  padding: 13px 12px;
  position: absolute;
  text-align: center;
  -webkit-transition: all 0.45s ease-in-out 0s;
  -o-transition: all 0.45s ease-in-out 0s;
  transition: all 0.45s ease-in-out 0s;
  top: 0;
}
.schedule-box .schedule-details {
  border-bottom: 1px solid #bbb;
}
.schedule-box .schedule-details ul li {
  margin: 0;
}
.schedule-box .schedule-details .title a {
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.schedule-box:hover .thumb .overlay {
  opacity: 1;
  top: 0;
}
.schedule-box:hover .thumb .overlay a i {
  opacity: 1;
  top: 40%;
}
/* -------- Event-Table ---------- */
.table-schedule thead tr th {
  color: #fff;
  font-size: 16px;
  padding: 15px 25px;
  text-transform: uppercase;
}
.table-schedule > tbody > tr {
  background-color: #fcfcfc;
}
.table-schedule > tbody > tr:nth-child(2n + 1) {
  background-color: #f9f9f9;
}
.table-schedule tbody tr td {
  border-top: 1px solid #ddd;
  color: #333;
  font-weight: 300;
  padding: 15px 25px;
}
.table-schedule tbody tr td .speaker-thumb {
  float: left;
  margin-right: 15px;
  margin-top: 5px;
  width: 55px;
}
.table-schedule tbody tr td .title {
  font-size: 15px;
  margin-bottom: 0;
  margin-top: 3px;
}
.table-schedule tbody tr td .name {
  font-size: 13px;
  margin: 0;
}
.table-schedule tbody tr td strong {
  font-weight: 700;
}
.table-schedule .toggle-content {
  cursor: pointer;
}
.table-schedule .session-details {
  display: none;
}
/*==================================================
=            Bootstrap 3 Media Queries             =
==================================================*/
/*==========  Mobile First Method  ==========*/
/* Custom, iPhone Retina */
/* Extra Small Devices, Phones */
/* Small Devices, Tablets */
/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
  .md-height {
    height: 275px !important;
  }
}
/* Large Devices, Wide Screens */
/*==========  Non-Mobile First Method  ==========*/
/* Large Devices, Wide Screens */
/* Medium Devices, Desktops */
/* Small Devices, Tablets */
/* Extra Small Devices, Phones */
/* Custom, iPhone Retina */
/*
 * Section: Services
 * -----------------------------------------------
*/
.rotated-service-box {
  position: relative;
}
.rotated-service-box .icon {
  background-color: #d3d3d3;
  display: inline-block;
  height: 90px;
  left: 0;
  margin: 0 auto;
  padding: 30px;
  position: absolute;
  right: 0;
  top: -45px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 90px;
}
.rotated-service-box .icon i {
  color: #fff;
  font-size: 32px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.rotated-service-box .heading {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
/*
 * Shop.less
 * -----------------------------------------------
*/
/* -------- Product Details Page ---------- */
@media only screen and (min-width: 480px) {
  .product {
    margin-bottom: 30px;
    padding-bottom: 15px;
  }
}
.product {
  margin-left: auto;
  margin-right: auto;

  position: relative;
  overflow: hidden;
}

.products .product {
  border: 1px solid #fcf9f9;
  max-width: 400px;
}
.products .product:hover {
  border: 1px solid #eeeeee;
}
.products .product .product-details .product-title {
  font-size: 15px;
  margin-top: 20px;
  text-transform: uppercase;
}
.btn-add-to-cart-wrapper,
.btn-product-view-details {
  opacity: 0;
  height: auto;
  margin: 0 0 15px;
  -webkit-transition: 400ms;
  -o-transition: 400ms;
  transition: 400ms;
  position: absolute;
  bottom: 0;
  left: -60px;
}
.product:hover .btn-add-to-cart-wrapper,
.product:hover .btn-product-view-details {
  left: 0;
  opacity: 1;
}
.btn-product-view-details {
  left: auto;
  bottom: 0;
  right: -60px;
}
.product:hover .btn-product-view-details {
  left: auto;
  opacity: 1;
  right: 0;
}
/* -------- Price ---------- */
.product .price {
  margin-top: 5px;
}
.product .price .amount {
  font-size: 14px;
  font-weight: 600;
}
.product .price del .amount {
  color: #999999;
  font-size: 14px;
  font-weight: 500;
}
.product .price ins {
  margin-left: 10px;
  text-decoration: none;
}
/* -------- Product-Summary ---------- */
.product .product-summary .product-title {
  margin-top: 0;
}
.product .product-summary .short-description {
  margin-bottom: 15px;
}
.product .product-summary .cart-form-wrapper .btn {
  margin-top: 0;
}
.product .product-summary .variations {
  width: 100%;
}
.product .product-tab {
  margin-top: 30px;
}
.product .commentlist .comment {
  margin-top: 30px;
  position: relative;
}
.product .commentlist .comment .meta {
  font-size: 12px;
}
.product .commentlist .comment .author {
  font-size: 14px;
  font-weight: bold;
}
.product .product-thumb {
  position: relative;
}
.product .product-thumb:hover .overlay {
  opacity: 1;
}
.product .product-thumb .overlay .add-to-wishlist {
  color: #fff;
  right: 10px;
  top: 10px;
  position: absolute;
}
.product .product-summary .cart-form-wrapper .quantity,
.tbl-shopping-cart .quantity {
  display: inline-block;
  margin-right: 0.8571em;
  position: relative;
  width: 4.1429em;
  vertical-align: top;
}
.product .product-summary .cart-form-wrapper .quantity .minus,
.tbl-shopping-cart .quantity .minus,
.product .product-summary .cart-form-wrapper .quantity .plus,
.tbl-shopping-cart .quantity .plus {
  background: none repeat scroll 0 0 transparent;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-family: "Open Sans", sans-serif;
  height: 18px;
  left: 2.8571em;
  line-height: 1;
  outline: medium none;
  padding: 0;
  position: absolute;
  width: 18px;
}
.product .product-summary .cart-form-wrapper .quantity .minus,
.tbl-shopping-cart .quantity .minus {
  bottom: 0;
}
.product .product-summary .cart-form-wrapper .quantity .plus,
.tbl-shopping-cart .quantity .plus {
  top: 0;
}
.product .product-summary .cart-form-wrapper .quantity .qty,
.tbl-shopping-cart .quantity .qty {
  -moz-appearance: textfield;
  border: 1px solid #ccc;
  height: 41px;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  width: 40px;
}
/* -------- Star-Rating ---------- */
.star-rating {
  font-size: 14px;
  height: 16px;
  width: 75px;
  line-height: 1;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  top: 0px;
}
.star-rating::before,
.star-rating span::before {
  color: #a9a9a9;
  content: "\f005\f005\f005\f005\f005";
  float: left;
  font-family: fontawesome;
  left: 0;
  letter-spacing: 2px;
  position: absolute;
  top: 0;
}
.star-rating span {
  float: left;
  left: 0;
  overflow: hidden;
  padding-top: 1.5em;
  position: absolute;
  top: 0;
}
/* -------- Tag Sale ---------- */
.product .tag-sale {
  margin: 0;
  top: 0;
  display: block;
  left: auto;
  right: 0;
  -webkit-transition: 400ms;
  -o-transition: 400ms;
  transition: 400ms;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  padding: 4px 10px 4px 16px;
  position: absolute;
  text-align: right;
  text-transform: uppercase;
  z-index: 9;
}
.product .tag-sale .tag-sale::before {
  border-color: transparent transparent transparent #dcdcdc;
  border-style: solid;
  border-width: 12px 13px 13px 8px;
  content: "";
  height: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 1px;
  width: 0;
}
/* -------- Cart Page ---------- */
.tbl-shopping-cart .product-thumbnail img {
  max-width: 72px;
  max-height: 72px;
}
.tbl-shopping-cart .product-remove {
  text-align: center;
}
.tbl-shopping-cart .variation .variation-size,
.tbl-shopping-cart .variation .variation-size span {
  font-size: 12px;
}
.tbl-shopping-cart tr th,
.tbl-shopping-cart tr td,
.tbl-shopping-cart .coupon label,
.tbl-shopping-cart .coupon input,
.tbl-shopping-cart .coupon button {
  vertical-align: middle !important;
}
.tbl-shopping-cart tr td {
  padding: 10px 12px !important;
}
table.no-border td {
  border: medium none !important;
}
/* -------- Checkout Page ---------- */
.payment-method .radio {
  border: 1px solid #eee;
  padding: 10px;
}
.payment-method .radio > label {
  color: #111;
  font-size: 17px;
}
.payment-method .radio input {
  margin-top: 8px;
  vertical-align: initial;
}
.payment-method .radio > p {
  margin-left: 20px;
}
#checkout-shipping-address {
  display: none;
}
/* -------- Top Nav Search And Cart ---------- */
.top-nav-search-box .indicator,
.top-nav-cart-box .indicator {
  display: none;
}
.dropdown-cart {
  padding: 10px;
}
.dropdown-cart .cart-table-list,
.dropdown-cart .cart-table-list i {
  color: #222222;
  border: none;
}
.dropdown-cart .cart-table-list a {
  line-height: normal;
  color: #222222;
}
.dropdown-cart .cart-table-list td img {
  width: 50px;
  height: auto;
}
.dropdown-cart .cart-table-list.table > tbody > tr > td {
  border: none;
  line-height: 20px;
  height: 20px;
  vertical-align: middle;
}
.dropdown-cart .total-cart {
  display: inline-block;
  width: 100%;
  padding-top: 20px;
  color: #222;
  text-align: right;
}
.dropdown-cart .total-cart ul {
  float: right;
  list-style: none;
  padding: 0;
  width: 100%;
}
.dropdown-cart .total-cart ul li {
  display: block;
  overflow: hidden;
}
.dropdown-cart .total-cart ul li span {
  width: 100px;
  display: inline-block;
  text-align: right;
  white-space: nowrap;
}
.dropdown-cart .total-cart .table > tbody > tr > td,
.dropdown-cart .total-cart .table > tbody > tr > th,
.dropdown-cart .total-cart .table > tfoot > tr > td,
.dropdown-cart .total-cart .table > tfoot > tr > th,
.dropdown-cart .total-cart .table > thead > tr > td,
.dropdown-cart .total-cart .table > thead > tr > th {
  border: 0;
}
.dropdown-cart .cart-btn .btn {
  width: auto;
  float: none;
  padding: 5px 15px;
}
/*
 * Section: Services
 * -----------------------------------------------
*/
.single-class-details {
  border-bottom: 1px solid #d3d3d3;
  display: inline-block;
}
.classes-schedules .dl-horizontal dt {
  text-align: left;
}
/*
 * Blog 
 * -----------------------------------------------
*/
.post .entry-header {
  position: relative;
  overflow: hidden;
}
.post .entry-title {
  margin-top: 0;
}
.post .entry-content {
  background: #f8f8f8;
  padding: 15px;
}
.post .entry-date {
  font-size: 13px;
}
.post .entry-meta.meta-absolute {
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}
.post .entry-meta.meta-absolute i {
  display: block;
  font-size: 28px;
}
.post .entry-meta.meta-absolute span a {
  color: #454545;
  font-size: 12px;
  font-style: italic;
  letter-spacing: 2px;
  margin-right: 5px;
}
.post.media-post .post-thumb {
  float: left;
  margin: 0 10px 10px 0;
}
.post .post-thumb {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
/*
 * Blog Style 2
 * -----------------------------------------------
*/
.post.style2,
.post.style3 {
  position: relative;
  overflow: hidden;
}
.post.style2 .entry-content,
.post.style3 .entry-content {
  background-color: rgba(0, 0, 0, 0.8);
  bottom: -30px;
  left: 0;
  padding: 30px;
  position: absolute;
  right: 0;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.post.style2 .entry-content .entry-date,
.post.style3 .entry-content .entry-date {
  font-size: 11px;
  margin-bottom: 10px;
}
.post.style2:hover .entry-content,
.post.style3:hover .entry-content {
  bottom: 0;
}
.post.style2:hover .post-thumb,
.post.style3:hover .post-thumb {
  -webkit-transform: scale(1) !important;
  -ms-transform: scale(1) !important;
  -o-transform: scale(1) !important;
  transform: scale(1) !important;
}
.post .entry-header .entry-date {
  position: absolute;
  bottom: 0;
  right: 0;
}
.featured-blog {
  overflow: hidden;
  position: relative;
}
.featured-blog-details {
  bottom: -18%;
  padding: 30px;
  position: absolute;
  transition: all 400ms ease-in-out 0s;
  width: 100%;
}
.featured-blog:hover .featured-blog-details {
  bottom: 0;
}
.blog-pull-right {
  float: right;
}
@media only screen and (max-width: 767px) {
  .blog-pull-right {
    float: none;
  }
}
/*
 *  -> Funfact
 * -----------------------------------------------
*/
funfactfunfact.border {
  border: 1px solid #ddd;
}
funfactno-border {
  border: 0 none;
}
funfact a {
  float: left;
  margin-right: 15px;
  margin-top: 3px;
}
funfact i {
  font-size: 46px;
}
funfact animate-number {
  font-size: 32px;
}
funfact span {
  color: #999;
  font-size: 18px;
}
funfactstyle-1:hover i {
  font-size: 40px !important;
  opacity: 0.3;
}
funfactstyle-1:hover h4 {
  color: #000000 !important;
}
funfactstyle-1 i,
funfactstyle-1 h4 {
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}
/*
 * 3.15 -> Trainer
 * -----------------------------------------------
*/
.trainer-item {
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
.trainer-item:hover:before {
  height: 100%;
  position: absolute;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  content: "";
}
.trainer-item .trainer-info {
  height: 100%;
  position: absolute;
  top: 0;
  -webkit-transition: all 0.2s linear 0s;
  -o-transition: all 0.2s linear 0s;
  transition: all 0.2s linear 0s;
  width: 100%;
}
.trainer-item .trainer-info .trainer-biography {
  bottom: 10%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  left: 0;
  right: 0;
  position: absolute;
  margin: auto;
  text-align: center;
  opacity: 0;
  -webkit-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
  z-index: 3;
}
.trainer-item:hover .trainer-biography {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.trainer-item .social-network {
  position: absolute;
  color: #fff;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  top: -50px;
  -webkit-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  -o-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  z-index: 3;
}
.trainer-item .social-network li a i {
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  color: #fff;
}
.trainer-item:hover .social-network {
  top: 20%;
}
/* Trainer Style 2 */
.box-hover-effect.effect1:hover .thumb img {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
  transform: scale(1.05);
}
.box-hover-effect.effect1:hover .thumb:before {
  -webkit-transform: rotate(-59deg);
  -ms-transform: rotate(-59deg);
  -o-transform: rotate(-59deg);
  transform: rotate(-59deg);
}
.box-hover-effect.effect1:hover .thumb:hover {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}
.trainer-item.style2 {
  overflow: hidden;
  position: relative;
  transition: all 0.5s linear 0s;
  margin-bottom: 30px;
}
.trainer-item.style2 .trainer-biography {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.trainer-item.style2:hover::before {
  background: transparent;
  content: "";
  height: 100%;
  position: static;
  width: 100%;
}
.trainer-item.style2:hover .trainer-biography {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.trainer-item.style2:hover .trainer-info {
  bottom: 0;
}
.trainer-item.style2 .trainer-info {
  background: #000 none repeat scroll 0 0;
  bottom: -100%;
  display: block;
  height: auto;
  position: absolute;
  top: auto;
  width: 100%;
  -webkit-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.trainer-item.style2 .trainer-info .trainer-biography {
  opacity: 1;
  position: relative;
  right: auto;
  left: auto;
  top: auto;
  bottom: auto;
  text-align: center;
  z-index: 3;
}
.trainer-item.style2 .social-network {
  color: #fff;
  position: relative;
  text-align: center;
  top: auto;
  z-index: 3;
}
.trainer-item.style2 .social-network:hover {
  top: auto;
}
.trainer-item.style2 .social-network li a i {
  color: #fff;
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  width: 30px;
}
/* Social Network Style 2 */
.team-carousel .styled-icons a i {
  line-height: 40px;
}
.bg-img-box {
  overflow: hidden;
}
.bg-img-box .style3 {
  top: 55%;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  position: absolute;
}
.style3 {
  opacity: 0;
  left: -160px;
  transition: all 0.5s ease-in-out 0s;
}
.bg-img-box:hover .style3 {
  opacity: 1;
  left: 0;
}
/*
 * Traning Classes
 * -----------------------------------------------
*/
.box-hover-effect.effect1:hover .thumb img {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
  transform: scale(1.05);
}
.box-hover-effect.effect1 .thumb::before {
  -webkit-transform: rotate(-59deg);
  -ms-transform: rotate(-59deg);
  -o-transform: rotate(-59deg);
  transform: rotate(-59deg);
}
.box-hover-effect.effect1 .thumb:hover {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}
/*
 * Calculator
 * -----------------------------------------------
*/
/*
 * Why Choose
 * -----------------------------------------------
*/
.img-icon-box {
  position: relative;
  overflow: hidden;
  -webkit-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  -o-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
}
.img-icon-box:before {
  position: absolute;
  background: rgba(255, 100, 100, 0.8);
  content: "";
  height: 100%;
  width: 100%;
  display: block;
}
.img-icon-box .img-icon-details {
  position: relative;
  min-height: 260px;
}
.img-icon-box .img-icon-details .flaticon-weights19,
.img-icon-box .img-icon-details .flaticon-gym-5,
.img-icon-box .img-icon-details .flaticon-gym-2,
.img-icon-box .img-icon-details .fitness-details,
.img-icon-box .img-icon-details .flaticon-weights19,
.img-icon-box .img-icon-details .flaticon-bodyparts70,
.img-icon-box .img-icon-details .flaticon-gym6,
.img-icon-box .img-icon-details .flaticon-gym5,
.img-icon-box .img-icon-details .flaticon-gym-6 {
  color: #fff;
}
.img-icon-box .img-icon-content {
  opacity: 0;
  -webkit-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  -o-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
}
.img-icon-box:hover .img-icon-content {
  opacity: 1;
}
.img-icon-box .fitness-details,
.img-icon-box .flaticon-weights19,
.img-icon-box .flaticon-bodyparts70,
.img-icon-box .flaticon-gym6,
.img-icon-box .flaticon-gym5,
.img-icon-box .flaticon-gym-2,
.img-icon-box .flaticon-gym-5,
.img-icon-box .flaticon-gym-6,
.img-icon-box h3 {
  -webkit-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  -o-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
}
.img-icon-box:hover .flaticon-gym-2,
.img-icon-box:hover .flaticon-weights19,
.img-icon-box:hover .flaticon-bodyparts70,
.img-icon-box:hover .flaticon-gym6,
.img-icon-box:hover .flaticon-gym5,
.img-icon-box:hover .flaticon-gym-5,
.img-icon-box:hover .flaticon-gym-6 {
  font-size: 0 !important;
}
/*
 * job.less
 * -----------------------------------------------
*/
.job-overview {
  box-shadow: 0 0 0 2px #eee;
  padding: 20px 0 20px 20px;
}
.job-overview .dl-horizontal dt {
  width: auto;
}
.job-overview .dl-horizontal dd {
  margin-left: 40px;
}
/*
 * Work-Gallery.less
 * -----------------------------------------------
*/
.work-gallery {
  margin: 0 auto 30px;
  max-width: 400px;
}
.work-gallery .gallery-thumb {
  overflow: hidden;
  position: relative;
}
.work-gallery .gallery-bottom-part {
  background-color: #f7f7f7;
  padding: 10px 10px 16px;
}
.work-gallery .gallery-thumb .gallery-overlay {
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  -webkit-transform: scale(0.2);
  -ms-transform: scale(0.2);
  -o-transform: scale(0.2);
  transform: scale(0.2);
  -webkit-transition: all 300ms ease-in-out 0s;
  -o-transition: all 300ms ease-in-out 0s;
  transition: all 300ms ease-in-out 0s;
  width: 100%;
}
.work-gallery:hover .gallery-thumb .gallery-overlay {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.gallery-contect {
  height: 100%;
  opacity: 0;
  padding: 30px;
  position: absolute;
  top: 0;
  -webkit-transform: scale(0.2);
  -ms-transform: scale(0.2);
  -o-transform: scale(0.2);
  transform: scale(0.2);
  -webkit-transition: all 100ms ease-in-out 0s;
  -o-transition: all 100ms ease-in-out 0s;
  transition: all 100ms ease-in-out 0s;
  width: 100%;
}
.work-gallery:hover .gallery-thumb .gallery-contect {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.work-gallery .gallery-thumb .styled-icons a {
  border-color: #fff;
  height: 36px;
  width: 36px;
  line-height: 34px;
}
.work-gallery .gallery-thumb .styled-icons a i {
  color: #fff;
}
.work-gallery .gallery-thumb .styled-icons a:hover i {
  color: #fff;
}
.work-gallery .gallery-thumb .styled-icons a:hover {
  color: #fff;
  -webkit-transition: all 100ms ease-in-out 0s;
  -o-transition: all 100ms ease-in-out 0s;
  transition: all 100ms ease-in-out 0s;
}
.work-gallery .gallery-thumb .gallery-contect .styled-icons {
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 45%;
}
.work-gallery:hover .gallery-bottom-part {
  -webkit-transition: all 300ms ease-in-out 0s;
  -o-transition: all 300ms ease-in-out 0s;
  transition: all 300ms ease-in-out 0s;
}
.work-gallery:hover .gallery-bottom-part .title {
  color: #fff;
}
.work-gallery:hover .gallery-bottom-part .sub-title {
  color: #fff;
}
/*
 * Shortcode.less
 * -----------------------------------------------
*/
/*
 * Shortcode: Alerts.less
 * -----------------------------------------------
*/
/*
 * Shortcode: Buttons.less
 * -----------------------------------------------
*/
/* -------- Btn-Default ---------- */
.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
.btn-default:focus,
.btn-default.focus {
  color: #333;
  background-color: #e6e6e6;
  border-color: #8c8c8c;
}
.btn-default:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.btn-default:active:hover,
.btn-default.active:hover,
.open > .dropdown-toggle.btn-default:hover,
.btn-default:active:focus,
.btn-default.active:focus,
.open > .dropdown-toggle.btn-default:focus,
.btn-default:active.focus,
.btn-default.active.focus,
.open > .dropdown-toggle.btn-default.focus {
  color: #333;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  background-image: none;
}
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled.focus,
.btn-default[disabled].focus,
fieldset[disabled] .btn-default.focus {
  background-color: #fff;
  border-color: #ccc;
}
.btn-default .badge {
  color: #fff;
  background-color: #333;
}
.btn-default:hover {
  background-color: #eeeeee;
  border-color: #eeeeee;
}
/* -------- Btn-Border ---------- */
.btn-border {
  color: #222222;
  background-color: #fff;
  border-color: #222222;
}
.btn-border:focus,
.btn-border.focus {
  color: #222222;
  background-color: #e6e6e6;
  border-color: #000000;
}
.btn-border:hover {
  color: #222222;
  background-color: #e6e6e6;
  border-color: #040404;
}
.btn-border:active,
.btn-border.active,
.open > .dropdown-toggle.btn-border {
  color: #222222;
  background-color: #e6e6e6;
  border-color: #040404;
}
.btn-border:active:hover,
.btn-border.active:hover,
.open > .dropdown-toggle.btn-border:hover,
.btn-border:active:focus,
.btn-border.active:focus,
.open > .dropdown-toggle.btn-border:focus,
.btn-border:active.focus,
.btn-border.active.focus,
.open > .dropdown-toggle.btn-border.focus {
  color: #222222;
  background-color: #d4d4d4;
  border-color: #000000;
}
.btn-border:active,
.btn-border.active,
.open > .dropdown-toggle.btn-border {
  background-image: none;
}
.btn-border.disabled:hover,
.btn-border[disabled]:hover,
fieldset[disabled] .btn-border:hover,
.btn-border.disabled:focus,
.btn-border[disabled]:focus,
fieldset[disabled] .btn-border:focus,
.btn-border.disabled.focus,
.btn-border[disabled].focus,
fieldset[disabled] .btn-border.focus {
  background-color: #fff;
  border-color: #222222;
}
.btn-border .badge {
  color: #fff;
  background-color: #222222;
}
.btn-border {
  border-width: 2px;
}
.btn-border:hover,
.btn-border:active,
.btn-border:focus {
  background-color: #222222;
  border-color: #222222;
  color: #fff;
}
/* -------- Btn-Dark ---------- */
.btn-dark {
  color: #fff;
  background-color: #222222;
  border-color: #222222;
}
.btn-dark:focus,
.btn-dark.focus {
  color: #fff;
  background-color: #090909;
  border-color: #000000;
}
.btn-dark:hover {
  color: #fff;
  background-color: #090909;
  border-color: #040404;
}
.btn-dark:active,
.btn-dark.active,
.open > .dropdown-toggle.btn-dark {
  color: #fff;
  background-color: #090909;
  border-color: #040404;
}
.btn-dark:active:hover,
.btn-dark.active:hover,
.open > .dropdown-toggle.btn-dark:hover,
.btn-dark:active:focus,
.btn-dark.active:focus,
.open > .dropdown-toggle.btn-dark:focus,
.btn-dark:active.focus,
.btn-dark.active.focus,
.open > .dropdown-toggle.btn-dark.focus {
  color: #fff;
  background-color: #000000;
  border-color: #000000;
}
.btn-dark:active,
.btn-dark.active,
.open > .dropdown-toggle.btn-dark {
  background-image: none;
}
.btn-dark.disabled:hover,
.btn-dark[disabled]:hover,
fieldset[disabled] .btn-dark:hover,
.btn-dark.disabled:focus,
.btn-dark[disabled]:focus,
fieldset[disabled] .btn-dark:focus,
.btn-dark.disabled.focus,
.btn-dark[disabled].focus,
fieldset[disabled] .btn-dark.focus {
  background-color: #222222;
  border-color: #222222;
}
.btn-dark .badge {
  color: #222222;
  background-color: #fff;
}
/* -------- Btn-Gray ---------- */
.btn-gray {
  color: #333333;
  background-color: #eeeeee;
  border-color: #eeeeee;
}
.btn-gray:focus,
.btn-gray.focus {
  color: #333333;
  background-color: #d5d5d5;
  border-color: #afafaf;
}
.btn-gray:hover {
  color: #333333;
  background-color: #d5d5d5;
  border-color: #d0d0d0;
}
.btn-gray:active,
.btn-gray.active,
.open > .dropdown-toggle.btn-gray {
  color: #333333;
  background-color: #d5d5d5;
  border-color: #d0d0d0;
}
.btn-gray:active:hover,
.btn-gray.active:hover,
.open > .dropdown-toggle.btn-gray:hover,
.btn-gray:active:focus,
.btn-gray.active:focus,
.open > .dropdown-toggle.btn-gray:focus,
.btn-gray:active.focus,
.btn-gray.active.focus,
.open > .dropdown-toggle.btn-gray.focus {
  color: #333333;
  background-color: #c3c3c3;
  border-color: #afafaf;
}
.btn-gray:active,
.btn-gray.active,
.open > .dropdown-toggle.btn-gray {
  background-image: none;
}
.btn-gray.disabled:hover,
.btn-gray[disabled]:hover,
fieldset[disabled] .btn-gray:hover,
.btn-gray.disabled:focus,
.btn-gray[disabled]:focus,
fieldset[disabled] .btn-gray:focus,
.btn-gray.disabled.focus,
.btn-gray[disabled].focus,
fieldset[disabled] .btn-gray.focus {
  background-color: #eeeeee;
  border-color: #eeeeee;
}
.btn-gray .badge {
  color: #eeeeee;
  background-color: #333333;
}
.btn-circled {
  border-radius: 25px;
}
.btn-flat {
  border-radius: 0;
}
/* -------- Btn-Default ---------- */
.btn {
  font-size: 14px;
  padding: 8px 22px;
  line-height: 1.38;
}
/* -------- Btn-xs ---------- */
.btn-xs {
  font-size: 11px;
  padding: 3px 14px;
}
/* -------- Btn-sm ---------- */
.btn-sm {
  font-size: 12px;
  padding: 5px 17px;
}
/* -------- Btn-lg ---------- */
.btn-lg {
  font-size: 14px;
  padding: 10px 26px;
}
/* -------- Btn-xl ---------- */
.btn-xl {
  font-size: 16px;
  padding: 13px 32px;
}
/* -------- Btn-transparent ---------- */
.btn-transparent {
  background-color: transparent;
  color: #fff;
}
.hvr-shutter-out-horizontal::before {
  background: #000;
}
/*
 * Blockquote.less
 * -----------------------------------------------
*/
blockquote {
  border-left: 3px solid #eeeeee;
  margin: 20px 0;
  padding: 30px;
}
blockquote.gray {
  border-left: 3px solid #666666;
  background-color: #eeeeee;
  color: #333333;
}
blockquote.gray.bg-theme-colored {
  background-color: #eeeeee;
}
blockquote.dark {
  border-left: 3px solid #3c3c3c;
  background-color: #555555;
  color: #fff;
}
blockquote.dark footer {
  color: #d3d3d3;
}
blockquote p {
  font-family: "Playfair Display", serif;
  font-size: 16px;
  font-style: italic;
}
/*
 * Shortcode: Clients.less
 * -----------------------------------------------
*/
.clients-logo .item {
  border-color: transparent;
  box-shadow: 0 0 0 10px #ffffff;
  margin-bottom: 30px;
  padding: 15px;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.clients-logo img {
  max-height: 90px;
  width: auto !important;
  display: inline;
  opacity: 0.9;
}
.clients-logo img:hover {
  opacity: 1;
}
.clients-logo.style2 .item {
  box-shadow: none;
}
.clients-logo.style2 .item img {
  max-height: 120px;
}
.client-img {
  opacity: 0.6;
  text-align: center;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.client-img:hover {
  opacity: 1;
}
.client-img img {
  max-height: 90px;
}
.clients-logo.carousel {
  text-align: center;
}
.clients-logo.carousel .item {
  box-shadow: none;
  margin: 0 10px;
}
.clients .client {
  text-align: center;
}
.clients .client img {
  opacity: 0.7;
}
.clients .client:hover img {
  opacity: 1;
  -webkit-transition: all 300ms ease 0s;
  -o-transition: all 300ms ease 0s;
  transition: all 300ms ease 0s;
}
/*
 * Shortcode: Countdown.less
 * -----------------------------------------------
*/
.countdown-timer li {
  color: #808080;
  display: inline-block;
  padding: 30px 20px;
  background-color: #f1f1f1;
  margin-right: 15px;
  font-size: 40px;
  text-align: center;
  min-width: 120px;
  min-height: 120px;
}
.countdown-timer li span {
  color: #c0c0c0;
  display: block;
  font-size: 15px;
  margin-top: 5px;
}
.countdown-timer.timer-box {
  padding: 20px;
  padding-top: 40px;
  text-align: center;
}
.countdown-timer.timer-box li {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.05);
  margin-bottom: 15px;
}
.countdown-timer.timer-box li span {
  color: #eeeeee;
}
.countdown-timer.timer-deep-bg li {
  background-color: rgba(0, 0, 0, 0.05);
}
@media only screen and (max-width: 767px) {
  .timer-responsive li {
    margin-top: 30px;
    font-size: 20px;
    min-width: 60px;
    min-height: 60px;
    padding: 20px 10px;
  }
  .countdown-timer li span {
    font-size: 12px;
  }
}
/*
 * Shortcode: Countdown-Timer.less
 * -----------------------------------------------
*/
/*
 * Shortcode: Dividers.less
 * -----------------------------------------------
*/
.divider,
.parallax {
  background-repeat: repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
}
/* -------- Parallax ---------- */
.parallax {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: repeat-y;
  background-position: top center;
}
.mobile-parallax {
  background-size: cover !important;
  background-attachment: scroll !important;
  background-position: center center;
}
.divider .container {
  padding-top: 70px;
  padding-bottom: 70px;
}
.divider .container.small-padding {
  padding-top: 30px;
  padding-bottom: 30px;
}
.divider .container.extra-padding {
  padding-top: 180px;
  padding-bottom: 180px;
}
/*
 * Shortcode: Facebook-Disqus-Comments.less
 * -----------------------------------------------
*/
.fb-comments,
.fb_iframe_widget,
.fb-comments > span,
.fb_iframe_widget > span,
.fb-comments > span > iframe,
.fb_iframe_widget > span > iframe {
  display: block !important;
  margin: 0;
  width: 100% !important;
}
/*
 * Shortcode: Faqs.less
 * -----------------------------------------------
*/
/*
 * Shortcode: Flexslider.less
 * -----------------------------------------------
*/
.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus {
  outline: none;
  border: none;
}
.flex-direction-nav {
  display: none;
}
.flexslider {
  position: relative;
  margin: 0;
  padding: 0;
  border: 0;
}
.flex-viewport {
  max-height: 2000px;
  -webkit-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.flex-control-nav {
  margin: 0;
  padding: 0;
  list-style: none;
  border: none;
  width: auto;
  position: absolute;
  z-index: 10;
  text-align: center;
  bottom: 15px;
  right: 15px;
}
.flex-control-nav li {
  float: left;
  display: block;
  margin: 0 3px;
  width: 10px;
  height: 10px;
}
.flex-control-nav li a {
  display: block;
  cursor: pointer;
  text-indent: -9999px;
  width: 10px;
  height: 10px;
  border: 1px solid #fff;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.flex-control-nav li:hover a,
.flex-control-nav li a.flex-active {
  background-color: #fff;
}
/*
 * Shortcode: Flip Box
 * -----------------------------------------------
*/
.card {
  float: left;
  min-height: 300px;
  padding-bottom: 25%;
  position: relative;
  text-align: center;
  width: 100%;
}
.card:nth-child(1) {
  margin-left: -3px;
  margin-right: 1px;
}
.card:nth-child(1),
.card .card:nth-child(3) {
  margin-right: 1px;
}
.card .card__front,
.card .card__back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
}
.card .card__back {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}
.card .card__text {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  line-height: 20px;
}
.card.effect__hover:hover .card__front {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}
.card.effect__hover:hover .card__back {
  -webkit-transform: rotateY(0);
  transform: rotateY(0);
}
.card.effect__click.flipped .card__front {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}
.card.effect__click.flipped .card__back {
  -webkit-transform: rotateY(0);
  transform: rotateY(0);
}
.card.effect__random.flipped .card__front {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}
.card.effect__random.flipped .card__back {
  -webkit-transform: rotateY(0);
  transform: rotateY(0);
}
/*
 * Responsive : Flip Box
 * -----------------------------------------------
*/
@media only screen and (max-width: 767px) {
  .card {
    margin-bottom: 30px;
    min-height: 489px;
  }
}
@media only screen and (min-width: 501px) and (max-width: 600px) {
  .card {
    margin-bottom: 30px;
    min-height: 400px;
  }
}
@media only screen and (min-width: 450px) and (max-width: 500px) {
  .card {
    margin-bottom: 30px;
    min-height: 350px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 449px) {
  .card {
    margin-bottom: 30px;
    min-height: 300px;
  }
}
/*
 * Shortcode: Font-Icons-Style.less
 * -----------------------------------------------
*/
/* common style */
.styled-icons a {
  color: #333333;
  font-size: 18px;
  height: 32px;
  line-height: 32px;
  width: 32px;
  float: left;
  margin: 5px 7px 5px 0;
  text-align: center;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.styled-icons a:hover {
  color: #666666;
}
.styled-icons.icon-gray a {
  background-color: #eeeeee;
  color: #555555;
  display: block;
  font-size: 18px;
  height: 36px;
  line-height: 36px;
  width: 36px;
}
.styled-icons.icon-gray a:hover {
  color: #bbbbbb;
}
.styled-icons.icon-gray.icon-bordered a {
  background-color: transparent;
  border: 2px solid #eeeeee;
}
.styled-icons.icon-gray.icon-bordered a:hover {
  border: 2px solid #d5d5d5;
}
.styled-icons.icon-dark a {
  background-color: #111111;
  color: #fff;
  display: block;
  font-size: 18px;
  height: 36px;
  line-height: 36px;
  width: 36px;
}
.styled-icons.icon-dark a:hover {
  color: #5e5e5e;
}
.styled-icons.icon-dark.icon-bordered a {
  background-color: transparent;
  border: 2px solid #111111;
  color: #111111;
}
.styled-icons.icon-dark.icon-bordered a:hover {
  background-color: #111111;
  border-color: #111111;
  color: #fff;
}
.styled-icons.icon-bordered a {
  border: 1px solid #777777;
}
.styled-icons.icon-bordered a:hover {
  background-color: #777777;
  color: #fff;
}
.styled-icons.icon-rounded a {
  border-radius: 3px;
}
.styled-icons.icon-circled a {
  border-radius: 50%;
}
.styled-icons.icon-sm a {
  font-size: 13px;
  height: 30px;
  line-height: 30px;
  margin: 0;
  width: 30px;
}
.styled-icons.icon-md a {
  font-size: 24px;
  height: 50px;
  line-height: 50px;
  width: 50px;
}
.styled-icons.icon-lg a {
  font-size: 32px;
  height: 60px;
  line-height: 60px;
  width: 60px;
}
.styled-icons.icon-xl a {
  font-size: 60px;
  height: 120px;
  line-height: 120px;
  width: 120px;
}
.styled-icons li {
  display: inline-block;
  margin-bottom: 0;
  margin-top: 0;
}
/*
 * Shortcode: Funfacts.less
 * -----------------------------------------------
*/
.funfact {
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.funfact.border {
  border: 1px solid #d3d3d3;
}
.funfact.no-border {
  border: 0;
}
.funfact.border {
  border: 1px solid #d3d3d3;
}
.funfact i {
  font-size: 45px;
}
.funfact .animate-number {
  position: relative;
  line-height: 1;
}
.funfact .animate-number:after {
  bottom: 0;
}
.funfact span {
  color: #eeeeee;
  font-size: 18px;
}
/*
 * Shortcode: Icon Boxes
 * -----------------------------------------------
*/
.icon-box {
  margin-bottom: 30px;
}
.icon-box .icon {
  display: inline-block;
  height: 70px;
  margin-bottom: 20px;
  text-align: center;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 70px;
}
.icon-box .icon i {
  line-height: 70px;
}
.icon-box .icon.icon-gray {
  background-color: #eeeeee;
  color: #555555;
}
.icon-box .icon.icon-gray:hover {
  background-color: #d5d5d5;
  color: #bbbbbb;
}
.icon-box .icon.icon-gray.icon-bordered {
  background-color: transparent;
  border: 2px solid #eeeeee;
}
.icon-box .icon.icon-gray.icon-bordered:hover {
  background-color: #eeeeee;
  color: #555555;
}
.icon-box .icon.icon-dark {
  background-color: #111111;
  color: #fff;
}
.icon-box .icon.icon-dark:hover {
  color: #5e5e5e;
}
.icon-box .icon.icon-dark.icon-bordered {
  background-color: transparent;
  border: 2px solid #111111;
  color: #111111;
}
.icon-box .icon.icon-dark.icon-bordered:hover {
  background-color: #111111;
  border-color: #111111;
  color: #fff;
}
.icon-box .icon.icon-white {
  background-color: #fff;
  color: #333333;
}
.icon-box .icon.icon-white:hover {
  background-color: #b3b3b3;
  color: #000000;
}
.icon-box .icon.icon-white.icon-bordered {
  background-color: transparent;
  border: 2px solid #fff;
  color: #fff;
}
.icon-box .icon.icon-white.icon-bordered:hover {
  background-color: #fff;
  border-color: #fff;
  color: #111111;
}
.icon-box .icon.icon-white.icon-border-effect {
  position: relative;
}
.icon-box .icon.icon-white.icon-border-effect::after {
  box-shadow: 0 0 0 3px #fff;
}
.icon-box .icon.icon-bordered {
  border: 1px solid #777777;
}
.icon-box .icon.icon-bordered:hover {
  background-color: #777777;
  color: #fff;
}
.icon-box .icon.icon-rounded {
  border-radius: 3px;
}
.icon-box .icon.icon-circled {
  border-radius: 50%;
}
.icon-box .icon.icon-xs {
  height: 30px;
  width: 30px;
}
.icon-box .icon.icon-xs i {
  font-size: 18px;
  line-height: 30px;
}
.icon-box .icon.icon-sm {
  height: 50px;
  width: 50px;
}
.icon-box .icon.icon-sm i {
  font-size: 22px;
  line-height: 50px;
}
.icon-box .icon.icon-md {
  height: 75px;
  width: 75px;
}
.icon-box .icon.icon-md i {
  font-size: 36px;
  line-height: 75px;
}
.icon-box .icon.icon-lg {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 110px;
  width: 110px;
  border: 5px solid #fff;
  box-shadow: 0px 7px 7px 0px #00000029;
}
.icon-box .icon.icon-lg i {
  font-size: 48px;
  line-height: 90px;
}
.icon-box .icon.icon-xl {
  height: 120px;
  width: 120px;
}
.icon-box .icon.icon-xl i {
  font-size: 60px;
  line-height: 120px;
}
.icon-box .icon.icon-border-effect {
  position: relative;
}
.icon-box .icon.icon-border-effect::after {
  border-radius: 50%;
  box-shadow: 0 0 0 3px;
  box-sizing: content-box;
  content: "";
  height: 100%;
  left: -4px;
  opacity: 0;
  padding: 4px;
  top: -4px;
  transform: scale(0.8);
  transition: transform 0.2s ease 0s, opacity 0.2s ease 0s;
  pointer-events: none;
  position: absolute;
  width: 100%;
}
.icon-box .icon.icon-border-effect.effect-flat::after {
  border-radius: 0;
}
.icon-box .icon.icon-border-effect.effect-rounded::after {
  border-radius: 3px;
}
.icon-box .icon.icon-border-effect.effect-circled::after {
  border-radius: 50%;
}
.icon-box .icon.icon-border-effect.icon-gray::after {
  box-shadow: 0 0 0 3px #eeeeee;
}
.icon-box .icon.icon-top {
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: -32px;
}
.icon-box i {
  display: inline-block;
  font-size: 40px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.icon-box i[class*="pe-7s-"] {
  vertical-align: text-bottom;
}
.icon-box.left {
  text-align: left;
}
.icon-box.left a {
  margin-right: 30px;
  padding: 0;
}
.icon-box.iconbox-border {
  border: 1px solid #dcdcdc;
}
.icon-box.iconbox-bg {
  background-color: #fafafa;
}
.icon-box.iconbox-bg.iconbox-bg-dark {
  background-color: #333333;
}
.icon-box .icon-box-title {
  margin-bottom: 15px;
}
.rotate {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 700ms ease-in-out 0s;
  -o-transition: all 700ms ease-in-out 0s;
  transition: all 700ms ease-in-out 0s;
}
.no-rotate {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: all 700ms ease-in-out 0s;
  -o-transition: all 700ms ease-in-out 0s;
  transition: all 700ms ease-in-out 0s;
}
.icon-box:hover .icon-border-effect::after {
  opacity: 1;
  transform: scale(1);
}
.icon-box .icon-border-effect:hover::after {
  opacity: 1;
  transform: scale(1);
}
/*
 * 5.0 -> Img Icon Box
 * -----------------------------------------------
*/
.img-icon-box {
  position: relative;
  overflow: hidden;
  -webkit-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  -o-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
}
.img-icon-box:before {
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  display: block;
}
.img-icon-box i,
.img-icon-box .img-icon-box h3 {
  -webkit-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  -o-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
}
.img-icon-box .img-icon-details {
  position: relative;
  min-height: 330px;
}
.img-icon-box .img-icon-details i {
  color: #fff;
}
.img-icon-box .img-icon-content {
  opacity: 0;
  -webkit-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  -o-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
}
.img-icon-box:hover i {
  font-size: 0 !important;
}
.img-icon-box:hover .img-icon-content {
  opacity: 1;
}
/* -------- Icon Box Services ---------- */
.service-icon-box {
  margin-bottom: 30px;
  overflow: hidden;
}
.service-icon-box a {
  background: #eeeeee;
  color: #fff;
  display: inline-block;
  height: 70px;
  line-height: 80px;
  text-align: center;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  width: 70px;
}
.service-icon-box a i {
  font-size: 28px;
}
.service-icon-box:hover a i {
  color: #fff;
}
/* -------- Features Services -------
--- */
.feature-icon-box {
  border: 1px solid #f1f1f1;
}
.feature-icon-box .feature-icon {
  border: 4px solid #f1f1f1;
  padding: 10px 15px;
  position: absolute;
  top: -30px;
}
/*
 * Shortcode: Infinitescroll.less
 * -----------------------------------------------
*/
#infscr-loading {
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 3px;
  color: #fff;
  font-size: 24px;
  height: 50px;
  line-height: 50px;
  left: 48%;
  position: fixed;
  text-align: center;
  top: 50%;
  width: 50px;
  z-index: 100;
}
#infscr-loading img {
  display: none;
}
/*
 * Shortcode: Instagram.less
 * -----------------------------------------------
*/
/* -------- Instagram-Feed ---------- */
.instagram-feed-carousel figure,
.instagram-feed figure {
  position: relative;
}
.instagram-feed-carousel figure .link-out,
.instagram-feed figure .link-out {
  position: absolute;
  right: 10px;
  bottom: 10px;
  background: rgba(0, 0, 0, 0.45);
  color: #f8f8f8;
  line-height: 1;
  padding: 10px;
  font-weight: 300;
  opacity: 0;
}
.instagram-feed-carousel figure .link-out:hover,
.instagram-feed figure .link-out:hover {
  background: rgba(0, 0, 0, 0.7);
  color: #f8f8f8;
}
.instagram-feed-carousel figure:hover .link-out,
.instagram-feed figure:hover .link-out {
  opacity: 1;
}
/* -------- Instagram-Feed ---------- */
.instagram-feed .item {
  width: 100px;
  float: left;
  margin-bottom: 2px;
  margin-right: 2px;
}
/* -------- Widget - Instagram-Feed ---------- */
.widget .instagram-feed .item {
  margin-bottom: 5px;
  margin-right: 5px;
  width: 70px;
}
/*
 * Shortcode: Light-Box.less
 * -----------------------------------------------
*/
.nivo-lightbox-theme-default .nivo-lightbox-nav {
  height: 100%;
  top: 0;
}
.lightbox .lb-dataContainer {
  left: 0;
  position: absolute;
  right: 0;
  top: -45px;
}
/*
 * Shortcode: Map.less
 * -----------------------------------------------
*/
.toggle-map {
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 16px;
  padding: 30px 0;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  z-index: 200;
  -webkit-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
}
.toggle-map:hover {
  color: #fff;
}
.toggle-map span:after {
  content: "\f107";
  font-family: fontawesome;
  margin-left: 10px;
}
.toggle-map.open span:after {
  content: "\f106";
}
.map-canvas {
  height: 400px;
  -webkit-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.map-canvas.big {
  height: 720px;
}
.map-canvas.map-hidden {
  height: 0;
}
.btn-show-map {
  color: #111111;
  font-size: 18px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: 45%;
}
.btn-show-map i {
  font-size: 48px;
}
/*
 * Shortcode: Pegination.less
 * -----------------------------------------------
*/
.pagination.theme-colored li.active a {
  color: #fff;
  border: 1px solid transparent;
}
.pagination.theme-colored li a:hover {
  color: #fff !important;
  border: 1px solid transparent;
}
.pagination.dark li a {
  color: #333333;
}
.pagination.dark li.active a {
  background: #333333;
  color: #fff;
  border: 1px solid transparent;
}
.pager.theme-colored li.active a {
  color: #fff;
  border: 1px solid transparent;
}
.pager.dark li a {
  color: #fff;
  background-color: #333333;
  border: 1px solid transparent;
}
/*
 * Shortcode: Pricing
 * -----------------------------------------------
*/
.pricing-table {
  margin-bottom: 30px;
  position: relative;
}
.pricing-table .table-price {
  color: #111111;
  font-size: 45px;
}
.pricing-table .table-price span {
  font-size: 13px;
  vertical-align: middle;
}
.pricing-table.featured {
  border: 1px solid #111111;
}
.pricing-table .table-type {
  display: inline;
}
.pricing-table .btn-signup {
  margin-right: 0;
  width: 100%;
}
.pricing-table .table-list {
  list-style: outside none none;
  padding: 10px 0 10px 20px;
  background: #f8f8f8;
}
.pricing-table .table-list.list.check-circle li {
  padding: 5px 0 5px 30px;
}
.pricing-table .table-list.list.check-circle li:before {
  content: "";
  font-family: fontawesome;
  font-size: 20px;
  vertical-align: middle;
}
.pricing-table .table-list li {
  border-bottom: 1px solid #ededed;
  padding: 5px 0;
}
.pricing-table .table-list li i {
  margin-right: 8px;
}
.pricing-table .price-amount {
  width: 80px;
  height: 80px;
  margin-top: -73px;
  margin-left: 20px;
  margin-right: 20px;
  line-height: 80px;
  font-size: 2rem;
}
/*
 * Shortcode: Process-Steps.less
 * -----------------------------------------------
*/
/*
 * Shortcode: Progress-Bar.less
 * -----------------------------------------------
*/
.progress-item {
  margin-top: 20px;
}
.progress-item .progress {
  background-color: #f8f8f8;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.4) inset;
  border-radius: 0;
  height: 10px;
  margin-bottom: 15px;
  overflow: visible;
}
.progress-item .progress-bar {
  font-size: 11px;
  padding-left: 10px;
  position: relative;
  text-align: left;
  width: 0;
  line-height: 18px;
  -webkit-transition: all 1s ease 0s;
  -o-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
}
.progress-item .progress-bar .percent {
  background: none repeat scroll 0 0 #555555;
  border-radius: 8px 0 0;
  padding: 3px 5px;
  position: absolute;
  right: 0;
  top: -30px;
}
.progress-item.progress-white .progress {
  padding: 2px;
}
.progress-item.style2 .progress {
  height: 25px;
}
.progress-item.style2 .progress-bar .percent {
  background: none;
  color: #555555;
  font-size: 14px;
}
/*
 * Shortcode: Promo.less
 * -----------------------------------------------
*/
.modal-promo-box {
  max-width: 700px;
  padding: 90px 60px;
  margin: 0 auto;
}
/*
 * Shortcode: Section Title
 * -----------------------------------------------
*/
.small-title {
  position: relative;
}
.small-title i {
  font-size: 36px;
  margin-top: 7px;
  padding-top: 0px;
}
.small-title .title {
  margin-left: 15px;
}
.small-title .title:after {
  bottom: -6px;
  content: "";
  height: 1px;
  left: 45px;
  position: absolute;
  width: 45px;
}
.title-pattern {
  background: rgba(0, 0, 0, 0) url("../images/header-bg-pattern.png") repeat
    scroll left top;
  line-height: 1;
  padding-bottom: 4px;
}
.title-pattern > span {
  background: #fff;
  padding-right: 15px;
}
.section-title .both-side-line::after {
  background: #c0c0c0 none repeat scroll 0 0;
  content: "";
  height: 2px;
  position: absolute;
  right: -40px;
  top: 10px;
  width: 30px;
}
.section-title .both-side-line::before {
  background: #c0c0c0 none repeat scroll 0 0;
  content: "";
  height: 2px;
  position: absolute;
  left: -40px;
  top: 10px;
  width: 30px;
}
.section-title .top-side-line::after {
  background: #c0c0c0 none repeat scroll 0 0;
  content: "";
  height: 2px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: -8px;
  width: 40px;
}
.section-title .left-side-line::before {
  background: #c0c0c0 none repeat scroll 0 0;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  top: -8px;
  width: 35px;
}
.section-title .right-side-line::before {
  background: #c0c0c0 none repeat scroll 0 0;
  content: "";
  height: 2px;
  position: absolute;
  right: 0;
  top: -8px;
  width: 35px;
}
.section-title .sub-title {
  margin: 0;
  position: relative;
  display: inline-block;
}
.section-title .title {
  margin-top: 0;
  position: relative;
}
.section-title p {
  font-size: 14px;
}
.section-title p.left-bordered {
  border-left: 2px solid #c0c0c0;
  padding-left: 10px;
}
.section-title p.right-bordered {
  border-right: 2px solid #c0c0c0;
  padding-right: 10px;
}
/*Theme Section Title*/
.section-title {
  margin-bottom: 30px;
}
.section-title.title-border {
  padding-left: 30px;
}
.section-title.title-border.title-right {
  border-left: 0;
  padding-left: 0;
  padding-right: 30px;
  text-align: right;
}
.section-title.icon-bg p {
  font-size: 14px;
  font-style: italic;
  margin-bottom: 0;
}
.section-title.icon-bg .page-title {
  font-size: 38px;
  line-height: 52px;
}
.section-title.icon-bg .page-title i {
  font-size: 42px;
  line-height: 1.3;
  position: absolute;
  opacity: 0.15;
  left: 0;
  right: 0;
  margin: 0 auto;
}
/*
 * Shortcode: Separators.less
 * -----------------------------------------------
*/
.title-separator {
  border-top-style: solid;
  border-top-width: 1px;
  font-size: 16px;
  height: auto;
  line-height: 20px;
  margin: 10px 0;
  width: 70%;
}
.title-separator span {
  background: #fff none repeat scroll 0 0;
  border-radius: 2px;
  letter-spacing: 0.2em;
  padding: 2px 10px;
  position: relative;
  text-transform: uppercase;
  top: -12px;
}
.separator {
  color: #c0c0c0;
  line-height: 1.2em;
  margin: 30px auto;
  overflow: hidden;
  text-align: center;
  width: 100%;
}
.separator::before,
.separator::after {
  border-bottom: 1px solid #eeeeee;
  content: "";
  display: inline-block;
  height: 0.65em;
  margin: 0 -4px 0 -100%;
  vertical-align: top;
  width: 50%;
}
.separator::after {
  margin: 0 -100% 0 0;
}
.separator i,
.separator span,
.separator a {
  display: inline-block;
  margin: 0 20px 0 24px;
  font-size: 20px;
}
.separator.separator-left,
.separator.left i {
  float: left;
  margin: 2px 50px 0 0;
}
.separator.right i {
  float: right;
  margin: 0 0 2px 50px;
}
.separator.separator-medium-line {
  margin: 10px auto;
  width: 20%;
}
.separator.separator-small-line {
  width: 10%;
}
.separator.separator-rouned i,
.separator.separator-border-rouned i {
  background-color: #f5f5f5;
  border-radius: 50%;
  height: 40px;
  left: 0;
  line-height: 40px;
  margin: -12px auto auto;
  position: absolute;
  right: 0;
  width: 40px;
}
.separator.separator-border-rouned i {
  background: transparent;
  border: 1px solid #f5f5f5;
}
.separator.separator-rouned.separator-rouned-left i {
  margin: -12px auto auto;
  right: auto;
  left: 0;
}
.separator.separator-rouned.separator-rouned-right i {
  margin: -12px auto auto;
  right: 0;
  left: auto;
}
.separator.dotted::before,
.separator.dotted::after {
  border-bottom: 2px dotted #c0c0c0;
}
/*
 * Shortcode: styled-icons.less
 * -----------------------------------------------
*/
/* common style */
.styled-icons a {
  color: #333333;
  font-size: 18px;
  height: 32px;
  line-height: 32px;
  width: 32px;
  float: left;
  margin: 5px 7px 5px 0;
  text-align: center;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.styled-icons a:hover {
  color: #666666;
}
.styled-icons.icon-gray a {
  background-color: #eeeeee;
  color: #555555;
  display: block;
  font-size: 18px;
  height: 36px;
  line-height: 36px;
  width: 36px;
}
.styled-icons.icon-gray a:hover {
  color: #bbbbbb;
}
.styled-icons.icon-gray.icon-bordered a {
  background-color: transparent;
  border: 2px solid #eeeeee;
}
.styled-icons.icon-gray.icon-bordered a:hover {
  border: 2px solid #d5d5d5;
}
.styled-icons.icon-dark a {
  background-color: #333333;
  color: #eeeeee;
  display: block;
  font-size: 18px;
  height: 36px;
  line-height: 36px;
  width: 36px;
}
.styled-icons.icon-dark a:hover {
  color: #5e5e5e;
}
.styled-icons.icon-dark.icon-bordered a {
  background-color: transparent;
  border: 2px solid #111111;
  color: #111111;
}
.styled-icons.icon-dark.icon-bordered a:hover {
  background-color: #111111;
  border-color: #111111;
  color: #fff;
}
.styled-icons.icon-bordered a {
  border: 1px solid #777777;
}
.styled-icons.icon-bordered a:hover {
  background-color: #777777;
  color: #fff;
}
.styled-icons.icon-rounded a {
  border-radius: 3px;
}
.styled-icons.icon-circled a {
  border-radius: 50%;
}
.styled-icons.icon-sm a {
  font-size: 13px;
  height: 30px;
  line-height: 30px;
  margin: 2px 7px 2px 0;
  width: 30px;
}
.styled-icons.icon-md a {
  font-size: 24px;
  height: 50px;
  line-height: 50px;
  width: 50px;
}
.styled-icons.icon-lg a {
  font-size: 32px;
  height: 60px;
  line-height: 60px;
  width: 60px;
}
.styled-icons.icon-xl a {
  font-size: 60px;
  height: 120px;
  line-height: 120px;
  width: 120px;
}
.styled-icons li {
  display: inline-block;
  margin-bottom: 0;
  margin-top: 0;
}
/*
 * Shortcode: Tabs.less
 * -----------------------------------------------
*/
.tab-content {
  padding: 15px;
  border: 1px solid #eeeeee;
}
.nav-tabs {
  border: none;
}
.nav-tabs > li {
  float: left;
  margin-bottom: -1px;
}
.nav-tabs > li.active a,
.nav-tabs > li.active a:hover,
.nav-tabs > li.active a:focus {
  color: #fff;
  background-color: #a9a9a9;
}
.nav-tabs > li > a {
  background-color: #f5f5f5;
  border-radius: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  color: #555555;
  padding: 7px 15px;
  margin-right: 0;
}
.nav-tabs > li > a .fa {
  margin-right: 10px;
}
.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
  color: #333333;
  background-color: transparent;
}
.nav-tabs > li > a i {
  font-size: 32px;
  margin-right: 20px;
  vertical-align: middle;
}
/* -------- Nav Tabs ---------- */
.nav-tabs > li {
  text-align: center;
}
.nav-tabs > li > a i {
  display: block;
  font-size: 48px;
  margin-right: 0;
  margin-bottom: 10px;
  vertical-align: middle;
}
/* -------- Horizontal-Tab-Centered ---------- */
.horizontal-tab-centered .nav-pills {
  display: inline-block;
}
.horizontal-tab-centered .nav-pills > li > a {
  background: #eeeeee;
  color: #555555;
  font-size: 14px;
  padding: 8px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  text-transform: capitalize;
}
.horizontal-tab-centered .nav-pills > li > a:hover {
  color: #fff;
}
.horizontal-tab-centered .nav-pills > li > a i {
  font-size: 14px;
  margin-right: 8px;
}
.horizontal-tab-centered .nav-pills > li.active > a,
.horizontal-tab-centered .nav-pills > li.active > a:hover,
.horizontal-tab-centered .nav-pills > li.active > a:focus {
  color: #fff;
}
/* -------- Horizontal-Tab Fullwide ---------- */
.horizontal-tab.fullwide .nav-tabs > li {
  width: 20%;
}
/* -------- Vertical Nav-Tabs ---------- */
.vertical-tab .tab-content {
  padding: 5px 5px 20px 20px;
  border: none;
}
.vertical-tab .nav-tabs > li {
  float: none;
  margin-bottom: 0;
}
.vertical-tab .nav-tabs > li > a {
  background: none;
  border-radius: 0;
  border-bottom: 1px solid #d3d3d3;
  border-right: 1px solid #d3d3d3;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  font-size: 14px;
  padding: 30px 0 30px 15px;
}
.vertical-tab .nav-tabs > li:first-child > a {
  border-top: 1px solid #d3d3d3 !important;
}
.vertical-tab .nav-tabs > li.active > a,
.vertical-tab .nav-tabs > li.active > a:hover,
.vertical-tab .nav-tabs > li.active > a:focus {
  background: none;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid #d3d3d3;
}
.vertical-tab .small-padding .nav-tabs > li a {
  padding: 20px 0 20px 15px;
}
.vertical-tab .small-padding .nav-tabs > li i {
  font-size: 24px;
}
@media only screen and (max-width: 767px) {
  .vertical-tab .tab-content {
    padding: 20px 5px;
  }
  .nav-tabs > li > a {
    border-radius: 0;
    border-bottom: none;
    border-left: 2px solid #d3d3d3;
  }
  .nav-tabs > li.active > a,
  .nav-tabs > li.active > a:hover,
  .nav-tabs > li.active > a:focus {
    border-bottom: none;
    border-left: 2px solid #d3d3d3;
  }
}
.custom-nav-tabs {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: -moz-use-text-color;
  border-image: none;
  border-style: none solid none none;
  border-width: medium 0px medium medium;
  margin-right: 53px;
}
.custom-nav-tabs > li {
  float: none;
  margin: 0 0 1px;
}
.custom-nav-tabs > li a {
  background: #eeeeee none repeat scroll 0 0;
  border: medium none;
  border-radius: 0;
  display: block;
  font-size: 17px;
  font-weight: 700;
  line-height: 59px;
  margin: 0;
  outline: medium none;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
}
.custom-nav-tabs > li.active > a,
.custom-nav-tabs > li.active > a:hover,
.custom-nav-tabs > li.active > a:focus,
.custom-nav-tabs > li > a:hover,
.custom-nav-tabs > li > a:focus {
  border: medium none;
  color: #000;
  padding: 0;
}
/* -------- Services Tab ---------- */
.services-tab .nav-tabs {
  border-bottom: 1px solid #f1f1f1;
  text-align: center;
}
.services-tab .nav-tabs > li {
  border-left: 1px solid #eeeeee;
  margin-bottom: 0;
  width: 20%;
}
.services-tab .nav-tabs > li:first-child {
  border-left: none;
}
.services-tab .nav-tabs > li > a {
  background-color: #fff;
  color: #a9a9a9;
  font-size: 13px;
  margin-right: 0;
  padding: 35px 20px;
  text-transform: uppercase;
}
.services-tab .nav-tabs > li > a i {
  display: block;
  font-size: 36px;
  margin-right: 0;
  margin-bottom: 10px;
  vertical-align: middle;
}
.services-tab .nav-tabs > li.active > a,
.services-tab .nav-tabs > li.active > a:hover,
.services-tab .nav-tabs > li.active > a:focus,
.services-tab .nav-tabs > li a:hover,
.services-tab .nav-tabs > li a:focus {
  color: #fff;
}
.services-tab .nav-tabs > li.active > a i,
.services-tab .nav-tabs > li.active > a:hover i,
.services-tab .nav-tabs > li.active > a:focus i,
.services-tab .nav-tabs > li a:hover i,
.services-tab .nav-tabs > li a:focus i {
  color: #fff;
}
.services-tab .tab-content .service-content .sub-title {
  color: #c8c8c8;
  font-family: "Playfair Display", sans-serif;
}
.services-tab .tab-content .service-content .title {
  color: #555555;
  font-family: "Playfair Display", sans-serif;
}
@media only screen and (max-width: 1199px) {
  .services-tab .service-content {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 991px) {
  .services-tab .service-content {
    padding: 0 20px;
  }
}
@media only screen and (max-width: 767px) {
  .services-tab .nav.nav-tabs a {
    padding: 10px 0;
    font-size: 9px;
  }
  .services-tab .nav.nav-tabs a i {
    font-size: 28px;
  }
}
.vertical-tab .nav-tabs li a:active {
  color: #d3d3d3;
}
/*
 * Shortcode: team.less
 * -----------------------------------------------
*/
.team-member {
  overflow: hidden;
  position: relative;
  -webkit-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
}
.team-member:hover::before {
  background: transparent;
  content: "";
  height: 100%;
  position: static;
  width: 90%;
}
.team-member .member-info {
  background: rgba(0, 0, 0, 0.6);
  bottom: -100%;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  -webkit-transition: all 0.4s linear 0s;
  -o-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
}
.team-member .member-info .member-biography {
  opacity: 1;
  position: relative;
  right: auto;
  left: auto;
  top: auto;
  bottom: auto;
  text-align: center;
  z-index: 3;
}
.team-member:hover .member-info {
  bottom: 0;
}
/*
 * Shortcode: Testimonials.less
 * -----------------------------------------------
*/
.testimonial .thumb img {
  display: inline-block;
  width: auto;
}
.testimonial.style1 .author {
  color: #777777;
}
.testimonial.style1 .title {
  color: #808080;
}
.carousel-control.left,
.carousel-control.right {
  background-image: none !important;
  filter: none;
}
.block-text {
  background-color: #fff;
  box-shadow: 0 1px 5px #e8e9ea;
  color: #626262;
  font-size: 14px;
  margin-top: 27px;
  padding-bottom: 1px;
}
.block-text a {
  color: #7d4702;
  font-size: 25px;
  font-weight: bold;
  line-height: 21px;
  text-decoration: none;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}
.mark {
  padding: 12px 0;
  background: none;
}
.block-text p {
  color: #585858;
  font-style: italic;
  line-height: 20px;
}
.sprite {
  border-left: 18px solid rgba(0, 0, 0, 0);
  border-top: 18px solid rgba(241, 241, 241, 0.8);
  height: 0;
  left: -18px;
  position: absolute;
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  transform: rotate(360deg);
  width: 0;
}
.block-text ins {
  bottom: -18px;
  left: 0;
  margin: auto;
  right: 0;
}
.person-name {
  padding: 5px 0;
}
.block {
  display: block;
}
.zmin {
  z-index: 1;
}
.ab {
  position: absolute;
}
.person-text {
  padding: 24px 0 0;
  text-align: center;
  z-index: 2;
}
.person-text a {
  color: #ffcc00;
  display: block;
  font-size: 14px;
  margin-top: 3px;
  text-decoration: underline;
}
.person-text i {
  color: #fff;
  font-family: Georgia;
  font-size: 13px;
}
.rel {
  position: relative;
}
/*Style 2*/
.feedback {
  position: relative;
}
.big-triangle,
.big-triangle-second-color {
  border-style: solid;
  border-width: 0 0 80px 80px;
  height: 0;
  position: absolute;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
  width: 0;
  top: 0;
  left: 0;
}
.big-icon,
.big-icon-second-color {
  border-radius: 50%;
  bottom: 7px;
  height: 49px;
  position: relative;
  right: 3px;
  text-align: center;
  width: 49px;
  z-index: 20;
  background: #fff;
}
.big-icon i.fa {
  line-height: 49px;
  color: #e6d8ad;
}
/* -------- Testimonial-Carousel ---------- */

.testimonial-carousel .quote,
.testimonial-carousel-style2 .quote,
.testimonial-carousel .thumb,
.testimonial-carousel-style2 .thumb {
  margin-bottom: 40px;
}
.testimonial-carousel .quote i,
.testimonial-carousel-style2 .quote i {
  background: #555555;
  border-radius: 50%;
  color: #fff;
  font-size: 25px;
  height: 60px;
  line-height: 1;
  padding: 20px 0 0;
  text-align: center;
  width: 60px;
}
.testimonial-carousel .author,
.testimonial-carousel-style2 .author {
  margin-top: 30px;
  text-transform: uppercase;
}
.testimonial-carousel.owl-theme .owl-controls,
.testimonial-carousel-style2.owl-theme .owl-controls {
  margin-top: 10px;
}
.testimonial-carousel.fullwidth .content p,
.testimonial-carousel-style2.fullwidth .content p {
  font-size: 15px;
  font-style: italic;
  color: #fff;
}
.testimonial-carousel.fullwidth .content p::after,
.testimonial-carousel-style2.fullwidth .content p::after {
  content: "\f10e";
  font-family: fontawesome;
  font-size: 100px;
  left: 42%;
  opacity: 0.1;
  position: absolute;
}
.testimonial-carousel.bullet-white .owl-dots span,
.testimonial-carousel-style2.bullet-white .owl-dots span {
  background: #fff !important;
}
.testimonial-carousel .owl-item img,
.testimonial-carousel-style2 .owl-item img {
  display: inline-block;
  width: auto;
  max-width: 90px;
}
.testimonial-carousel.boxed .quote,
.testimonial-carousel-style2.boxed .quote,
.testimonial-carousel.boxed .thumb,
.testimonial-carousel-style2.boxed .thumb {
  margin-bottom: 20px;
}
.testimonial-carousel.boxed .owl-item img,
.testimonial-carousel-style2.boxed .owl-item img {
  display: inline-block;
  width: auto;
  max-width: 65px;
}
.testimonial-carousel.boxed .content::after,
.testimonial-carousel-style2.boxed .content::after {
  bottom: 12px;
  content: "\f10e";
  font-family: fontawesome;
  font-size: 55px;
  opacity: 0.2;
  position: absolute;
  right: 10px;
}
.testimonial-carousel.boxed .owl-controls,
.testimonial-carousel-style2.boxed .owl-controls {
  margin-top: 0;
}
.testimonial-carousel.boxed .owl-dots,
.testimonial-carousel-style2.boxed .owl-dots {
  text-align: left;
}
.testimonial-carousel.boxed.owl-theme .owl-dots .owl-dot.active span,
.testimonial-carousel-style2.boxed.owl-theme .owl-dots .owl-dot.active span,
.testimonial-carousel.boxed.owl-theme .owl-dots .owl-dot:hover span,
.testimonial-carousel-style2.boxed.owl-theme .owl-dots .owl-dot:hover span {
  opacity: 1;
}
/*
 * Shortcode: Toggles-Accordions.less
 * -----------------------------------------------
*/
.panel-group .panel {
  box-shadow: none;
  -webkit-border-radius: 0 0 0 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0 0 0 0;
  border-radius: 0 0 0 0;
  border: none;
  position: relative;
}
.panel-group .panel-title {
  font-size: 13px;
}
.panel-group .panel-title a {
  background: #f6f6f6;
  display: block;
  font-size: 14px;
  padding: 10px 15px 10px 48px;
  white-space: normal;
}
.panel-group .panel-title a.active {
  background: #f1f1f1 none repeat scroll 0 0 !important;
  border-bottom: 1px solid #d3d3d3 !important;
  color: #111111;
}
.panel-group .panel-title a.active .open-sub:after {
  display: none;
}
.panel-group .panel-title a.active::after {
  color: #fff;
  background: #e3e3e3 !important;
  content: "\f068";
}
.panel-group .panel-title a::after {
  color: #777777;
  content: "\f067";
  font-family: fontawesome;
  font-size: 14px;
  left: 0;
  padding: 10px 14px;
  position: absolute;
  top: 0;
}
.panel-group .panel-title:hover a,
.panel-group .panel-title:focus a {
  color: #333333;
}
.panel-group .panel-title .open-sub {
  display: block;
  position: relative;
}
.panel-group .panel-title .open-sub:before,
.panel-group .panel-title .open-sub:after {
  content: "";
  display: block;
  position: absolute;
  height: 1px;
  top: 50%;
  margin-top: -0.5px;
  left: 50%;
}
.panel-group .panel-content {
  background: #f1f1f1 none repeat scroll 0 0;
  padding: 10px 20px 20px;
}
.panel-group .panel-content > :last-child {
  margin-bottom: 0;
}
/* -------- Toggle ---------- */
.panel-group.toggle .panel-heading {
  padding: 0;
  border-bottom: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.panel-group.toggle .panel-body {
  padding: 10px 0 5px 25px;
}
/* .panel-group.accordion.transparent .panel-title a,
.panel-group.toggle.transparent .panel-title a,
.panel-group.accordion.transparent .panel-title a.active,
.panel-group.toggle.transparent .panel-title a.active {
  background: transparent !important;
  border: none !important;
}
.panel-group.accordion.transparent .panel-title a::after,
.panel-group.toggle.transparent .panel-title a::after,
.panel-group.accordion.transparent .panel-title a.active::after,
.panel-group.toggle.transparent .panel-title a.active::after {
  color: #333333;
  background: transparent !important;
}
.panel-group.accordion.transparent .panel-content,
.panel-group.toggle.transparent .panel-content {
  background: transparent !important;
}
.panel-group.toggle .panel-heading + .panel-collapse > .panel-body,
.panel-group.toggle .panel-heading + .panel-collapse > .list-group {
  border-top: none;
} */
/*
 * Shortcode: Vertical-Timeline.less
 * -----------------------------------------------
*/
.cd-timeline-content {
  background: #f1f1f1;
}
.cd-timeline-content::before {
  border-color: transparent transparent transparent #eee;
}
.cd-timeline-block:nth-child(2n) .cd-timeline-content::before {
  border-color: transparent #eee transparent transparent;
}
#cd-timeline.cd-timeline-simple::before {
  background: #ccc none repeat scroll 0 0;
  height: 82%;
  top: 65px;
  width: 2px;
}
#cd-timeline.cd-timeline-simple.time-line-media::before {
  height: 65%;
}
.cd-timeline-simple .cd-timeline-block {
  margin: 38px 0 20px 0;
}
.cd-timeline-simple .cd-timeline-block .cd-timeline-content::before {
  display: none;
}
.cd-timeline-simple .cd-timeline-block .cd-timeline-content {
  padding-top: 0;
  background: none;
  box-shadow: none;
}
.cd-timeline-simple .cd-timeline-block .cd-timeline-content p {
  font-size: 14px;
}
.cd-timeline-simple .cd-timeline-block .cd-timeline-img {
  background: #333 none repeat scroll 0 0 !important;
  box-shadow: 0 0 0 4px #ccc, 0 3px 0 rgba(0, 0, 0, 0.06) inset,
    0 0px 0 3px rgba(0, 0, 0, 0.05) !important;
}
@media only screen and (max-width: 992px) {
  .cd-timeline-simple .cd-timeline-block .cd-timeline-content {
    text-align: left;
  }
}
@media only screen and (max-width: 768px) {
  .cd-timeline-simple .cd-timeline-block .cd-timeline-content {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 992px) {
  .cd-timeline-simple .cd-timeline-block .cd-timeline-content h2 {
    font-size: 20px;
  }
}
@media only screen and (max-width: 768px) {
  .cd-timeline-simple .cd-timeline-block .cd-timeline-content h2 {
    font-size: 18px;
  }
}
@media only screen and (max-width: 768px) {
  .cd-timeline-simple .cd-timeline-block .cd-timeline-content .cd-date {
    font-size: 14px;
  }
}
/* Vertical Masonry Timeline */
.vertical-masonry-timeline .each-masonry-item {
  position: relative;
  width: 50%;
  margin-bottom: 30px;
}
.vertical-masonry-timeline .each-masonry-item:nth-child(2) {
  margin-top: 80px;
}
.vertical-masonry-timeline .each-masonry-item:nth-child(2n) {
  padding-left: 50px;
}
.vertical-masonry-timeline .each-masonry-item:nth-child(2n + 1) {
  padding-right: 50px;
}
.vertical-masonry-timeline .each-masonry-item .timeline-block {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #d7e4ed;
  height: 100%;
  padding: 20px;
  position: relative;
  width: 100%;
}
.vertical-masonry-timeline .each-masonry-item .timeline-block:before,
.vertical-masonry-timeline .each-masonry-item .timeline-block:after {
  border-style: solid;
  border-width: 8px;
  content: "";
  display: block;
  position: absolute;
  top: 20px;
}
.vertical-masonry-timeline
  .each-masonry-item:nth-child(2n + 1)
  .timeline-block:before {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #c6d2db;
  left: auto;
  right: -16px;
}
.vertical-masonry-timeline
  .each-masonry-item:nth-child(2n + 1)
  .timeline-block:after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #ffffff;
  left: auto;
  right: -15px;
}
.vertical-masonry-timeline
  .each-masonry-item:nth-child(2n)
  .timeline-block:before {
  border-color: rgba(0, 0, 0, 0) #c6d2db rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
  left: -16px;
  right: auto;
}
.vertical-masonry-timeline
  .each-masonry-item:nth-child(2n)
  .timeline-block:after {
  border-color: rgba(0, 0, 0, 0) #ffffff rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
  left: -15px;
  right: auto;
}
.vertical-masonry-timeline
  .each-masonry-item:nth-child(2n + 1)
  .timeline-post-format {
  left: auto;
  right: -82px;
}
.vertical-masonry-timeline
  .each-masonry-item:nth-child(2n)
  .timeline-post-format {
  left: -80px;
  right: auto;
}
.timeline-post-format {
  border: 3px solid #bbb;
  border-radius: 100%;
  color: #bbb;
  height: 60px;
  overflow: hidden;
  position: absolute;
  top: -2px;
  width: 60px;
  z-index: 1;
}
.timeline-post-format:after {
  background: #fff none repeat scroll 0 0;
  color: #fff;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.timeline-post-format i {
  font-size: 18px;
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}
.vertical-masonry-timeline-wrapper:before {
  background: #ddd none repeat scroll 0 0;
  bottom: 0;
  content: "";
  height: 100%;
  left: 50%;
  margin-left: 0px;
  position: absolute;
  width: 4px;
}
.vertical-masonry-timeline-wrapper:after {
  background: rgba(0, 0, 0, 0) linear-gradient(#ddd, transparent) repeat scroll
    0 0;
  bottom: -100px;
  content: "";
  height: 100px;
  left: 50%;
  margin-left: 0px;
  position: absolute;
  width: 4px;
}
.vertical-masonry-timeline::after {
  background: rgba(0, 0, 0, 0) linear-gradient(transparent, #ddd) repeat scroll
    0 0;
  content: "";
  height: 100px;
  left: 50%;
  position: absolute;
  top: -65px;
  width: 4px;
}
@media only screen and (min-width: 768px) {
  .vertical-masonry-timeline .each-masonry-item.item-right {
    padding-right: 0px;
    padding-left: 50px;
  }
  .vertical-masonry-timeline
    .each-masonry-item.item-right
    .timeline-block:before {
    border-color: rgba(0, 0, 0, 0) #c6d2db rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
    left: -16px;
    right: auto;
  }
  .vertical-masonry-timeline
    .each-masonry-item.item-right
    .timeline-block:after {
    border-color: rgba(0, 0, 0, 0) #ffffff rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
    left: -15px;
    right: auto;
  }
  .vertical-masonry-timeline
    .each-masonry-item.item-right
    .timeline-post-format {
    left: -80px;
    right: auto;
  }
  .vertical-masonry-timeline .each-masonry-item.item-left {
    padding-right: 50px;
    padding-left: 0px;
  }
  .vertical-masonry-timeline
    .each-masonry-item.item-left
    .timeline-block:before {
    border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #c6d2db;
    right: -16px;
    left: auto;
  }
  .vertical-masonry-timeline
    .each-masonry-item.item-left
    .timeline-block:after {
    border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #ffffff;
    right: -15px;
    left: auto;
  }
  .vertical-masonry-timeline
    .each-masonry-item.item-left
    .timeline-post-format {
    right: -81px;
    left: auto;
  }
}
@media only screen and (max-width: 768px) {
  .vertical-masonry-timeline .each-masonry-item {
    width: 100%;
  }
  .vertical-masonry-timeline .each-masonry-item:nth-child(2n + 1) {
    padding-right: 0px;
    padding-left: 71px;
  }
  .vertical-masonry-timeline .each-masonry-item:nth-child(2n) {
    padding-left: 71px;
    padding-right: inherit;
  }
  .vertical-masonry-timeline .each-masonry-item:nth-child(2) {
    margin-top: 0;
  }
  .vertical-masonry-timeline
    .each-masonry-item:nth-child(2n + 1)
    .timeline-post-format {
    left: -72px;
    right: auto;
  }
  .vertical-masonry-timeline
    .each-masonry-item:nth-child(2n)
    .timeline-post-format {
    left: -72px;
  }
  .vertical-masonry-timeline
    .each-masonry-item:nth-child(2n + 1)
    .timeline-block::before {
    border-color: rgba(0, 0, 0, 0) #c6d2db rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
    left: -16px;
    right: auto;
  }
  .vertical-masonry-timeline
    .each-masonry-item:nth-child(2n + 1)
    .timeline-block::after {
    border-color: rgba(0, 0, 0, 0) #ffffff rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
    left: -15px;
    right: auto;
  }
  .vertical-masonry-timeline:after {
    left: 29px;
  }
  .vertical-masonry-timeline-wrapper:before,
  .vertical-masonry-timeline-wrapper:after {
    left: 44px;
  }
}
/*
 * Shortcode: Working-Process.less
 * -----------------------------------------------
*/
.working-process > li {
  float: left;
  overflow: hidden;
  position: relative;
  text-align: center;
}
.working-process > li::after {
  border-top: 1px dashed #333333;
  content: "";
  left: 6px;
  margin-left: -32px;
  overflow: hidden;
  position: absolute;
  top: 25px;
  width: 50%;
}
.working-process > li::before {
  border-top: 1px dashed #333333;
  content: "";
  margin-right: -25px;
  position: absolute;
  right: 0;
  top: 25px;
  width: 50%;
}
.working-process > li :first-child::after {
  border-top: medium none;
}
.working-process > li :last-child::before {
  border-top: medium none;
}
.working-process li a {
  border: 1px solid;
  display: inline-block;
  height: 50px;
  line-height: 50px;
  overflow: hidden;
  width: 50px;
  border-radius: 50%;
}
.working-process li a:hover {
  background: #333333 none repeat scroll 0 0;
  border: 1px solid transparent;
  color: #fff;
}
.working-process li a i {
  font-size: 18px;
}
.working-process.square li a,
.working-process.square.large-circle li a {
  border-radius: 0;
}
.working-process.large-circle li a {
  height: 120px;
  line-height: 130px;
  width: 120px;
  border-radius: 50%;
}
.working-process.large-circle li a i {
  font-size: 28px;
}
.working-process.large-circle > li::before {
  margin-right: -60px;
  top: 60px;
}
.working-process.large-circle > li::after {
  margin-left: -64px;
  top: 60px;
}
.working-process.theme-colored li a {
  color: #fff;
}
/*
 * Shortcode: timetable-fullcalendar.less
 * -----------------------------------------------
*/
.fc-timetable-wrapper .fc-scroller {
  height: auto;
  overflow: hidden;
}
.fc-timetable-wrapper .fc-toolbar {
  display: none;
  height: 38px;
  margin-bottom: 20px;
}
.fc-timetable-wrapper .fc-toolbar h2 {
  color: #7f7f7f;
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 38px;
  text-transform: uppercase;
}
.fc-timetable-wrapper .fc-toolbar .fc-button {
  -moz-box-sizing: border-box;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-box-sizing: border-box;
  -webkit-transition: all 0.3s ease;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-shadow: none;
  box-sizing: border-box;
  height: 38px;
  line-height: 36px;
  transition: all 0.3s ease;
  width: 40px;
}
.fc-timetable-wrapper .fc-toolbar .fc-button:hover {
  background: #5fc7ae;
  border-color: #5fc7ae;
}
.fc-timetable-wrapper
  .fc-toolbar
  .fc-button:hover
  .fc-icon.fc-icon-left-single-arrow {
  background-position: -108px -50px;
}
.fc-timetable-wrapper
  .fc-toolbar
  .fc-button:hover
  .fc-icon.fc-icon-right-single-arrow {
  background-position: -115px -50px;
}
.fc-timetable-wrapper .fc-toolbar .fc-button .fc-icon {
  height: 11px;
  top: -1px;
  width: 6px;
}
.fc-timetable-wrapper .fc-toolbar .fc-button .fc-icon:after {
  display: none;
}
.fc-timetable-wrapper
  .fc-toolbar
  .fc-button
  .fc-icon.fc-icon-left-single-arrow {
  background-position: -100px -50px;
}
.fc-timetable-wrapper
  .fc-toolbar
  .fc-button
  .fc-icon.fc-icon-right-single-arrow {
  background-position: -122px -50px;
}
.fc-timetable-wrapper .fc-day-header {
  background: #ffffff;
  color: #7f7f7f;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 52px;
}
.fc-timetable-wrapper .fc-time-grid .fc-day.fc-widget-content {
  background: #ffffff;
}
.fc-timetable-wrapper .fc-axis {
  background: #ffffff;
}
.fc-timetable-wrapper .filter-departments {
  margin-bottom: 20px;
}
.fc-timetable-wrapper .filter-departments li {
  margin-bottom: 20px;
}
.fc-timetable-wrapper .filter-departments a {
  background: #eeeeee;
  border-color: #eeeeee;
  border-radius: 5px;
  color: #333333;
  padding: 5px;
  padding: 7px 15px;
}
.fc-timetable-wrapper .filter-departments .active {
  background: #888888;
  border-color: #888888;
  color: #ffffff;
}
.fc-timetable-wrapper .filter-departments .fc-departments-orthopaedics {
  background: #72a230;
  border-color: #72a230;
}
.fc-timetable-wrapper .filter-departments .fc-departments-cardiology {
  background: #ffab00;
  border-color: #ffab00;
}
.fc-timetable-wrapper .filter-departments .fc-departments-neurology {
  background: #fd4084;
  border-color: #fd4084;
}
.fc-timetable-wrapper .filter-departments .fc-departments-dental {
  background: #00a4ef;
  border-color: #00a4ef;
}
.fc-timetable-wrapper .filter-departments .fc-departments-haematology {
  background: #80acdd;
  border-color: #80acdd;
}
.fc-timetable-wrapper .fc-event {
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  border-radius: 0;
  border: 0;
  border: 2px solid #5fc7ae;
  color: #ffffff;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 300;
  transition: all 0.3s ease;
}
.fc-timetable-wrapper .fc-event .fc-bg {
  opacity: 0;
}
.fc-timetable-wrapper .fc-event .fc-content {
  padding-left: 10px;
  padding-top: 10px;
}
.fc-timetable-wrapper .fc-event .fc-content div.fc-time {
  display: none;
}
.fc-timetable-wrapper .fc-event.hide {
  opacity: 0;
  z-index: -2;
}
.fc-timetable-wrapper .fc-event.fc-departments-orthopaedics {
  background: #72a230;
  border-color: #72a230;
}
.fc-timetable-wrapper .fc-event.fc-departments-cardiology {
  background: #ffab00;
  border-color: #ffab00;
}
.fc-timetable-wrapper .fc-event.fc-departments-neurology {
  background: #fd4084;
  border-color: #fd4084;
}
.fc-timetable-wrapper .fc-event.fc-departments-dental {
  background: #00a4ef;
  border-color: #00a4ef;
}
.fc-timetable-wrapper .fc-event.fc-departments-haematology {
  background: #80acdd;
  border-color: #80acdd;
}
.fc-timetable-wrapper td.fc-time {
  color: #7f7f7f;
  font-family: "Lato", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 70px;
  text-align: center;
}
.fc-timetable-wrapper .fc-time-grid-container {
  height: auto;
}
.fc-timetable-wrapper .fc-content-skeleton .fc-event-container {
  margin: 0;
}
@media only screen and (max-width: 768px) {
  .fc-timetable-wrapper .fc-day-header {
    font-size: 10px;
  }
  .fc-timetable-wrapper .fc-time-grid-event .fc-time,
  .fc-timetable-wrapper .fc-time-grid-event .fc-title {
    font-size: 12px;
  }
  .fc-timetable-wrapper .fc-event .fc-content {
    padding-left: 0;
    padding-top: 0;
  }
}
/*
 * Widgets.less
 * -----------------------------------------------
*/
/* -------- Top Search Bar ---------- */
.search-form-wrapper {
  position: relative;
  /* form absolute */
}
.search-form-wrapper.toggle {
  display: none;
}
.search-form-wrapper.toggle.active {
  display: block;
}
.search-form-wrapper form {
  padding: 10px;
  position: relative;
}
.search-form-wrapper form label {
  margin: 0px;
  position: absolute;
  right: 11px;
  top: 11px;
}
.search-form-wrapper form label:before {
  color: #555555;
  cursor: pointer;
  content: "\f002";
  font-family: fontawesome;
  font-size: 16px;
  position: absolute;
  right: 13px;
  top: 6px;
}
.search-form-wrapper form input[type="text"] {
  background-color: #fff;
  border: 1px solid #c0c0c0;
  height: 46px;
  padding: 8px 17px;
  width: 100%;
}
.search-form-wrapper form input[type="submit"] {
  background-color: #fff;
  border-left: 1px solid #c0c0c0;
  border: none;
  height: 44px;
  width: 50px;
  text-indent: -999px;
}
.search-form-wrapper.form-absolute form {
  background-color: #333333;
  position: absolute;
  right: 0px;
  top: 12px;
  width: 370px;
  z-index: 99;
}
/* -------- Form Round ---------- */
.search-form-wrapper.round form input[type="text"] {
  border-radius: 30px;
  border-width: 4px;
}
.search-form-wrapper.round form input[type="submit"] {
  height: auto;
  width: auto;
  margin-right: 10px;
  margin-top: 10px;
}
.search-form-wrapper.round form label::before {
  color: #c0c0c0;
}
/* -------- Footer & Sidebar Widgets ---------- */
.widget {
  margin-bottom: 40px;
}
.widget .widget-title {
  margin-top: 0;
  margin-bottom: 20px;
}
.widget ul li {
  margin-bottom: 5px;
  padding-bottom: 5px;
}
.widget ul.list li,
.widget .post {
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.widget .list-border li {
  margin-bottom: 0;
  padding: 10px 0;
}
.widget.brochures > li {
  margin-bottom: 10px;
  padding: 10px;
}
.widget.brochures i {
  color: #666666;
  font-size: 16px;
  margin-right: 10px;
}
.widget.address > li {
  font-size: 13px;
  margin-bottom: 10px;
  padding: 5px 10px;
}
.widget.address > li i {
  font-size: 16px;
  margin-right: 8px;
  vertical-align: middle;
}
.widget .twitter-feed li {
  font-size: 13px;
  margin-bottom: 15px;
  margin-top: 0px;
  padding-left: 30px;
}
.widget .twitter-feed li.item {
  position: relative;
}
.widget .twitter-feed li::after {
  content: "\f099";
  font-size: 24px;
  font-family: fontawesome;
  left: 0;
  position: absolute;
  top: 0;
}
.widget .styled-icons li {
  margin-bottom: 0;
  padding-bottom: 0;
}
.widget .styled-icons li a {
  margin-bottom: 0;
}
.widget .tags a {
  border: 1px solid #d3d3d3;
  display: inline-block;
  font-size: 11px;
  margin: 5px 4px 5px -2px;
  padding: 5px 10px;
}
.widget .tags a:hover {
  color: #fff;
}
.widget .search-input {
  background: transparent none repeat scroll 0 0;
  border: 1px solid #c0c0c0;
  border-radius: initial;
  box-shadow: none;
  height: 45px;
  padding: 10px;
}
.widget .search-button {
  background: transparent none repeat scroll 0 0;
  border: 1px solid #c0c0c0;
  border-radius: 0;
  height: 45px;
}
.widget .nav-tabs li {
  border-bottom: 1px solid transparent;
  margin-bottom: -2px;
  padding-bottom: 0;
}
.widget .nav-tabs li:hover {
  border-bottom: 1px solid transparent;
}
.widget .post-title a {
  font-size: 13px;
  letter-spacing: 0;
}
.widget .quick-contact-form .form-control {
  background-color: rgba(241, 241, 241, 0.8);
  border-color: #d3d3d3;
  color: #a9a9a9;
}
.widget .product-list .product-title {
  font-size: 14px;
  font-weight: 400;
}
.widget .testimonial-widget-carousel .item img {
  display: inline;
  width: auto;
  float: right;
}
.widget.dark .widget-title {
  color: #fff;
}
.widget.dark .tags a {
  border: 1px solid #777777;
}
.widget.dark .widget-image-carousel .title {
  color: #fff;
}
.widget.dark .search-input {
  border: 1px solid #333333;
}
.widget.dark .styled-icons li a:hover i {
  color: #fff;
}
.widget.dark .search-button {
  border: 1px solid #333333;
}
.widget.dark .widget-subscribe .subscribe-title {
  color: #fff;
}
.widget.dark .widget-subscribe .subscribe-sub-title {
  color: #666666;
}
.widget.dark .nav-tabs li {
  border-bottom: 1px solid transparent;
  margin-bottom: -2px;
  padding-bottom: 0;
}
.widget.dark .nav-tabs li:hover {
  border-bottom: 1px solid transparent;
}
.widget.dark .nav-tabs li:hover a {
  border: 1px solid #555555;
}
.widget.dark .nav-tabs li.active a {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background: #333333 none repeat scroll 0 0;
  border-color: #555555 #555555 transparent;
  border-image: none;
  border-style: solid;
  border-width: 1px;
}
.widget.dark .product-title a {
  color: #808080;
}
.widget.dark .post-title a {
  color: #c0c0c0;
}
.widget.dark .tab-content {
  background: #333333 none repeat scroll 0 0;
  border: 1px solid #555555;
}
.widget.dark .quick-contact-form .form-control {
  background-color: rgba(51, 51, 51, 0.3);
  border-color: #333333;
}
.widget.no-border ul li {
  border: none;
  padding-bottom: 0;
  margin-bottom: 0;
}
.widget ul.list li::before {
  display: none;
}
.widget.dark ul.list li::before {
  display: none;
}
/* --------Horizontal Contact Widget  ---------- */
.horizontal-contact-widget {
  border-top: 1px dashed #d3d3d3;
}
.horizontal-contact-widget .each-widget::before {
  content: "\f111";
  font-family: FontAwesome;
  left: 0;
  position: absolute;
  right: 0;
  top: -42px;
}
/* -------- Horizontal Contact Widget Dark ---------- */
.horizontal-contact-widget.dark {
  border-top: 1px dashed #333333;
}
.widget.dark .btn.btn-default {
  border-color: #333;
}
/* -------- Flickr-Feed ---------- */
#flickr-feed img {
  background: none repeat scroll 0 0 #000;
  float: left;
  height: 60px;
  margin: 0 5px 5px 0;
  padding: 0;
  width: 70px;
}
.text-hover-theme-colored {
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
/*
 * Footer.less
 * -----------------------------------------------
*/
.footer a {
  color: #808080;
}
.footer a:focus,
.footer a:hover,
.footer a:active {
  color: #666666;
}
.footer .widget .widget-title {
  margin-top: 0px;
}
.footer .widget.dark .quick-contact-form button {
  border-color: #333333;
  color: #909090;
}
.footer .widget.dark .quick-contact-form button:hover {
  background-color: #eeeeee;
  border-color: #eeeeee;
  color: #333333;
}
/* -------- Custom Theme Style ---------- */
.footer-box {
  padding: 20px 15px;
}
.footer-box.icon-box {
  margin-bottom: 0;
}
.footer-box .title {
  color: #fff;
  margin-bottom: 5px;
}
.footer-box p {
  color: #eeeeee;
  line-height: 20px;
}
.footer-box p i {
  color: #fff;
  margin-left: 10px;
}
.footer-box-wrapper {
  margin-top: -55px;
}
.footer-news-letter .newsletter-form .form-control {
  background: #333333;
  border: medium none;
  height: 42px;
  margin-right: 10px;
}

/*# sourceMappingURL=style-main.css.map */
