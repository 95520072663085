@keyframes background-fade {
  0% {
    opacity: 1;
  }

  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

#guardadoC {
  padding: 5px;
  opacity: 0;
}

#guardadoC.animating {
  animation: background-fade 5s;
}

.home-background {
  background-image: url("../../assets//images/pattern/pt19.jpg");
}
.shop-popup-bn {
  color: #fff;
  &:hover {
    color: #fff;
  }
}
//reiview

@media (max-width: 372px) {
  .responsive-review-container {
    display: flex !important;
    flex-direction: column !important;
    margin-left: 0;
  }
  .rating-btn {
    text-align: center;
  }
  .pro-btn {
    width: 50%;
  }
}

@media (max-width: 336px) {
  .responsive-why-choose {
    font-size: 25px;
  }
}
// Line stages

.line-stage {
  padding: 0 0.5em;
  height: 2em;
  text-align: center;
  line-height: 2em;
  border-radius: 0.2em;
  background: #fff;

  color: #333;
  margin: 0 3em;
  display: inline-block;
  position: relative;
  font-weight: 500;
}

.line-stage::before {
  content: "";
  position: absolute;
  top: 0.9em;
  left: -10em;
  width: 10em;
  height: 0.2em;
  background: #fff;
  color: #ff670e;
  z-index: -1;
}

.line-stage:first-child::before {
  display: none;
}

.line-stage-active {
  background: #fff;
  color: #ff670e;
  padding: 0 0.3em;
}

.line-stage-active ~ li::before {
  background: #0db1ca;
  margin: 0 1em;
  color: #000;
}

@media (max-width: 663px) {
  .line-stage {
    display: none;
  }
  .progress-bars {
    margin-top: 0px !important;
  }
}

@media (max-width: 410px) {
  .line-stage {
    display: none;
  }
  .progress-bars {
    margin-top: 0px !important;
  }
}

//common

// container-height

.edit-file {
  position: relative;
  input[type="file"] {
    color: rgba(0, 0, 0, 0);
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.common-container {
  // margin: 20px 30px;
  margin: 40px 60px;
}
@media only screen and (max-width: 576px) {
  .common-container {
    margin: 10px;
  }
}

.checkout-container {
  margin: 20px 0 20px 80px;
}

.container-height {
  min-height: 100%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  opacity: 1;
  -moz-appearance: textfield;
}

.nounderline {
  text-decoration: none !important;
}

.mandatory {
  color: red;
  padding-left: 3px;
}

.display-none {
  display: none !important;
}

.display-block {
  display: block !important;
}

.font-normal {
  font-weight: normal;
}

.light-green-font {
  color: #7caa47;
}

.light-green-background {
  background-color: #7caa47;
  color: #fff;
}

.disabled-success {
  background-color: #7a7a79;
  color: #fff;
}

.blue-font {
  color: #4bd5b0;
}

.blue-background {
  background-color: #4bd5b0;
  color: #fff;
}

.orange-background {
  background-color: #FC633B !important;
}

.orange-font {
  color: #ff670e;

  &:hover {
    color: #ff5e00;
    text-decoration: none;
  }
}

.center-container {
  display: flex;
  justify-content: center;
}

.line-border {
  border-bottom-color: rgb(179, 179, 179);
}

.home-service-icon {
  font-size: 48px !important;
  line-height: 90px !important;

  &:hover {
    transform: scale(1.5);
    color: #fff;
  }
}

.variant-active {
  color: #fff !important;
  background-color: #4bd5b0 !important;
  border-color: transparent;
}

.variant-inactive {
  color: #000;
  background-color: #f5f6f8;
  &:hover {
    background-color: #4bd5b0;
    color: #fff;
  }
}

.my-order-items-container {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  //   width: 50em;
}
.carer-login-form {
  padding-top: 149px;
}
pt-140 pb-30 pl-50 .card-address-cust {
  min-height: 235px !important;
}

.card-pet-cust {
  // min-height: 192px !important;
  background-color: #fafafa;
  // height: 200px;
}

.order-view-action-btn {
  font-size: 12.5px;
  background: #fff;
  padding: 3px 20px;
  border-color: #c0c0c0;
  font-weight: bold;
  text-transform: uppercase;
  &:hover {
    background-color: #fafafa;
  }
}

// Cart Icon

.fa-stack[data-count]:after {
  position: absolute;
  right: 0%;
  top: 0%;
  content: attr(data-count);
  font-size: 36%;
  padding: 0.6em;
  border-radius: 999px;
  line-height: 0.75em;
  color: #fff;
  text-align: center;
  min-width: 2em;
  // font-weight:bold;
  background: #f06204;
  margin-top: 5px;
  // border-style:solid;
}

.cart-empty-btn {
  padding: 7px 20px;
  font-size: 15px;
  color: #fff;
  background-color: #f60;
  border: 1px solid #f60 !important;
  text-decoration: none;
  &:hover {
    background-color: #f60;
    border: 1px solid #f60 !important;

    color: #fff;
  }
}

// Home Title Pic

.page-content {
  height: -webkit-fill-available;
  // height: 100vh;
  margin: 0;
  position: relative;
  overflow-y: auto;
  padding-left: 25px;
  padding-right: 25px;
  width: 80%;
}

// Pagination

// Pagination

.page-item.active .page-link {
  background-color: #FC633B!important;
  border-color: #d2d3d6;
  border-bottom-color: #dd5f0b;
  color: #fff;
  border-radius: 20px;
  border-color: transparent!important;
}

.page-link {
  color: #333;
  padding: 0.6rem 1rem;
  margin: 0 0.3rem;

  &:hover {
    color: #dd5f0b;
  }
}

.pagination > li > a, .pagination > li > span {
  color: #797979;
  background-color: transparent;
  border: none;
}

.page-item:first-child .page-link {
  color: #FC633B;
  font-size: 20px;
  border-right: 1px solid #707070;
  line-height: 15px;
}
// Pagiantion

.pagination_wrap {
  flex: 5;
  display: flex;
  justify-content: center;

  .pagination {
    margin: 0;
    padding: 1rem 2rem;
    border: 1px solid #707070;
    border-radius: 21px;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
}

.magnifier.fixed {
  height: 100%;
}

.magnifier.fixed > div {
  height: 100%;
  display: flex;
}

.magnifier.fixed > div > img {
  max-height: 100%;
  max-width: 100%;
  margin: auto;
  width: auto !important; /* should be removed from inline styles */
}

// Checkout

.seller-data {
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 62%;
  display: inline;
  color: #94969f;
}

.seller-data {
  margin-left: 0px;
}

.content-header {
  color: #fff;
  background-color: #4bd5b0;
  // padding: 0px;

  .number {
    background-color: #fff;
    position: relative;
    padding: 0px 4px;
    color: #4bd5b0;
    font-size: 16px;
  }
  .heading {
    color: #fff;
    // top: 4px;
    font-size: 16px;
  }
}

.approve-content-header {
  color: #878787;
  background-color: #fff;
  // padding: 0px;

  .approve-number {
    font-size: 12px;
    color: #2874f0;
    background-color: #f0f0f0;
    border-radius: 2px;
    padding: 3px 7px;
    vertical-align: baseline;
    margin-right: 17px;
  }
  .approve-heading {
    color: #878787;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 6px;
    text-transform: uppercase;
  }
}

@media (max-width: 405px) {
  .content-body .inner {
    padding: 26px 0px 18px 0px !important;
    position: relative;
  }
}

.custom-checkout-products {
  min-width: 477px;
}

@media (max-width: 700px) {
  .custom-checkout-products {
    min-width: auto;
  }
}

// clinic

.SearchWrapper {
  position: relative;
  .tableSearchField {
    width: 100%;
  }

  .searchIconWrapper {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translate(-50%, -50%);
    color: #e2e4e6;
  }
}

.search-btn {
  background-color: #f8f9fa;
  border-right: none;
  border-left: none;
  border-top-width: 1.8px;
  border-top-color: #cacfd4;
  border-bottom-color: #ffffff;
  border-bottom-width: 1px;
  cursor: pointer;
  margin-left: -26px;
  background-color: #ffffff;
}

.content-body {
  position: relative;

  .clinic-icon {
    font-size: 30px;
  }
  .clinic-inner {
    padding: 15px 30px;
    position: relative;
    border: 1px solid rgba(184, 184, 184, 0.125);
    border-radius: 5px;
    background-color: #f3f3f3;

    .clinic-doc-img {
      margin-top: 10px;
      // height: 200px;
      width: 180px;
    }
  }
  .clinic-modal-inner {
    padding: 15px 30px;
    position: relative;
    border-radius: 10px;

    .clinic-doc-img {
      height: 150px;
      width: auto;
    }
  }
  .inner {
    padding: 26px 15px 18px 15px;
    position: relative;
  }
  .inner-elements {
    margin-bottom: 12px;
  }
  .inner-key {
    color: #878787;
  }
  .inner-value {
    margin-left: 15px;
    font-weight: 500;
  }
  .checkout-btn {
    background: #fb641b;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    border: none;
    color: #fff;
  }
}

@media only screen and (max-width: 500px) {
  .clinic-doc-img {
    height: 100px;
    width: auto;
  }
}

.font-weight-500 {
  font-weight: 500;
}
.activeAddress {
  border: groove #0cb1ca;
}

.inactiveAddress {
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.address-type-span {
  text-transform: uppercase;
  font-size: 11px;
  color: #878787;
  vertical-align: middle;
  padding: 4px 7px;
  border-radius: 2px;
  background-color: #f0f0f0;
  font-weight: 500;
}

// Clinic

.clinic-btn {
  padding: 7px 35px;
  font-size: 13px;
  background: #36c9a2;
  color: #fff;
  margin-top: 30px;
  &:hover {
    background-color: #fff;
    color: #36c9a2;
  }
  &:focus {
    background: #36c9a2;
    color: #fff;
    border: none;
  }
}

.clinic-main-detail-container {
  margin-left: 20px;
}

@media (max-width: 360px) {
  .clinic-main-detail-container {
    margin-left: 0px;
  }
}

.clinic-icon {
  color: #ff670e;
  font-size: 20px !important;
}

.clinic-service-desc {
  line-height: 2rem;
  font-size: 16px;
}

// @media only screen and (max-width: 1700px) {
//   .clinic-title {
//     margin-top: 10px;
//   }
//   .clinic-btn {
//     margin-top: 100px;
//     margin-left: 80px;
//   }
// }

// @media only screen and (max-width: 1200px) {
//   .clinic-btn {
//     margin-top: 100px;
//     margin-left: 10px;
//   }
// }

// @media only screen and (max-width: 900px) {
//   .clinic-btn {
//     margin-top: 70px;
//     margin-left: 10px;
//   }
// }

@media only screen and (max-width: 590px) {
  .clinic-btn {
    margin-top: 10px;
    margin-left: -20px;
  }
}

// Shop Detail
.centralize-content {
  vertical-align: "middle" !important;
  text-align: "center" !important;
}

.product-name {
  font-size: 15px;
  font-weight: bold;
  margin-top: 20px;
  color: #707070;
  font-family: Montserrat;
}

.star-button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.on {
  color: #000;
}
.off {
  color: #ccc;
}

// Shop Detail Image

.img-wrapper {
  overflow: hidden;
}
.hover-zoom:hover {
  transform: scale(1.5);
}

// Shop Popup

.shop-popup {
  direction: ltr;
  width: 40%;
  left: 30%;
  right: 30%;
  position: fixed;
  bottom: 30px;
  background-color: #4bd5b0;
  padding: 13px 10px 0px 10px;
  z-index: 30;
  opacity: 1;
  color: #ffffff;
  font-weight: 500;
  z-index: 99;
  text-align: center;

  p {
    width: 85%;
    margin-left: 5rem;
    // margin-bottom: 5px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
  }
}

// Shop Filter

.filter-label {
  display: block !important;
  width: 95%;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 17px;
  color: #282c3f;
  font-weight: normal;
  font-size: 13px;
  width: auto;
  text-align: left;
  input {
    margin: 0 8px 0 0;
  }

  input[type=checkbox]{
    accent-color: #ff3000;
    color: #fff;
  }
}

.filter-cation-btn-container {
  position: fixed;
  bottom: 0;
  left: 0;
  text-align: center;
  align-items: center;
  // padding:0px 30px;
  margin: 0 50px;
  width: 80%;
}

@media only screen and (max-width: 600px) {
  .filter-cation-btn-container {
    position: fixed;
    bottom: 30px;
    left: 0;
    width: 100%;
    padding: 0px;
    margin: 0;
  }
}
@media only screen and (max-width: 1000px) {
  .filter-modal-hide {
    display: none;
  }
  .filter-inner-modal-btn {
    // display: inline;
    width: 90%;
    display: inline-flex;
    margin: 0 0 40px 0 !important;
    padding: 0;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
  }
}

@media only screen and (min-width: 1000px) {
  .filter-inner-modal-btn {
    width: 25%;
    display: inline-flex;
    margin: 0!important;
    padding: 0;
    align-items: center;
    justify-content: space-between;
    padding-right: 5rem;
  }
}

// @media (min-width: 1000px) {
//   .modal-backdrop {
//       display: none !important;
//   }
//   #myModalDiv {
//       display: none !important;
//   }
//   .modal-dialog {
//       display: none !important;
//   }
// }

@media only screen and (max-width: 1000px) {
  .filter-show {
    display: none;
  }
  .responsive-filter {
    width: 100%;
    height: 100%;
  }
  .search-filter-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}

////////////

.shop-popup-btn {
  margin-top: 0 !important;
  padding-bottom: 10px;
  opacity: 1;
  font-size: 14px;
  // height: 40px;
  font-weight: bold;
  background-color: transparent;
  text-align: center;
  color: #fff;
  &:hover {
    cursor: pointer;
    color: #f3f3f3;
  }
}

.hideMe {
  -webkit-animation: cssAnimation 5s forwards;
  animation: cssAnimation 5s forwards;
}
@keyframes cssAnimation {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes cssAnimation {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media only screen and (max-width: 500px) {
  .shop-popup {
    width: 100%;
    left: 0%;
  }
}

.reg_login_headerWrapper {
  padding: 0;
  display: flex;
  .reg_login_detailsRegDet,
  .reg_login_detailsFilter {
    display: flex;
    align-items: center;

    label {
      margin: 0;
      padding-right: 5px;
    }
  }

  .reg_login_detailsFilter {
    justify-content: flex-end;
  }
}

.reg_login_headerWrapper {
  padding: 0;
  display: flex;
  .reg_login_detailsRegDet,
  .reg_login_detailsFilter {
    display: flex;
    align-items: center;

    label {
      margin: 0;
      padding-right: 5px;
    }
  }

  .reg_login_detailsFilter {
    justify-content: flex-end;
  }
}

.reg_login_headerWrapper {
  padding: 0;
  display: flex;
  .reg_login_detailsRegDet,
  .reg_login_detailsFilter {
    display: flex;
    align-items: center;

    label {
      margin: 0;
      padding-right: 5px;
    }
  }

  .reg_login_detailsFilter {
    justify-content: flex-end;
  }
}

@media only screen and (max-width: 500px) {
  .reg_login_headerWrapper {
    display: block;
  }

  .reg_login_detailsFilter {
    margin-top: 10px;
  }

  .userDetailsWrapper > div {
    padding-bottom: 1rem;
  }

  .reg_login_headerWrapper {
    .reg_login_detailsRegDet,
    .reg_login_detailsFilter {
      display: block !important;
    }

    .reg_login_detailsFilter select {
      width: 100%;
    }
  }

  .userDetailStarStatus {
    .starBox {
      width: 70px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .reg_login_headerWrapper {
    display: block;
  }

  .reg_login_detailsFilter {
    margin-top: 10px;
  }

  .userDetailsWrapper > div {
    padding-bottom: 1rem;
  }

  .reg_login_headerWrapper {
    .reg_login_detailsRegDet,
    .reg_login_detailsFilter {
      display: block !important;
    }

    .reg_login_detailsFilter select {
      width: 100%;
    }
  }

  .userDetailStarStatus {
    .starBox {
      width: 70px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .reg_login_headerWrapper {
    display: block;
  }

  .reg_login_detailsFilter {
    margin-top: 10px;
  }

  .userDetailsWrapper > div {
    padding-bottom: 1rem;
  }

  .reg_login_headerWrapper {
    .reg_login_detailsRegDet,
    .reg_login_detailsFilter {
      display: block !important;
    }

    .reg_login_detailsFilter select {
      width: 100%;
    }
  }

  .userDetailStarStatus {
    .starBox {
      width: 70px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .reg_login_headerWrapper {
    display: block;
  }

  .reg_login_detailsFilter {
    margin-top: 10px;
  }

  .userDetailsWrapper > div {
    padding-bottom: 1rem;
  }

  .reg_login_headerWrapper {
    .reg_login_detailsRegDet,
    .reg_login_detailsFilter {
      display: block !important;
    }

    .reg_login_detailsFilter select {
      width: 100%;
    }
  }

  .userDetailStarStatus {
    .starBox {
      width: 70px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .reg_login_headerWrapper {
    display: block;
  }

  .reg_login_detailsFilter {
    margin-top: 10px;
  }

  .userDetailsWrapper > div {
    padding-bottom: 1rem;
  }

  .reg_login_headerWrapper {
    .reg_login_detailsRegDet,
    .reg_login_detailsFilter {
      display: block !important;
    }

    .reg_login_detailsFilter select {
      width: 100%;
    }
  }

  .userDetailStarStatus {
    .starBox {
      width: 70px;
    }
  }
}

#reserveUNList {
  .rdt_Table {
    .rdt_TableBody {
      > .rdt_TableRow {
        .rdt_TableCell:nth-child(2) {
          div:first-child {
            white-space: initial;
          }
        }
      }
    }
  }
}

//Table

.snoTable {
  .rdt_TableBody {
    .rdt_TableRow {
      > div:nth-child(1) {
        text-align: center;
        > div {
          width: 100%;
        }
      }
    }
  }
}

.cust-table-Header {
  background: #fff;
  padding-right: 0px;
  display: flex;

  .cust-table-leftSection {
    flex: 1;
  }

  .cust-table-rightSection {
    flex: 1;
    display: flex;
    justify-content: flex-end;

    button {
      margin-left: 10px;
    }
  }
}

.cust-table-actions-wrap {
  display: flex;
  justify-content: space-around;

  button {
    background: transparent;
    border: none;
    margin-left: 5px;

    &:hover {
      cursor: pointer;
      background: #f5f6f8;
    }
  }
}

.cust-table-avatar-wrap {
  position: relative;

  .cust-table-avatar-premium {
    position: absolute;
    width: 20px;
    height: 20px;
    top: -10px;
    left: 10px;
  }
}

.cust-page {
  margin-left: 280px;
}
@media only screen and (max-width: 890px) {
  .cust-page {
    margin-left: 0px !important;
  }
}

.reserved_UN_delete {
  margin: 0px 5px;
  background: transparent;
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
}

.reservedUN_phoneField input {
  border: 1px solid #ced4da;
  padding: 0.47rem 0.75rem;
  color: #495057;
  border-radius: 0.25rem;
  outline: none;
}

.addUsernameFieldWrap {
  > div {
    margin: 0.5rem 0rem;
  }
}

#reserveUNList {
  .rdt_Table {
    .rdt_TableBody {
      > .rdt_TableRow {
        .rdt_TableCell:nth-child(2) {
          div:first-child {
            white-space: initial;
          }
        }
      }
    }
  }
}

.checkAvailabilityWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  > div {
    flex: 1;
  }

  button {
    padding: 7px 20px;
  }

  .errorAlerts {
    position: absolute;
  }
}

@media only screen and (max-width: 500px) {
  .checkAvailabilityWrap {
    display: block;

    button {
      margin-left: 0px;
      margin-top: 10px;
      width: 100%;
    }
  }
}

//common button

.cust-btn {
  background-color: #ff670e;
  color: #fff;
  font-weight: 700;
}

.cust-save-btn {
  background-color: #ff670e;
  color: #fff;
  font-weight: 700;
  padding: 0.75rem 3rem;
}

.cust-label {
  color: #495057;
  font-size: 1.2rem;
  margin-top: 1rem;
}

// Loader
.loaderWrapper {
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1040;

  .spinnerBox {
    text-align: center;
    color: #fff;
  }
}

.cust-avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

//personal details

.emp-profile {
  padding: 3%;
  margin-top: 3%;
  margin-bottom: 3%;
  border-radius: 0.5rem;
  background: #fff;
}
.profile-img {
  text-align: center;
  img {
    width: 70%;
    height: 100%;
  }
  .file {
    position: relative;
    overflow: hidden;
    margin-top: -20%;
    width: 70%;
    border: none;
    border-radius: 0;
    font-size: 15px;
    background: #212529b8;
    input {
      position: absolute;
      opacity: 0;
      right: 0;
      top: 0;
    }
  }
}
.profile-head {
  h5 {
    color: #333;
  }
  h6 {
    color: #0062cc;
  }
  .nav-tabs {
    margin-bottom: 5%;
    .nav-link {
      font-weight: 600;
      border: none;
    }
    .nav-link.active {
      border: none;
      border-bottom: 2px solid #0062cc;
    }
  }
}
.profile-edit-btn {
  border: none;
  border-radius: 1.5rem;
  width: 70%;
  padding: 2%;
  font-weight: 600;
  color: #6c757d;
  cursor: pointer;
}
.proile-rating {
  font-size: 12px;
  color: #818182;
  margin-top: 5%;
  span {
    color: #495057;
    font-size: 15px;
    font-weight: 600;
  }
}

.profile-work {
  padding: 14%;
  margin-top: -15%;
  p {
    font-size: 12px;
    color: #818182;
    font-weight: 600;
    margin-top: 10%;
  }
  a {
    text-decoration: none;
    color: #495057;
    font-weight: 600;
    font-size: 14px;
  }
  ul {
    list-style: none;
  }
}
.profile-tab {
  label {
    font-weight: 600;
  }
  p {
    font-weight: 600;
    color: #0062cc;
  }
}
// 404

.custom-not-found {
  margin: 100px 0;
  padding: 100px 0;
}

.custom-page-layout {
  margin: 50px 0 50px 0;
  padding: 100px 0;
}

// Plus Minus Button

.icon-align {
  margin-top: 32px;
}

.section-enable-btn {
  border-color: #fff;
  border-style: none;
  background-color: #fff;
}

//circle

.circle {
  border: 1px solid #000;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  position: relative;
  margin: 4px;
  display: inline-block;
  vertical-align: middle;
}
.circle.plus:before,
.circle.plus:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: green;
}
.circle.plus:before {
  width: 2px;
  margin: 2px auto;
}
.circle.plus:after {
  margin: auto 2px;
  height: 2px;
}

/* and a bonus!!! minus :-) */
.circle.minus:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: red;
  margin: auto 2px;
  height: 2px;
}

//Register

.cust-col-md-offset-1 {
  margin-left: 5%;
  margin-top: 3%;
}

// Clinics

.clinic-doc-responsive {
  display: flex;
}

@media (max-width: 600px) {
  .clinic-doc-responsive {
    display: flex;
    flex-direction: column;
  }
  .clinic-main-detail-container {
    margin-left: 0;
    padding-left: 0;
    text-align: center;
  }

  .clinic-btn-container {
    justify-content: center !important;
  }

  .clinic-doc-img {
    text-align: center;
    justify-content: center;
    margin: 0 auto;
  }

  .clinic-btn {
    padding: 7px 10px;
    margin: 5px 2px;
  }
}

@media (max-width: 360px) {
  .clinic-doc-responsive {
    display: flex;
    flex-direction: column;
  }
}

.clinic-btn-container {
  justify-content: right;
}

@media (min-width: 1702px) {
  .clinic-btn-container {
    display: flex;
  }
}

.clinic-speciality-text {
  text-align: right;
}

@media (max-width: 1200px) {
  .clinic-speciality-text {
    text-align: left !important;
  }
}

.clinic-title {
  color: #0e204d;
  font-size: 18px;
  margin-top: 30px;
}

.clinic-modal-title {
  color: #0e204d;
  font-size: 15px;
  margin-top: 50px;
}

.clinic-modal-sub {
  font-size: 13px;
}

// Clinic modal navs

.slot-booking-modal {
  border-radius: 30px;
}

.nav_active {
  background-color: #fff;
}

.clinic-slot {
  color: #4bd5b0 !important;
  font-size: 13.5px;
}

.active-slot {
  background-color: #4bd5b0;
  height: 2px;
}

.nav-tabs-custom .nav-item .nav-link::after {
  background-color: #1da1da;
  cursor: pointer;
}
.slots-btn {
  margin-right: 18px;
  margin-bottom: 10px;
  padding: 7px 15px;
  font-size: 13px;
  background: #fff;
  color: #4bd5b0;
  border: 1px solid #4bd5b0;

  &:hover:enabled {
    background-color: #56e6bf;
    color: #fff;
  }

  // &:hover:disabled {
  //   background-color: #fff;
  //   color: gray;
  // }
  // &:disabled {
  //   border: 1px solid gray;
  //   color: gray;
  // }
}

.slot-requested-btn {
  color: gray !important;
  border: 1px solid gray !important;

  background-color: gray !important;
  color: #fff !important;
}

.slot-blocked-btn {
  color: gray !important;
  border: 1px solid red !important;

  background-color: lightcoral !important;
  color: #fff !important;
}

.slot-unavailabe-btn {
  &:hover {
    background-color: #fff !important;
    color: gray !important;
  }
  border: 1px solid gray !important;
  color: gray !important;
}

.slot-submit-btn {
  padding: 7px 25px;
  font-size: 13px;
  background: #fff;
  color: #4bd5b0;
  border: 1px solid #4bd5b0;

  &:hover {
    background-color: #4bd5b0;
    border: 1px solid #4bd5b0 !important;

    color: #fff;
  }
}

.active-slot-time {
  background-color: #4bd5b0 !important;
  border: 1px solid #4bd5b0 !important;
  color: #fff !important;
}

// clinic detail page

.doc-detail-page-descp {
  font-weight: normal;
  line-height: 3rem;
  font-size: 16px;
}

.doc-detail-page-speciality {
  font-weight: normal;
  font-size: 16px;
  line-height: 2rem;
}

.doc-detail-page-overview {
  font-weight: 400;
  font-size: 15px;
  line-height: 3rem;
}

.clinic-detail-img {
  width: 15em;
  height: 15em;
}
.clinic-detail-btn-container {
  text-align: right;
}

.clinic-detail-btn {
  padding: 15px 60px;
  font-size: 16px;
  background: #fff;
  color: #4bd5b0;
  margin-top: 30px;
  transition: background-color 0.5s ease-in;
  justify-content: right;
  text-align: right;

  &:hover {
    background: #4bd5b0;
    color: #fff;
  }

  &:focus {
    /* color: #333; */
    color: #4bd5b0;
    background-color: #fff !important;
    border-color: #4bd5b0 !important;
  }
}

@media (max-width: 600px) {
  .clinic-detail-img {
    margin: 0 auto;
    width: 180px;
  }
  .doc-detail-page-title {
    font-size: 20px;
  }
  .doc-detail-page-descp {
    font-weight: normal;
    line-height: 3rem;
    font-size: 14px;
  }

  .doc-detail-page-speciality {
    font-weight: normal;
    font-size: 14px;
    line-height: 2rem;
  }

  .doc-detail-page-overview {
    font-weight: 400;
    font-size: 13px;
    line-height: 2rem;
  }
  .clinic-detail-btn {
    justify-content: center !important;
  }
  .clinic-detail-btn-container {
    text-align: center !important;
    margin-bottom: 50px;
  }
}

// Guest

.cust-btn {
  padding: 7px 20px;
  font-size: 15px;
  color: #fff;
  background-color: #36c9a2;
  border: 1px solid #36c9a2 !important;
  text-decoration: none;
  &:hover {
    background-color: #fff;
    border: 1px solid #36c9a2 !important;
    text-decoration: none;
    color: #36c9a2;
  }
}

.cust-payment-btn {
  padding: 7px 20px;
  font-size: 15px;
  color: #fff;
  background-color: #138496;
  border: 1px solid #138496 !important;
  text-decoration: none;
  &:hover {
    background-color: #fff;
    border: 1px solid #138496 !important;
    text-decoration: none;
    color: #138496;
  }
}

@media (min-width: 1200px) {
  .modal-xlg {
    width: 90% !important;
  }
}

@media (min-width: 500px) {
  .slots-btn {
    margin-right: 18px;
    margin-bottom: 10px;
    padding: 7px 5px;
    font-size: 13px;
    background: #fff;
    color: #4bd5b0;
    border: 1px solid #4bd5b0;

    &:hover {
      background-color: #4bd5b0;
      color: #fff;
    }
  }
}

// Customs

.custom-form-control {
  display: block;
  width: 100%;
  height: 40px;
  padding: 6px 12px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.custom-btn {
  width: auto;
  height: 40px;
  padding: 6px 12px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

// .cust-header {
//   z-index: 1000;
//   position: fixed;
//   top: 0px;
//   margin-left: 0px;
//   width: 100%;
//   left: 0px;
// }

.cust-seller-login-btn {
  border-style: none;
  margin-top: 21px !important;
  margin-left: 10px !important;
  padding: 5px 10px !important;
  border-radius: 5%;
  list-style: none;
  cursor: pointer;
  &:hover {
    background-color: #fa640e;
    color: #fff;
    text-decoration: none;
  }
}

@media (max-width: 991px) {
  .cust-seller-login-btn {
    border-style: none;
    margin-top: 0px !important;
    margin-left: 0px !important;
    padding: 1px 0px !important;
    border-radius: 5%;
    list-style: none;
    cursor: pointer;
    &:hover {
      background-color: #fa640e;
      color: #fff;
      text-decoration: none;
    }
  }
}

.custom-seller-login-page {
  margin: 369px 0;
}

.custom-seller-login-logo {
  padding-top: 120px;
}

@media only screen and (max-width: 976px) {
  .custom-seller-login-page {
    margin: 200px 0;
  }

  .custom-seller-login-logo {
    padding-top: 0px;
  }
  .custom-btn {
    width: auto;
    height: 40px;
    padding: 6px 12px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }
}

@media only screen and (max-width: 450px) {
  .custom-btn {
    width: auto;
    height: 40px;
    padding: 6px 12px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }
}

@media (max-width: 991px) {
  .reward-check {
    width: 10%;
    margin-right: 4px !important;
    margin-top: 3px !important;
  }
}

@media (min-width: 991px) {
  .reward-check {
    margin-right: 4px !important;
    margin-top: 3px !important;
  }
}

.cust-login-fieldWrapper {
  position: relative;

  .cust-user-eye {
    // background: #2b2e3b;
    width: 1.75rem;
    height: 1.5rem;
    position: absolute;
    bottom: 0.6rem;
    right: 7%;
    transform: translate(-35%, 0%);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem;
    transition: all 0.3s;
    cursor: pointer;
    color: #424242;
  }

  .cust-pwd-eye {
    // background: #2b2e3b;
    width: 1.75rem;
    height: 1.5rem;
    position: absolute;
    bottom: 1rem;
    right: 1%;
    transform: translate(-35%, 0%);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem;
    transition: all 0.3s;

    i {
      color: #9295a4;
      transition: all 0.3s;
    }

    &:hover {
      background: #9295a4;
      transition: all 0.3s;
      cursor: pointer;

      i {
        color: #c3cbe4;
        transition: all 0.3s;
      }
    }
  }
}

// invoice

.cardTitle {
  padding: 10px 15px;
  background: #bebebe;
  font-weight: bold;
}

//profile

.menu-ct[petwalker] {
  width: 100%;
  margin-top: 17px;
  box-shadow: 0 4px 5px #dddddda6;
}

.scrollmenu[petwalker] {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  background: #fff;
}

.scrollmenu[petwalker] a[petwalker] {
  display: inline-block;
  color: #000;
  text-align: center;
  padding: 0 25px;
  line-height: 50px;
  height: 50px;
  text-decoration: none;
  font-size: 18px;
  font-weight: 700;
  border-top: 2px solid #fff;
}

.active-sm[petwalker] {
  color: #f60 !important;
  border-bottom: 3px solid #f60 !important;
}

.active-donation-type {
  color: #333;
  background: #d4e5f3;
  border: 1px groove rgb(58, 57, 57) !important;
  box-shadow: none;
  border-radius: 3px;
}

.active-donation-amount {
  background: #d4e5f3 !important;
}

.margin-auto {
  max-width: 1000px;
  margin: 0 auto !important;
  float: none;
}

.donation {
  background: url("../images/group-portrait-adorable-puppies.jpg");
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  // background-size: contain;
  // width: 100%;
}

.fa-donate-tick {
  display: inline-block;
  background-color: #35cb43;
  color: #fff;
  font-size: 1.3rem;
  padding: 0.2rem;
  border-radius: 50%;
  vertical-align: middle;
  margin-left: 0.6rem;
}

.fa-donate-lock {
  font-size: 1.7rem;
  color: #35cb43;
  margin-right: 0.5rem;
  vertical-align: middle;
}

.donate-form-option,
.donate-payment-option {
  display: inline-block;
  text-align: center;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  cursor: pointer;
  border-radius: 0.5rem;
  vertical-align: middle;
  padding: 0.75rem 2rem;
  width: calc(50% - 4rem - 1rem - 5px);
  margin: 0.5rem;
  border: 1px solid #e8e6de;
  font-weight: normal;
}

.donate-form-amount,
.donate-payment-option span {
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  font-size: 1.8rem;
  // font-weight: 500;
  color: #333;
  font-weight: bold;
  text-transform: none;
}

.donate-btn-primary {
  margin: 1rem;
  right: 0;
  // width: calc(100% - 2rem);
  z-index: 10;
  display: block;
  text-align: center;
  padding: 1rem;
  background-color: #d4e5f3;
  box-shadow: 0 0 1px 1px #bcd7ec;
  color: #5a4a06;
  white-space: nowrap;
  // border-radius: 20px;
}

.donate-subtitle {
  color: #181616;
  font-weight: 300;
  font-size: 1.6rem;
}

// .home-stats-wrapper {
//   position: relative;
//   // text-align: center;
//   z-index: 5;
//   padding: 0;
//   text-align: left;
// }

// .title-primary {
//   font-size: 1.8rem;
//   color: #2f2f2f;
//   font-weight: 500;
//   line-height: 1.2;
// }

// .home-stats-list {
//   margin: 5rem 10% 0;
//   display: flex;
//   flex-wrap: wrap;
//   transform: scale(0.95);
//   justify-content: center;
//   transform-origin: left;
//   margin: 5rem 0 0;
// }

// .home-approach-icons-wrapper.animated .animate-card,
// .home-stats-main-wrapper.animated .home-stats-item {
//   animation-name: showCard;
//   animation-duration: 1s;
//   animation-delay: 0s;
//   animation-fill-mode: forwards;
// }

// .page-donate .home-stats-item {
//   width: calc(48% - 6rem - 2px);
//   text-align: center;
// }

// .donate-options-cards,
// .home-approach-icons-item,
// .home-approach-title,
// .home-how-we-do-wrapper,
// .home-stats-item,
// .home-stats-main-wrapper {
//   transition: all 0.8s;
//   -webkit-transition: all 0.8s;
//   // opacity: 0;
//   transform: translateY(1.5rem);
// }

.home-stats-item {
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  display: inline-block;
  background-color: transparent;
  background-color: #fff;
  // border: 1px solid #efefef;
  box-shadow: 0 0 0.7rem -0.1rem rgba(0, 0, 0, 0.09);
  border-radius: 0.5rem;
  vertical-align: top;
  width: calc(48% - 6rem - 2px);
  padding: 2rem;
  margin: 0 1rem 3.5rem;
  transform: translateY(0);
}

.donate-steps-frequency {
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  font-size: 1.6rem;
  cursor: pointer;
  float: left;
  width: calc(50% - 3rem);
  padding: 1rem 1.5rem 1rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  margin: 10px 10px;
}

.donate-steps-frequency.selected,
.donate-steps-frequency:hover {
  background-color: #d4e5f3;
}

.donate-select {
  padding: 0.5rem !important;
}

.whydonateus-container {
  border: 1px solid #d4e5f3;
  padding: 50px 20px;
  background-color: #d4e5f3;
  h3 {
    color: #ff5e00;
  }
}

.donation-type:checked + label {
  background-color: #fff !important;
  color: #333 !important;
  border-color: #fff !important;
}

@media (max-width: 1450px) {
  .donation {
    background: url("../images/group-portrait-adorable-puppies.jpg");
    background-position: right;
    background-repeat: no-repeat;
    // background-size: cover;
    background-size: contain;
    // width: 100%;
  }
}



.noselect {
  -webkit-touch-callout: none !important; /* iOS Safari */
  -webkit-user-select: none !important; /* Safari */
  -khtml-user-select: none !important; /* Konqueror HTML */
  -moz-user-select: none !important; /* Old versions of Firefox */
  -ms-user-select: none !important; /* Internet Explorer/Edge */
  user-select: none !important; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.pe-auto {
  pointer-events: auto !important;
}

.edit-file {
  input[type="file"] {
    color: rgba(0, 0, 0, 0);
  }
}

.dropdown-user {
  font-size: 12px;
  font-weight: bold;
  color: rgb(59, 59, 59) !important;
}

// Footer

.custom-footer-section {
  display: flex;
  padding: 19px 19px 0px;
}

@media (max-width: 816px) {
  .custom-footer-section {
    display: block;
  }
}

.donate-sub {
  font-size: 16px;
}

// .fixed-header {
//   display: block;
//   position: sticky;
//   top: 0px;
//   z-index: 100;
// }

.fixed-header {
  display: block;
}

.clinic-text {
  font-size: 15px !important;
}

// .filter-border {
//   border: 1px solid #eeeeee;
// }

@media (max-width: 991px) {
  .filter-label {
    display: flex !important;
    width: auto !important;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 10px;
    color: #282c3f;
    font-weight: normal;
    font-size: 13px;
    width: auto;
    text-align: left;

    input {
      // margin: 0 8px 0 0;
      margin-top: 0px !important;
      width: auto !important;
    }
  }
}

.shop-price-container {
  display: flex;
}

.custom-price {
  margin-right: 100px;
  margin-top: 10px;
}

@media (max-width: 438px) {
  .shop-price-container {
    display: flex;
    flex-direction: column;
  }
  .custom-price {
    margin-right: 10px;
    margin-top: 10px;
  }
  .fast-delivery {
    margin-left: 15px !important;
  }
  .custom-variant-container {
    margin-left: 15px !important;
    margin-top: 20px !important;
  }
  .custom-add-to-cart {
    margin-left: 15px !important;
    margin-top: 20px !important;
  }
}

.variant-list-container {
  margin-top: 0;
  max-height: 240px;
  z-index: 1000;
  overflow: hidden;
  overflow-y: scroll;
  border-radius: 10px;
  margin-right: 0px !important;
  min-width: 10vw;
  text-align: center;
  padding-left: 3px;
}

.variant-empty-container {
  margin-top: 0;
  max-height: 300px;
  z-index: 1000;
  border-radius: 10px;
  margin-right: 0px !important;
  position: absolute;
  top: 10px;
  left: -20px;
}

.variant-list {
  padding: 5px 3px;
  margin: 0 2px;
  list-style: none;
  background-color: rgb(250, 250, 250);

  &_link {
    color: #333;
    &:hover {
      color: #0e0e0e;
    }
  }
}

.variant-btn-container {
  margin-top: 40px !important;
}

@media only screen and (max-width: 700px) {
  .variant-btn-container {
    margin-top: 0px !important;
  }
}

.gradient {
  display: inline;
  border: solid rgb(190, 190, 190);
  padding: 10px;
}

.gradient-select {
  display: inline;
  border: solid rgb(233, 86, 17);
  padding: 10px;
}

.btn-orange {
  background-color: #e95611;
  color: #fff;
}

.superstar-suggestion-list-container {
  margin-top: 0;
  max-height: 300px;
  max-width: 500px;
  z-index: 1000;
  overflow: hidden;
  overflow-y: scroll;
  margin-right: 0px !important;
  position: absolute;
  top: 184px;
  left: 50px;
  text-align: center;
  border: 1px solid #ebe9e9;
}

.superstar-suggestion-list {
  padding: 5px 3px;
  margin: 0 2px;
  list-style: none;
  background-color: rgb(250, 250, 250);

  &_link {
    color: #333;
    &:hover {
      color: #0e0e0e;
    }
  }
}

.super-star-btn-container {
  margin-top: 40px !important;
}

@media only screen and (max-width: 700px) {
  .super-star-btn-container {
    margin-top: 0px !important;
  }
}

.product-img {
  height: 25rem;

  & img {
    border-radius: 50px 50px 10px 10px;
  }
}

.pro-detail-img {
  margin-left: 50px !important;
}

@media only screen and (max-width: 700px) {
  .pro-detail-img {
    margin-left: 20px !important;
  }
}

@media only screen and (max-width: 392px) {
  .price-custom {
    display: block !important;
    margin-left: 0px !important;
  }
}

// pincode

._1NQxOU .ibtBU6 {
  margin: 24px 12px;
  display: inline-block;
}
.ibtBU6 {
  border-bottom: 2px solid #ff670e !important;
  width: 226px !important;
}

._1NQxOU ._3t6eWY {
  margin-left: 0 !important;
}

.cfnctZ {
  padding: 0 0 4px;
  font-size: 14px;
  border: none;
  // border-bottom: 2px solid #ff670e;
  font-weight: 500;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  display: inline-block;
  line-height: 25px;
  outline: none;
  box-shadow: none;
  width: 170px;
}
.UgLoKg.jlIjY- {
  color: #878787;
  cursor: not-allowed;
}

.UgLoKg {
  font-weight: 500;
  cursor: pointer;
  font-size: 14px;
  color: #ff670e;
  position: relative;
  display: inline-block;
  padding-bottom: 3px;
  line-height: 25px;
  border: none;
  // border-bottom: 2px solid #ff670e;
  background: none;
}

/* Clinic Profile Details */
$bg: #ff670e;
$color-primary: #ff670e;

* {
  box-sizing: border-box;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.clinic_detail h1 {
  margin-top: 0;
  margin-bottom: 1em;
  font-size: 2.5em;
  max-width: 650px;
  line-height: 1.3;
  margin-left: 1rem !important;
}

.content-block {
  margin-bottom: 3em;
  width: 100%;
}

// Cart Page Accordian Tabs CSS
div.accordian {
  // width: 90%;
  // max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5rem;
  margin-bottom: 5rem;
  transition: all 1s ease-out;
}

details div.acc-content {
  border-left: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  // padding: 1.5em;
}

details div.acc-content > * + * {
  margin-top: 1.5em;
}

details + details {
  margin-top: 0.5rem;
}

summary {
  list-style: none;
}

summary::-webkit-details-marker {
  display: none;
}

summary {
  border: 1px solid #eeeeee;
  padding: 0.75em 1em;
  cursor: pointer;
  position: relative;
  padding-left: calc(1.75rem + 0.75rem + 0.75rem);
}

summary:before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0.75rem;
  content: "↓";
  width: 1.75rem;
  height: 1.75rem;
  background-color: #000;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

details[open] summary {
  background-color: #eee;
}

details[open] summary:before {
  content: "↑";
}

summary:hover {
  background-color: #eee;
}

div.accordian > a {
  color: inherit;
  font-weight: 600;
  text-decoration: none;
  box-shadow: 0 1px 0 0;
}

div.accordian > a:hover {
  box-shadow: 0 3px 0 0;
}

code {
  font-family: monospace;
  font-weight: 600;
}

// Cart Page Accordian Tabs CSS

#tabs {
  margin-bottom: 2.6em;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    height: 1px;
    width: 100%;
    left: 0;
    // background: #f1f1f1;
  }

  li {
    font-size: 1.05em;
    font-weight: 700;
    margin-right: 3em;
    padding-bottom: 0.6em;
    letter-spacing: 0.03em;
    display: inline-block;
    position: relative;
    cursor: pointer;
    &.active {
      border-bottom: 4px solid $color-primary;
      color: $color-primary;
    }
  }
}

p {
  line-height: 1.6;
}

.clinic_container {
  max-width: 1780px;
  margin: 20px 0 0 20px;
  padding: 0 2em;
  @extend %clearfix;
}

.stars {
  font-size: 1.6em;
}

.label {
  color: lighten(black, 60%);
  letter-spacing: 0.08em;
  font-size: 1rem;
  font-weight: bold;
  display: block;
  margin-bottom: 0.7rem;
  text-transform: uppercase;
}

span.stars {
  font-size: 1.4em;
}

.body,
.course-sidebar {
  min-height: 100vh;
}

.course-content {
  width: calc(100% - 320px);
  float: right;
  padding-right: 5em;
}

.courseSidebar {
  width: 230px;
  float: left;
  position: relative;
  &__featureImage {
    margin-bottom: 3em;
  }
}

.profile-image {
  position: relative;
  overflow: hidden;
  padding-bottom: 100%;
  border-radius: 100%;
  background: $bg;
  &__inner {
    position: Absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 20px;
    border-radius: 100%;
    overflow: hidden;
    &::after {
      position: absolute;
      top: -1px;
      right: -1px;
      bottom: -1px;
      left: -1px;
      background: rgba(0, 0, 0, 0.2);
      content: "";
    }
  }
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.panel {
  margin: 2em 0;
  background: white;
  padding: 4em 10em 4em 5.5em;
  position: relative;
  border-radius: 5px;
  box-shadow: 0 0 7px 0px rgba(0, 0, 0, 0.07), 0 0 55px rgba(0, 0, 0, 0.06);
}

.courseStats {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    margin-bottom: 2.8rem;
  }

  &__label {
    background: white;
    position: relative;
    margin-bottom: -4px;
    float: left;
    padding-right: 5px;
    margin-left: 0px !important;
  }

  &__divider {
    float: left;
    height: 1px;
    background: lighten(black, 90%);
    width: 100%;
    display: block;
    margin-bottom: 1.4rem;
  }

  &__data {
    display: block;
    font-weight: 700;
    font-size: 1.2rem;
    margin-left: 0px !important;
  }
}

#tab li:not(.active) {
  display: none;
}

.button {
  border-radius: 3px;
  text-decoration: none;
  cursor: pointer;
  $root: &;
  padding: 15px 20px;
  font-weight: 700;
  background: #ff670e;
  color: #fff;
  display: inline-block;
  margin: 0 0.8em 0.8em 0;
  &:hover {
    text-decoration: none;
    color: #ff670e;
    background: #fff;
  }
  &:first-child {
    margin-left: 0;
  }
  &#{$root}--booking {
    background: $color-primary;
    color: #fff;

    // color: darken($color-primary, 26%);
  }
}

.unavailable-container {
  margin-bottom: 337px;
}

.available-container {
  margin-bottom: 294px;
}

.slot-container {
  margin-bottom: 61px !important;
}

.review-container {
  margin-bottom: 222px !important;
}

@media (max-width: 991px) {
  .clinic_detail h1 {
    margin-top: 0;
    margin-bottom: 1em;
    font-size: 1.6em;
    max-width: 650px;
    line-height: 1.3;
  }
}

@media (max-width: 557px) {
  .clinic_detail h1 {
    margin-top: 0;
    margin-bottom: 1em;
    max-width: 650px;
    line-height: 1.3;
  }

  .clinic_container {
    max-width: 1780px;
    margin: 5px 0 0 5px;
    padding: 0 0.3em;
  }
}

@media (max-width: 495px) {
  .button {
    border-radius: 3px;
    text-decoration: none;
    cursor: pointer;
    $root: &;
    padding: 10px 15px;
    font-weight: 700;
    background: #ff670e;
    color: #fff;
    display: inline-block;
    margin: 0 0.8em 0.8em 0;
    &:hover {
      text-decoration: none;
      color: #ff670e;
      background: #fff;
    }
  }

  .clinic_detail h1 {
    margin-top: 0;
    margin-bottom: 1em;
    font-size: 1.6em;
    max-width: 650px;
    line-height: 1.3;
  }

  #tabs {
    margin-bottom: 2.6em;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      height: 1px;
      width: 100%;
      left: 0;
      // background: #f1f1f1;
    }

    li {
      font-size: 1.05em;
      font-weight: 700;
      margin-right: 0.7em;
      padding-bottom: 0.6em;
      letter-spacing: 0.03em;
      display: inline-block;
      position: relative;
      cursor: pointer;
      &.active {
        border-bottom: 4px solid $color-primary;
        color: $color-primary;
      }
    }
  }
}

.doc-disclaimer {
  margin-top: 12px;
  margin-left: 10px;
  cursor: pointer;
}

.active-indicator {
  border: 1px solid #4bd5b0;
  width: 15px;
  height: 14px;
}

.booked-indicator {
  border: 1px solid gray !important;
  background-color: gray !important;
  width: 15px;
  height: 14px;
}

.blocked-indicator {
  border: 1px solid red !important;
  background-color: lightcoral !important;
  width: 15px;
  height: 14px;
}

.requested-indicator {
  border: 1px solid gray;
  width: 15px;
  height: 14px;
}

.offer-zone {
  border: 1px solid #ffffff;
  width: 500px;
  margin-bottom: 20px;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}

@media (max-width: 505px) {
  .offer-zone {
    border: 1px solid #ffffff;
    width: 500px;
    margin-bottom: 20px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .exchange-offer {
    margin-left: 30px !important;
  }
}

.personal-content-header {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 540px) {
  .personal-content-header {
    display: flex;
    flex-direction: column;
  }
}

.search-filter-container {
  display: flex;
  justify-content: space-between;
  padding-top: 2rem;
}

@media (min-width: 735px) {
  .custom-refund-container {
    margin-bottom: 410px !important;
  }
}

.mytooltip > .tooltip-inner {
  font-size: 12px !important;
}

// for Image Gallery
.responsive-gallery {
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;

  -webkit-column-gap: 20px;
  -moz-column-gap: 20px;
  column-gap: 20px;
}
@media (max-width: 1200px) {
  .responsive-gallery {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;

    -webkit-column-gap: 20px;
    -moz-column-gap: 20px;
    column-gap: 20px;
  }
}
@media (max-width: 800px) {
  .responsive-gallery {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;

    -webkit-column-gap: 20px;
    -moz-column-gap: 20px;
    column-gap: 20px;
  }
}
@media (max-width: 600px) {
  .responsive-gallery {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
}
.responsive-gallery img,
.responsive-gallery video {
  width: 100%;
  height: auto;
  margin: 4% auto;
  box-shadow: -3px 5px 15px #000;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
