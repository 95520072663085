:root {
  --blue: #5e72e4;
  --indigo: #5603ad;
  --purple: #8965e0;
  --pink: #f3a4b5;
  --red: #f5365c;
  --orange: #fb6340;
  --yellow: #ffd600;
  --green: #2dce89;
  --teal: #11cdef;
  --cyan: #2bffc6;
  --white: #fff;
  --gray: #8898aa;
  --gray-dark: #32325d;
  --light: #ced4da;
  --lighter: #e9ecef;
  --primary: #5e72e4;
  --secondary: #fdb777;
  --success: #2dce89;
  --info: #11cdef;
  --warning: #fb6340;
  --danger: #f5365c;
  --light: #adb5bd;
  --dark: #212529;
  --default: #172b4d;
  --white: #fff;
  --neutral: #fff;
  --darker: black;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: Open Sans, sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (min-width: 500px) {
  html,
  body,
  #root,
  .wrapper,
  .row,
  .col-md-6 {
    height: 100%;
  }
  .service-provider-login {
    padding-left: 15px !important;
  }
}
@media (max-width: 500px) {
  .service-provider-login {
    margin-top: 70px !important;
    padding-left: 15px !important;
  }
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
  width: device-width;
}

figcaption,
footer,
header,
main,
nav,
section {
  display: block;
}
/*   
  body {
    font-family: Open Sans, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
    text-align: left;
    color: #525f7f;
    background-color: #f8f9fe;
  } */

[tabindex="-1"]:focus {
  outline: 0 !important;
}

/* hr {
  overflow: visible;
  box-sizing: content-box;
  height: 0;
}

h1,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

ul ul {
  margin-bottom: 0;
}

dfn {
  font-style: italic;
}

strong {
  font-weight: bolder;
}

a {
  text-decoration: none;
  color: #5e72e4;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  text-decoration: none;
  color: #233dd2;
}

a:not([href]):not([tabindex]) {
  text-decoration: none;
  color: inherit;
}

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  text-decoration: none;
  color: inherit;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

code {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
  font-size: 1em;
}

img {
  vertical-align: middle;
  border-style: none;
}

caption {
  padding-top: 1rem;
  padding-bottom: 1rem;
  caption-side: bottom;
  text-align: left;
  color: #8898aa;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

legend {
  font-size: 1.5rem;
  line-height: inherit;
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5rem;
  padding: 0;
  white-space: normal;
  color: inherit;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

[hidden] {
  display: none !important;
}

h1,
h3,
h4,
h5,
h6,
.h1,
.h3,
.h4,
.h5,
.h6 {
  font-family: inherit;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 0.5rem;
  color: #32325d;
}

h1,
.h1 {
  font-size: 1.625rem;
}

h3,
.h3 {
  font-size: 1.0625rem;
}

h4,
.h4 {
  font-size: 0.9375rem;
}

h5,
.h5 {
  font-size: 0.8125rem;
}

h6,
.h6 {
  font-size: 0.625rem;
}

.display-2 {
  font-size: 2.75rem;
  font-weight: 600;
  line-height: 1.5;
}

hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

code {
  font-size: 87.5%;
  word-break: break-word;
  color: #f3a4b5;
}

a > code {
  color: inherit;
} */

.seller-container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 576px) {
  .seller-container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .seller-container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .seller-container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .seller-container {
    max-width: 1140px;
  }
}

.seller-container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
}

.seller-row {
  display: flex;
  margin-right: -15px;
  margin-left: -15px;
  flex-wrap: wrap;
}

.seller-col-4,
.seller-col-8,
.seller-col,
.seller-col-md-10,
.seller-col-md-12,
.seller-col-lg-3,
.seller-col-lg-4,
.seller-col-lg-6,
.seller-col-lg-7,
.seller-col-xl-4,
.seller-col-xl-6,
.seller-col-xl-8 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.seller-col {
  max-width: 100%;
  flex-basis: 0;
  flex-grow: 1;
}

.seller-col-4 {
  max-width: 33.33333%;
  flex: 0 0 33.33333%;
}

.seller-col-8 {
  max-width: 66.66667%;
  flex: 0 0 66.66667%;
}

@media (min-width: 768px) {
  .seller-col-md-10 {
    max-width: 83.33333%;
    flex: 0 0 83.33333%;
  }

  .seller-col-md-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

@media (min-width: 992px) {
  .seller-col-lg-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .seller-col-lg-4 {
    max-width: 33.33333%;
    flex: 0 0 33.33333%;
  }

  .seller-col-lg-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .seller-col-lg-7 {
    max-width: 58.33333%;
    flex: 0 0 58.33333%;
  }

  .order-lg-2 {
    order: 2;
  }
}

@media (min-width: 1200px) {
  .seller-col-xl-4 {
    max-width: 33.33333%;
    flex: 0 0 33.33333%;
  }

  .seller-col-xl-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .seller-col-xl-8 {
    max-width: 66.66667%;
    flex: 0 0 66.66667%;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }
}

.seller-form-control {
  font-size: 1.5rem;
  line-height: 1.5;
  display: block;
  width: 100%;
  height: calc(2.75rem + 10px);
  padding: 0.625rem 0.75rem;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  color: #525d68;
  border: 1px solid #cad1d7;
  border-radius: 0.375rem;
  background-color: #fff;
  background-clip: padding-box;
  box-shadow: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .seller-form-control {
    transition: none;
  }
}

.seller-form-control::-ms-expand {
  border: 0;
  background-color: transparent;
}

.seller-form-control:focus {
  color: #8898aa;
  border-color: rgba(50, 151, 211, 0.25);
  outline: 0;
  background-color: #fff;
  box-shadow: none, none;
}

.seller-form-control:-ms-input-placeholder {
  opacity: 1;
  color: #adb5bd;
}

.seller-form-control::-ms-input-placeholder {
  opacity: 1;
  color: #adb5bd;
}

.seller-form-control::placeholder {
  opacity: 1;
  color: #adb5bd;
}

.seller-form-control:disabled,
.seller-form-control[readonly] {
  opacity: 1;
  background-color: #e9ecef;
}

textarea.seller-form-control {
  height: auto;
}

.seller-form-group {
  margin-bottom: 1.5rem;
}

.seller-form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

@media (min-width: 576px) {
  .seller-form-inline label {
    display: flex;
    margin-bottom: 0;
    align-items: center;
    justify-content: center;
  }

  .seller-form-inline .seller-form-group {
    display: flex;
    margin-bottom: 0;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
  }

  .seller-form-inline .seller-form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  .seller-form-inline .seller-input-group {
    width: auto;
  }
}

.seller-btn {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  display: inline-block;
  padding: 0.625rem 1.25rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  border: 1px solid transparent;
  border-radius: 0.375rem;
}

@media screen and (prefers-reduced-motion: reduce) {
  .seller-btn {
    transition: none;
  }
}

.seller-btn:hover,
.seller-btn:focus {
  text-decoration: none;
}

.seller-btn:focus {
  outline: 0;
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.seller-btn:disabled {
  opacity: 0.65;
  box-shadow: none;
}

.seller-btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.seller-btn:not(:disabled):not(.disabled):active {
  box-shadow: none;
}

.seller-btn:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08),
    none;
}

.seller-btn-primary {
  color: #fff;
  border-color: #5e72e4;
  background-color: #5e72e4;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.seller-btn-primary:hover {
  color: #fff;
  border-color: #5e72e4;
  background-color: #5e72e4;
}

.seller-btn-primary:focus {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08),
    0 0 0 0 rgba(94, 114, 228, 0.5);
}

.seller-btn-primary:disabled {
  color: #fff;
  border-color: #5e72e4;
  background-color: #5e72e4;
}

.seller-btn-primary:not(:disabled):not(.disabled):active {
  color: #fff;
  border-color: #5e72e4;
  background-color: #324cdd;
}

.seller-btn-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none, 0 0 0 0 rgba(94, 114, 228, 0.5);
}

.seller-btn-info {
  color: #fff;
  border-color: #11cdef;
  background-color: #11cdef;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.seller-btn-info:hover {
  color: #fff;
  border-color: #11cdef;
  background-color: #11cdef;
}

.seller-btn-info:focus {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08),
    0 0 0 0 rgba(17, 205, 239, 0.5);
}

.seller-btn-info:disabled {
  color: #fff;
  border-color: #11cdef;
  background-color: #11cdef;
}

.seller-btn-info:not(:disabled):not(.disabled):active {
  color: #fff;
  border-color: #11cdef;
  background-color: #0da5c0;
}

.seller-btn-info:not(:disabled):not(.disabled):active:focus {
  box-shadow: none, 0 0 0 0 rgba(17, 205, 239, 0.5);
}

.seller-btn-default {
  color: #fff;
  border-color: #172b4d;
  background-color: #172b4d;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.seller-btn-default:hover {
  color: #fff;
  border-color: #172b4d;
  background-color: #172b4d;
}

.seller-btn-default:focus {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08),
    0 0 0 0 rgba(23, 43, 77, 0.5);
}

.seller-btn-default:disabled {
  color: #fff;
  border-color: #172b4d;
  background-color: #172b4d;
}

.seller-btn-default:not(:disabled):not(.disabled):active {
  color: #fff;
  border-color: #172b4d;
  background-color: #0b1526;
}

.seller-btn-default:not(:disabled):not(.disabled):active:focus {
  box-shadow: none, 0 0 0 0 rgba(23, 43, 77, 0.5);
}

.seller-btn-sm {
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 0.25rem 0.5rem;
  border-radius: 0.375rem;
}

.seller-dropdown {
  position: relative;
}

.seller-dropdown-menu {
  font-size: 1rem;
  position: absolute;
  z-index: 1000;
  top: 100%;
  left: 0;
  display: none;
  float: left;
  min-width: 10rem;
  margin: 0.125rem 0 0;
  padding: 0.5rem 0;
  list-style: none;
  text-align: left;
  color: #525f7f;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0.4375rem;
  background-color: #fff;
  background-clip: padding-box;
  box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1),
    0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);
}

.seller-dropdown-menu.show {
  display: block;
  opacity: 1;
}

.seller-dropdown-menu-right {
  right: 0;
  left: auto;
}

.seller-dropdown-menu[x-placement^="top"],
.seller-dropdown-menu[x-placement^="right"],
.seller-dropdown-menu[x-placement^="bottom"],
.seller-dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.seller-dropdown-divider {
  overflow: hidden;
  height: 0;
  margin: 0.5rem 0;
  border-top: 1px solid #e9ecef;
}

.seller-dropdown-item {
  font-weight: 400;
  display: block;
  clear: both;
  width: 100%;
  padding: 0.25rem 1.5rem;
  text-align: inherit;
  white-space: nowrap;
  color: #212529;
  border: 0;
  background-color: transparent;
}

.seller-dropdown-item:hover,
.seller-dropdown-item:focus {
  text-decoration: none;
  color: #16181b;
  background-color: #f6f9fc;
}

.seller-dropdown-item:active {
  text-decoration: none;
  color: #fff;
  background-color: #5e72e4;
}

.seller-dropdown-item:disabled {
  color: #8898aa;
  background-color: transparent;
}

.seller-dropdown-header {
  font-size: 0.875rem;
  display: block;
  margin-bottom: 0;
  padding: 0.5rem 1.5rem;
  white-space: nowrap;
  color: #8898aa;
}

.seller-input-group {
  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
}

.seller-input-group > .seller-form-control {
  position: relative;
  width: 1%;
  margin-bottom: 0;
  flex: 1 1 auto;
}

.seller-input-group > .seller-form-control + .seller-form-control {
  margin-left: -1px;
}

.seller-input-group > .seller-form-control:focus {
  z-index: 3;
}

.seller-input-group > .seller-form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.seller-input-group > .seller-form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.seller-input-group-prepend {
  display: flex;
}

.seller-input-group-prepend .seller-btn {
  position: relative;
  z-index: 2;
}

.seller-input-group-prepend .seller-btn + .seller-btn,
.seller-input-group-prepend .seller-btn + .seller-input-group-text,
.seller-input-group-prepend .seller-input-group-text + .seller-input-group-text,
.seller-input-group-prepend .seller-input-group-text + .seller-btn {
  margin-left: -1px;
}

.seller-input-group-prepend {
  margin-right: -1px;
}

.seller-input-group-text {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
  margin-bottom: 0;
  padding: 0.625rem 0.75rem;
  text-align: center;
  white-space: nowrap;
  color: #adb5bd;
  border: 1px solid #cad1d7;
  border-radius: 0.375rem;
  background-color: #fff;
  align-items: center;
}

.seller-input-group-text input[type="radio"],
.seller-input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.seller-input-group > .seller-input-group-prepend > .seller-btn,
.seller-input-group > .seller-input-group-prepend > .seller-input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.seller-input-group
  > .seller-input-group-prepend:not(:first-child)
  > .seller-btn,
.seller-input-group
  > .seller-input-group-prepend:not(:first-child)
  > .seller-input-group-text,
.seller-input-group
  > .seller-input-group-prepend:first-child
  > .seller-btn:not(:first-child),
.seller-input-group
  > .seller-input-group-prepend:first-child
  > .seller-input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.seller-nav {
  display: flex;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  flex-wrap: wrap;
}

.seller-nav-link {
  display: block;
  padding: 0.25rem 0.75rem;
}

.seller-nav-link:hover,
.seller-nav-link:focus {
  text-decoration: none;
}

.seller-navbar {
  position: relative;
  display: flex;
  padding: 1rem 1rem;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.seller-navbar > .seller-container,
.seller-navbar > .seller-container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.seller-navbar-nav {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.seller-navbar-nav .seller-nav-link {
  padding-right: 0;
  padding-left: 0;
}

.seller-navbar-nav .seller-dropdown-menu {
  position: static;
  float: none;
}

@media (max-width: 767.98px) {
  .seller-navbar-expand-md > .seller-container,
  .seller-navbar-expand-md > .seller-container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .seller-navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .seller-navbar-expand-md .seller-navbar-nav {
    flex-direction: row;
  }

  .seller-navbar-expand-md .seller-navbar-nav .seller-dropdown-menu {
    position: absolute;
  }

  .seller-navbar-expand-md .seller-navbar-nav .seller-nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .seller-navbar-expand-md > .seller-container,
  .seller-navbar-expand-md > .seller-container-fluid {
    flex-wrap: nowrap;
  }
}

.seller-navbar-dark .seller-navbar-nav .seller-nav-link {
  color: rgba(255, 255, 255, 0.95);
}

.seller-navbar-dark .seller-navbar-nav .seller-nav-link:hover,
.seller-navbar-dark .seller-navbar-nav .seller-nav-link:focus {
  color: rgba(255, 255, 255, 0.65);
}

.seller-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 0.375rem;
  background-color: #fff;
  background-clip: border-box;
}

.seller-card > hr {
  margin-right: 0;
  margin-left: 0;
}

.seller-card-body {
  padding: 1.5rem;
  flex: 1 1 auto;
}

.seller-card-header {
  margin-bottom: 0;
  padding: 1.25rem 1.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  background-color: #fff;
}

.seller-card-header:first-child {
  border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

.progress {
  font-size: 0.75rem;
  display: flex;
  overflow: hidden;
  height: 1rem;
  border-radius: 0.375rem;
  background-color: #e9ecef;
  box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1 1;
}

.bg-secondary {
  background-color: #fff !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #d2e3ee !important;
}

.bg-default {
  background-color: #172b4d !important;
}

a.bg-default:hover,
a.bg-default:focus,
button.bg-default:hover,
button.bg-default:focus {
  background-color: #0b1526 !important;
}

.bg-white {
  background-color: #fff !important;
}

a.bg-white:hover,
a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important;
}

.bg-white {
  background-color: #fff !important;
}

.border-0 {
  border: 0 !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.d-none {
  display: none !important;
}

.d-flex {
  display: flex !important;
}

@media (min-width: 768px) {
  .d-md-flex {
    display: flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.align-items-center {
  align-items: center !important;
}

@media (min-width: 1200px) {
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
}

.float-right {
  float: right !important;
}

.shadow,
.seller-card-profile-image img {
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
}

/* .m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mt--7 {
  margin-top: -6rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pt-8 {
  padding-top: 8rem !important;
}

.pb-8 {
  padding-bottom: 8rem !important;
}

.m-auto {
  margin: auto !important;
}

@media (min-width: 768px) {
  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
}

@media (min-width: 992px) {
  .pl-lg-4 {
    padding-left: 1.5rem !important;
  }

  .pt-lg-8 {
    padding-top: 8rem !important;
  }

  .ml-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-bold {
  font-weight: 600 !important;
}

.text-white {
  color: #fff !important;
}

.text-white {
  color: #fff !important;
}

a.text-white:hover,
a.text-white:focus {
  color: #e6e6e6 !important;
}

.text-muted {
  color: #8898aa !important;
}

@media print {
  *,
  *::before,
  *::after {
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a:not(.seller-btn) {
    text-decoration: underline;
  }

  img {
    page-break-inside: avoid;
  }

  p,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h3 {
    page-break-after: avoid;
  }

  body {
    min-width: 992px !important;
  }

  .seller-container {
    min-width: 992px !important;
  }

  .seller-navbar {
    display: none;
  }
}

figcaption,
main {
  display: block;
}

main {
  overflow: hidden;
}

.bg-white {
  background-color: #fff !important;
}

a.bg-white:hover,
a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important;
}

.bg-gradient-default {
  background: linear-gradient(87deg, #172b4d 0, #1a174d 100%) !important;
}

.bg-gradient-default {
  background: linear-gradient(87deg, #172b4d 0, #1a174d 100%) !important;
} */

@keyframes floating-lg {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(15px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes floating {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes floating-sm {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(5px);
  }

  100% {
    transform: translateY(0px);
  }
}

.opacity-8 {
  opacity: 0.8 !important;
}

.opacity-8 {
  opacity: 0.9 !important;
}

.center {
  left: 50%;
  transform: translateX(-50%);
}

[class*="shadow"] {
  transition: all 0.15s ease;
}

.font-weight-300 {
  font-weight: 300 !important;
}

.text-sm {
  font-size: 0.875rem !important;
}

.text-white {
  color: #fff !important;
}

a.text-white:hover,
a.text-white:focus {
  color: #e6e6e6 !important;
}

.avatar {
  font-size: 1rem;
  display: inline-flex;
  width: 48px;
  height: 48px;
  color: #fff;
  border-radius: 50%;
  background-color: #adb5bd;
  align-items: center;
  justify-content: center;
}

.avatar img {
  width: 100%;
  border-radius: 50%;
}

.avatar-sm {
  font-size: 0.875rem;
  width: 36px;
  height: 36px;
}

.seller-btn {
  font-size: 0.875rem;
  position: relative;
  transition: all 0.15s ease;
  letter-spacing: 0.025em;
  text-transform: none;
  will-change: transform;
}

.seller-btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.seller-btn:not(:last-child) {
  margin-right: 0.5rem;
}

.seller-btn i:not(:first-child) {
  margin-left: 0.5rem;
}

.seller-btn i:not(:last-child) {
  margin-right: 0.5rem;
}

.seller-input-group .seller-btn {
  margin-right: 0;
  transform: translateY(0);
}

.seller-btn-sm {
  font-size: 0.75rem;
}

[class*="btn-outline-"] {
  border-width: 1px;
}

.seller-card-profile-image {
  position: relative;
}

.seller-card-profile-image img {
  position: absolute;
  left: 50%;
  max-width: 180px;
  transition: all 0.15s ease;
  transform: translate(-50%, -30%);
  border-radius: 0.375rem;
}

.seller-card-profile-image img:hover {
  transform: translate(-50%, -33%);
}

.seller-card-profile-stats {
  padding: 1rem 0;
}

.seller-card-profile-stats > div {
  margin-right: 1rem;
  padding: 0.875rem;
  text-align: center;
}

.seller-card-profile-stats > div:last-child {
  margin-right: 0;
}

.seller-card-profile-stats > div .heading {
  font-size: 1.1rem;
  font-weight: bold;
  display: block;
}

.seller-card-profile-stats > div .description {
  font-size: 0.875rem;
  color: #adb5bd;
}

.main-content {
  position: relative;
  min-height: 65rem !important;
}

.profile-content {
  min-height: 23rem !important;
}

.main-content .seller-navbar-top {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  padding-right: 0 !important;
  padding-left: 0 !important;
  background-color: transparent;
}

@media (min-width: 768px) {
  .main-content .seller-container-fluid {
    padding-right: 39px !important;
    padding-left: 39px !important;
  }
}

.seller-dropdown {
  display: inline-block;
}

.seller-dropdown-menu {
  min-width: 12rem;
}

.seller-dropdown-menu .seller-dropdown-item {
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
}

.seller-dropdown-menu .seller-dropdown-item > i {
  font-size: 1rem;
  margin-right: 1rem;
  vertical-align: -17%;
}

.seller-dropdown-header {
  font-size: 0.625rem;
  font-weight: 700;
  padding-right: 1rem;
  padding-left: 1rem;
  text-transform: uppercase;
  color: #f6f9fc;
}

.seller-dropdown-menu a.media > div:first-child {
  line-height: 1;
}

.seller-dropdown-menu a.media p {
  color: #8898aa;
}

.seller-dropdown-menu a.media:hover .heading,
.seller-dropdown-menu a.media:hover p {
  color: #172b4d !important;
}

.footer {
  padding: 2.5rem 0;
  background: #f7fafc;
}

.footer .seller-nav .seller-nav-item .seller-nav-link {
  color: #8898aa !important;
}

.footer .seller-nav .seller-nav-item .seller-nav-link:hover {
  color: #525f7f !important;
}

.footer .copyright {
  font-size: 0.875rem;
}

.seller-form-control-label {
  /* font-size: 0.875rem; */
  font-weight: 400;
  /* color: #525f7f; */
}

/* .seller-form-control {
  font-size: 0.875rem;
} */

.seller-form-control:focus:-ms-input-placeholder {
  color: #adb5bd;
}

.seller-form-control:focus::-ms-input-placeholder {
  color: #adb5bd;
}

.seller-form-control:focus::placeholder {
  color: #adb5bd;
}

textarea[resize="none"] {
  resize: none !important;
}

textarea[resize="both"] {
  resize: both !important;
}

textarea[resize="vertical"] {
  resize: vertical !important;
}

textarea[resize="horizontal"] {
  resize: horizontal !important;
}

.seller-text-area {
  border-color: rgba(24, 67, 94, 0.25) !important;
}

.seller-form-control-alternative {
  transition: box-shadow 0.15s ease;
  border: 0;
  box-shadow: 0 1px 3px rgba(31, 31, 31, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
}

.seller-form-control-alternative:focus {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.seller-input-group {
  transition: all 0.15s ease;
  border-radius: 0.375rem;
  box-shadow: none;
}

.seller-input-group .seller-form-control {
  box-shadow: none;
}

.seller-input-group .seller-form-control:not(:first-child) {
  padding-left: 0;
  border-left: 0;
}

.seller-input-group .seller-form-control:not(:last-child) {
  padding-right: 0;
  border-right: 0;
}

.seller-input-group .seller-form-control:focus {
  box-shadow: none;
}

.seller-input-group-text {
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.seller-input-group-alternative {
  transition: box-shadow 0.15s ease;
  border: 0;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
}

.seller-input-group-alternative .seller-form-control,
.seller-input-group-alternative .seller-input-group-text {
  border: 0;
  box-shadow: none;
}

.focused .seller-input-group-alternative {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08) !important;
}

.focused .seller-input-group {
  box-shadow: none;
}

.focused .seller-input-group-text {
  color: #8898aa;
  border-color: rgba(50, 151, 211, 0.25);
  background-color: #fff;
}

.focused .seller-form-control {
  border-color: rgba(50, 151, 211, 0.25);
}

/* .header {
  position: relative;
} */

.header {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  z-index: 100;
  width: 90%;
  border-top-right-radius: 4rem;
  border-bottom-right-radius: 4rem;
  margin-top: 40px;
}

.services-bg-image-clinic {
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.services-bg-image-store {
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
}
.services-bg-image-hands4paws {
  background-position: right;
  background-size: cover;
}

.servicesBg {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #FFE4D4!important;
  border-radius: 50px;
  border: 1px solid #fc633b66!important;
  position: relative;
  min-height: 230px;
}

.iconImg img {
  width: 50px;
}

.paddingMore {
  padding-bottom: 10%;
}

.responsive-filter .flex {
  display: flex;
  align-items: center;
  gap: 3rem;
  margin: 0;
  padding-bottom: 2rem;
  position: absolute;
  top: 0;
  z-index: 9;
  left: 25%;
}

.responsive-filter .card-header {
  padding: 1.25rem 0.75rem;
  margin-bottom: 0;
  background-color: #FAF5F2;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 10px!important;
}

.responsive-filter .card-header .fas {
  color: #707070;
  font-size: 18px;
}

.responsive-filter .card-header h5 {
  color: #292929;
  font-weight: normal;
}

.responsive-filter .widget {
  padding: 0;
}

.widget .rc-slider-track {
  background-color: #ff7000 !important;
}

.widget .rc-slider-handle {
  border: solid 2px #ff7000;
}

.widget .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: #ff7000;
  box-shadow: 0 0 0 5px #ff7000;
}

.widget input[type=radio]{
  accent-color: #ff7000;
}

.responsive-filter button {
  font-size: 18px;
  margin: 0!important;
}

.responsive-filter a.text-dark:focus, .responsive-filter a.text-dark:hover {
  text-decoration: none;
}

.responsive-filter .collapse.show {
  border-bottom: 1px solid #cbcbcb;
  margin-bottom: 12px;
}

.responsive-filter .filter-label {
  display: flex!important;
  align-items: center;
  margin-bottom: 1.5rem;
  color: #707070;
}

.responsive-filter .filter-label input {
  width: 16px;
  height: 16px;
  opacity: 0.6;
  margin-top: 0!important;
}

.responsive-filter .filter-label span.brand-num {
  margin-left: 5px;
}

.filtericon {
  width: 25px;
  height: 25px;
  display: flex;
  margin-right: 10px;
  margin: 0;
}

.hideIcon {
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  margin-left: 10px;
  gap: 10px;
  color: #888584;
  font-weight: 500;
}

.storePage{
  font-family: 'Montserrat';
  color: #707070;
}

.storePage .common-container {
  position: relative;
}

.filterText {
  display: flex;
  gap: 10px;
  align-items: center;
}

.search-filter-container .form-control {
  border: 1px solid #707070;
  border-radius: 8px;
}

.search-filter-container .input-group-text {
  border: 1px solid #707070!important;
  background: #E4E4E4!important;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.section-content .products .product {
  border: 1px solid #B5B5B5;
  border-radius: 36px;
  position: relative;
  min-height: 450px;
  overflow: visible;
  min-height: 473px;
}

.section-content .products .product .noRelative {
  position: static;
  width: 100%;
  padding-bottom: 5px;
}

/* .section-content .products .product .text-success {
  position: absolute;
  top: 0;
  right: -120px;
  background: #FC633B;
  color: #fff! important;
  padding: 15px;
  margin: 0! important;
  width: 160px;
  transform: skew(39deg);
  -webkit-transform: skew(39deg);
  -moz-transform: skew(39deg);
  -o-transform: skew(39deg);
  height: 100px;
} */

.section-content .products .product .text-success {
  position: absolute;
  top: -1px;
  right: -1px;
  background: #FC633B;
  color: #fff !important;
  padding: 23px 15px 10px 55px;
  margin: 0 !important;
  width: 100px;
  height: 115px;
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 100% 100%);
  display: flex;
  justify-content: center;
  border-top-right-radius: 36px;
}

.section-content .products .product .text-success>div {
  color: #fff;
  display: flex;
  line-height: 13px;
  font-size: 16px;
  font-family: Montserrat;
}

.borderLineBottom {
  display: flex;
  width: 90%;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border-bottom: 1px solid #cfcfcf;
  height: 1px;
  padding: 5px 0;
  margin-bottom: 50px;
}

.cartWrapper {
  width: 87%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #FC633B;
  border-radius: 18px;
  padding: 7px;
  color: #fff;
  font-size: 18px;
  text-align: center;
  position: absolute;
  bottom: -20px;
  margin: 0 auto;
  left: 7%;
  cursor: pointer;
}

.products .blockPadding {
  padding: 0 20px;
  padding-bottom: 35px;
}

.product .product-details .price {
  margin-top: 0;
  position: absolute;
  bottom: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product .price .amount {
  color: #FC633B;
  font-size: 20px;
  font-family: Montserrat;
}

/* .product .price del .amount {
  color: #CBC9C9;
  font-weight: lighter;
} */

/* .page-item.disabled .page-link {
  color: #FC633B;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
} */

.page-item:last-child .page-link {
  color: #FC633B;
  font-size: 20px;
  border-left: 1px solid #707070;
  line-height: 15px;
}

.storeAddSectn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 1199px) {
  .products .blockPadding {
    width: 50%;
    clear: none!important;
  }

  .product .product-details {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 900px) {

  .cartWrapper {
    width: 85%;
  }

  /* .products .blockPadding {
    width: 50%;
  } */

  .storePage .common-container {
    margin: 0;
    padding: 15px;
  }

  .responsive-filter .flex {
    gap: 1rem;
    margin: 0;
    padding-bottom: 0;
    top: 50px;
    left: 0;
    width: 100%;
    justify-content: space-between;
  }

  .responsive-filter button {
    font-size: 15px;
  }

  .servicesBg {
    min-height: auto;
  }

  .servicesBg h4 a {
    font-size: 20px;
  }

  .paddingMore {
    padding-bottom: 5%;
    margin: 10px 20px!important;
  }
  .header {
    margin-top: 0px;
  }

  .menuzord {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .menuzord-menu > li {
    padding: 0;
  }

  .menuzord.orange .menuzord-menu > li.active > a,
.menuzord.orange .menuzord-menu > li:hover > a,
.menuzord.orange .menuzord-menu ul.dropdown li:hover > a {
  background: #df4e28;
  border-top: none;
  border-bottom: 1px solid #df4d27;
}

.menuzord-responsive .menuzord-menu > li > a {
  border-bottom: solid 1px #df4d27;
}

.menuzord-responsive .menuzord-menu > li:first-child > a {
  border-top: solid 1px #df4d27;
}

.menuzord-responsive .menuzord-menu > li:last-child > a {
  border-bottom: none;
}

.menuzord-responsive .menuzord-menu li .indicator {
  border-left: solid 1px #df4d27;
}

.menuzord .menuzord-menu ul.dropdown, .menuzord .menuzord-menu .megamenu {
  border: none;
  background: #df4d27;
  border-top: 1px solid #fc623a;
  border-bottom-right-radius: 18px;
}

.menuzord-responsive .menuzord-menu ul.dropdown li a {
  border-bottom: solid 1px #fc623a;
}

.menuzord-responsive .menuzord-menu ul.dropdown li:last-child a {
  border-bottom: none;
}

.icon-box .icon.icon-lg { 
  height: 80px;
  width: 80px;
  margin-bottom: 5px;
}
}

@media only screen and (max-width: 576px) {
  .storePage .row {
    width: 100%;
    margin: 0;
  }

  .storePage .filter-border {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .pagination_wrap .pagination {
    gap: 5px!important;
    width: 100%;
  }

  .pagination > li > a, .pagination > li > span {
    padding: 5px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0;
  }

  .cartWrapper {
    width: 85%;
  }
  .products .blockPadding {
    width: 100%;
  }

  .filtericon {
    width: 20px;
    height: 20px;
    margin: 0;
  }

  .responsive-filter .flex {
    overflow: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none; 
    justify-content: flex-start;
  }

  .responsive-filter .flex::-webkit-scrollbar {
    display: none;
  }

  .section-content .filter-inner-modal-btn {
    width: 100%;
    display: inline-flex;
    margin: 0 0 40px 0 !important;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    padding-right: 45px;
  }

  .main-content {
    overflow: hidden;
  }
}

.seller-input-group {
  transition: all 0.15s ease;
  border-radius: 0.375rem;
  box-shadow: none;
}

.seller-input-group .seller-form-control {
  box-shadow: none;
}

.seller-input-group .seller-form-control:not(:first-child) {
  padding-left: 0;
  border-left: 0;
}

.seller-input-group .seller-form-control:not(:last-child) {
  padding-right: 0;
  border-right: 0;
}

.seller-input-group .seller-form-control:focus {
  box-shadow: none;
}

.seller-input-group-text {
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.seller-input-group-alternative {
  transition: box-shadow 0.15s ease;
  border: 0;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
}

.seller-input-group-alternative .seller-form-control,
.seller-input-group-alternative .seller-input-group-text {
  border: 0;
  box-shadow: none;
}

.focused .seller-input-group-alternative {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08) !important;
}

.focused .seller-input-group {
  box-shadow: none;
}

.focused .seller-input-group-text {
  color: #8898aa;
  border-color: rgba(50, 151, 211, 0.25);
  background-color: #fff;
}

.focused .seller-form-control {
  border-color: rgba(50, 151, 211, 0.25);
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.15s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .mask {
    transition: none;
  }
}

.seller-nav-link {
  color: #525f7f;
}

.seller-nav-link:hover {
  color: #5e72e4;
}

.seller-nav-link i.ni {
  position: relative;
  top: 2px;
}

.seller-navbar-search .seller-input-group {
  border: 2px solid;
  border-radius: 2rem;
  background-color: transparent;
}

.seller-navbar-search .seller-input-group .seller-input-group-text {
  padding-left: 1rem;
  background-color: transparent;
}

.seller-navbar-search .seller-form-control {
  width: 270px;
  background-color: transparent;
}

.seller-navbar-search-dark .seller-input-group {
  border-color: rgba(255, 255, 255, 0.6);
}

.seller-navbar-search-dark .seller-input-group-text {
  color: rgba(255, 255, 255, 0.6);
}

.seller-navbar-search-dark .seller-form-control {
  color: rgba(255, 255, 255, 0.9);
}

.seller-navbar-search-dark .seller-form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.seller-navbar-search-dark .seller-form-control::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.seller-navbar-search-dark .seller-form-control::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.seller-navbar-search-dark .focused .seller-input-group {
  border-color: rgba(255, 255, 255, 0.9);
}

@media (min-width: 768px) {
  .seller-navbar .seller-dropdown-menu {
    margin: 0;
    pointer-events: none;
    opacity: 0;
  }

  .seller-navbar .seller-dropdown-menu-arrow:before {
    position: absolute;
    z-index: -5;
    bottom: 100%;
    left: 20px;
    display: block;
    width: 12px;
    height: 12px;
    content: "";
    transform: rotate(-45deg) translateY(12px);
    border-radius: 2px;
    background: #fff;
    box-shadow: none;
  }

  .seller-navbar .seller-dropdown-menu-right:before {
    right: 20px;
    left: auto;
  }

  @keyframes show-navbar-dropdown {
    0% {
      transition: visibility 0.25s, opacity 0.25s, transform 0.25s;
      transform: translate(0, 10px) perspective(200px) rotateX(-2deg);
      opacity: 0;
    }

    100% {
      transform: translate(0, 0);
      opacity: 1;
    }
  }

  @keyframes hide-navbar-dropdown {
    from {
      opacity: 1;
    }

    to {
      transform: translate(0, 10px);
      opacity: 0;
    }
  }
}

@media (max-width: 767.98px) {
  .seller-navbar-nav .seller-nav-link {
    padding: 0.625rem 0;
    color: #172b4d !important;
  }

  .seller-navbar-nav .seller-dropdown-menu {
    min-width: auto;
    box-shadow: none;
  }
}

@keyframes show-navbar-collapse {
  0% {
    transform: scale(0.95);
    transform-origin: 100% 0;
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes hide-navbar-collapse {
  from {
    transform: scale(1);
    transform-origin: 100% 0;
    opacity: 1;
  }

  to {
    transform: scale(0.95);
    opacity: 0;
  }
}

.progress {
  overflow: hidden;
  height: 8px;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
  background-color: #e9ecef;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

/* p {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.7;
} */

.description {
  font-size: 0.875rem;
}

.heading {
  font-size: 0.95rem;
  font-weight: 600;
  letter-spacing: 0.025em;
  text-transform: uppercase;
}

.heading-small {
  font-size: 0.75rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}

.display-2 span {
  font-weight: 300;
  display: block;
}

@media (max-width: 768px) {
  .seller-btn {
    margin-bottom: 10px;
  }
}

#navbar .seller-navbar {
  margin-bottom: 20px;
}

.badge-pawwalker {
  /* background-color: #ff670e; */
  background-color: transparent;
  color: #707070;
  font-family: Montserrat;
  font-weight: normal;
  position: relative;
  padding-right: 5px;
  margin-right: 1px;
}

.badge-pawwalker-active{
  color: #ff670e;
  background-color: transparent;
  font-family: Montserrat;
  font-weight: normal;
  position: relative;
  padding-right: 5px;
  margin-right: 1px;
}

.badge-pawwalker::after {
  content: "|";
  width: 1px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 3px;
}

.badge-pawwalker:hover {
  color: #ff670e;
  border: none;
  outline: none;
}

.forgot-password-form-input {
  width: 300px;
}
