/*
Strip Skin
*/

/* Active/hover state (Menu items) */
.menuzord-menu > li.active > a,
.menuzord-menu > li:hover > a {
  background: #fff;
  color: #333;
}

/* Hover state (dropdown items) */
.menuzord-menu ul.dropdown li:hover > a {
  background: #fff;
  color: #333;
}

/* Color schemes */
/* blue */
.menuzord.blue .menuzord-menu > li.active > a,
.menuzord.blue .menuzord-menu > li:hover > a,
.menuzord.blue .menuzord-menu ul.dropdown li:hover > a {
  background: #4a89dc;
  color: #fff;
}
/* green */
.menuzord.green .menuzord-menu > li.active > a,
.menuzord.green .menuzord-menu > li:hover > a,
.menuzord.green .menuzord-menu ul.dropdown li:hover > a {
  background: #37bc9b;
  color: #fff;
}
/* red */
.menuzord.red .menuzord-menu > li.active > a,
.menuzord.red .menuzord-menu > li:hover > a,
.menuzord.red .menuzord-menu ul.dropdown li:hover > a {
  /* background: #; */
  color: #e9573f;
}
/* orange */
.menuzord.orange .menuzord-menu > li.active > a,
.menuzord.orange .menuzord-menu > li:hover > a,
.menuzord.orange .menuzord-menu ul.dropdown li:hover > a {
  /* color: #ff670e; */
  color: #fff;
  border-bottom: 2px solid #fff;
  background: transparent;
  /* font-weight: bold; */
}
/* yellow */
.menuzord.yellow .menuzord-menu > li.active > a,
.menuzord.yellow .menuzord-menu > li:hover > a,
.menuzord.yellow .menuzord-menu ul.dropdown li:hover > a {
  background: #f6bb42;
  color: #fff;
}
/* purple */
.menuzord.purple .menuzord-menu > li.active > a,
.menuzord.purple .menuzord-menu > li:hover > a,
.menuzord.purple .menuzord-menu ul.dropdown li:hover > a {
  background: #967adc;
  color: #fff;
}
/* pink */
.menuzord.pink .menuzord-menu > li.active > a,
.menuzord.pink .menuzord-menu > li:hover > a,
.menuzord.pink .menuzord-menu ul.dropdown li:hover > a {
  background: #d770ad;
  color: #fff;
}

/* Mobile mode (Responsive mode) */
@media (max-width: 900px) {
  /* Menu items */
  .menuzord-responsive .menuzord-menu > li > a {
    padding: 12px 20px !important;
  }
}
