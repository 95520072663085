/* --------------------------------------
@Author: ThemeMascot
@URL: http://themeforest.net/user/ThemeMascot
----------------------------------------- */
/*
 * Utility Classes
 * -----------------------------------------------
*/
/* -------- Text Colors ---------- */
/* Text Black & Gray Color*/
.text-black {
  color: #000 !important;
}
.text-black-111 {
  color: #111111 !important;
}
.text-black-222 {
  color: #222222 !important;
}
.text-black-333 {
  color: #333333 !important;
}
.text-black-444 {
  color: #404040 !important;
}
.text-black-555 {
  color: #555555 !important;
}
.text-black-666 {
  color: #666666 !important;
}
.text-gray-dimgray {
  color: #666666 !important;
}
.text-gray-light {
  color: #777777 !important;
}
.text-gray {
  color: #808080 !important;
}
.text-gray-darkgray {
  color: #a9a9a9 !important;
}
.text-gray-silver {
  color: #c0c0c0 !important;
}
.text-gray-lightgray {
  color: #d3d3d3 !important;
}
.text-gray-gainsboro {
  color: #fff !important;
}
.text-gray-lighter {
  color: #eeeeee !important;
}
/* Text White Color*/
.text-white {
  color: #fff !important;
}
.text-white-f1 {
  color: #f1f1f1 !important;
}
.text-white-f2 {
  color: #f2f2f2 !important;
}
.text-white-f3 {
  color: #f3f3f3 !important;
}
.text-white-f4 {
  color: #f4f4f4 !important;
}
.text-white-f5 {
  color: #f5f5f5 !important;
}
.text-white-f6 {
  color: #f6f6f6 !important;
}
.text-white-f7 {
  color: #f7f7f7 !important;
}
.text-white-f8 {
  color: #f8f8f8 !important;
}
.text-white-f9 {
  color: #f9f9f9 !important;
}
.text-white-fa {
  color: #fafafa !important;
}
.text-white-fc {
  color: #fcfcfc !important;
}
.text-white-fd {
  color: #fdfdfd !important;
}
.text-white-fe {
  color: #fefefe !important;
}
/* -------- Solid Color Background ---------- */
.bg-deep {
  background-color: #f1f1f1 !important;
}
.bg-light {
  background-color: #f5f5f5 !important;
}
.bg-lighter {
  background-color: #f7f7f7 !important;
}
.bg-lightest {
  background-color: #fcfcfc !important;
}
.bg-silver-light {
  background-color: #F7F8FA !important;
}
.bg-black {
  background-color: #000 !important;
}
.divider-dark {
  background-color: #252525 !important;
}
footer.bg-deep {
  background-color: #191919 !important;
}
.bg-black-111 {
  background-color: #111111 !important;
}
.bg-black-222 {
  background-color: #222222 !important;
}
.bg-black-333 {
  background-color: #333333 !important;
}
.bg-black-444 {
  background-color: #404040 !important;
}
.bg-black-555 {
  background-color: #555555 !important;
}
.bg-gray-dimgray {
  background-color: #666666 !important;
}
.bg-gray-light {
  background-color: #777777 !important;
}
.bg-gray {
  background-color: #808080 !important;
}
.bg-gray-darkgray {
  background-color: #a9a9a9 !important;
}
.bg-gray-silver {
  background-color: #c0c0c0 !important;
}
.bg-gray-lightgray {
  background-color: #d3d3d3 !important;
}
.bg-gray-gainsboro {
  background-color: #dcdcdc !important;
}
.bg-gray-lighter {
  background-color: #eeeeee !important;
}
/* Bg White Color*/
.bg-white {
  background-color: #fff !important;
}
.bg-white-f1 {
  background-color: #f1f1f1 !important;
}
.bg-white-f2 {
  background-color: #f2f2f2 !important;
}
.bg-white-f3 {
  background-color: #f3f3f3 !important;
}
.bg-white-f4 {
  background-color: #f4f4f4 !important;
}
.bg-white-f5 {
  background-color: #f5f5f5 !important;
}
.bg-white-f6 {
  background-color: #f6f6f6 !important;
}
.bg-white-f7 {
  background-color: #f7f7f7 !important;
}
.bg-white-f8 {
  background-color: #f8f8f8 !important;
}
.bg-white-f9 {
  background-color: #f9f9f9 !important;
}
.bg-white-fa {
  background-color: #fafafa !important;
}
.bg-white-fb {
  background-color: #fbfbfb !important;
}
.bg-white-fc {
  background-color: #fcfcfc !important;
}
.bg-white-fd {
  background-color: #fdfdfd !important;
}
.bg-white-fe {
  background-color: #fefefe !important;
}
/* -------- Transparent Background Color ---------- */
.bg-white-transparent-9 {
  background-color: rgba(255, 255, 255, 0.9);
}
.bg-white-transparent-8 {
  background-color: rgba(255, 255, 255, 0.8);
}
.bg-white-transparent-7 {
  background-color: rgba(255, 255, 255, 0.7);
}
.bg-white-transparent-6 {
  background-color: rgba(255, 255, 255, 0.6);
}
.bg-white-transparent-5 {
  background-color: rgba(255, 255, 255, 0.5);
}
.bg-white-transparent-4 {
  background-color: rgba(255, 255, 255, 0.4);
}
.bg-white-transparent-3 {
  background-color: rgba(255, 255, 255, 0.3);
}
.bg-white-transparent-2 {
  background-color: rgba(255, 255, 255, 0.2);
}
.bg-white-transparent-1 {
  background-color: rgba(255, 255, 255, 0.1);
}
.bg-dark-transparent-9 {
  background-color: rgba(0, 0, 0, 0.9);
}
.bg-dark-transparent-8 {
  background-color: rgba(0, 0, 0, 0.8);
}
.bg-dark-transparent-7 {
  background-color: rgba(0, 0, 0, 0.7);
}
.bg-dark-transparent-6 {
  background-color: rgba(0, 0, 0, 0.6);
}
.bg-dark-transparent-5 {
  background-color: rgba(0, 0, 0, 0.5);
}
.bg-dark-transparent-4 {
  background-color: rgba(0, 0, 0, 0.4);
}
.bg-dark-transparent-3 {
  background-color: rgba(0, 0, 0, 0.3);
}
.bg-dark-transparent-2 {
  background-color: rgba(0, 0, 0, 0.2);
}
.bg-dark-transparent-1 {
  background-color: rgba(0, 0, 0, 0.1);
}
/* -------- Font Weight ---------- */
.font-weight-100 {
  font-weight: 100 !important;
}
.font-weight-200 {
  font-weight: 200 !important;
}
.font-weight-300 {
  font-weight: 300 !important;
}
.font-weight-400 {
  font-weight: 400 !important;
}
.font-weight-500 {
  font-weight: 500 !important;
}
.font-weight-600 {
  font-weight: 600 !important;
}
.font-weight-700 {
  font-weight: 700 !important;
}
.font-weight-800 {
  font-weight: 800 !important;
}
.font-weight-900 {
  font-weight: 900 !important;
}
/* -------- Font Size ---------- */
.font-9 {
  font-size: 9px !important;
}
.font-10 {
  font-size: 10px !important;
}
.font-11 {
  font-size: 11px !important;
}
.font-12 {
  font-size: 12px !important;
}
.font-13 {
  font-size: 13px !important;
}
.font-14 {
  font-size: 14px !important;
}
.font-15 {
  font-size: 15px !important;
}
.font-16 {
  font-size: 16px !important;
}
.font-17 {
  font-size: 17px !important;
}
.font-18 {
  font-size: 18px !important;
}
.font-19 {
  font-size: 19px !important;
}
.font-20 {
  font-size: 20px !important;
}
.font-21 {
  font-size: 21px !important;
}
.font-22 {
  font-size: 22px !important;
}
.font-23 {
  font-size: 23px !important;
}
.font-24 {
  font-size: 24px !important;
}
.font-25 {
  font-size: 25px !important;
}
.font-26 {
  font-size: 26px !important;
}
.font-27 {
  font-size: 27px !important;
}
.font-28 {
  font-size: 2rem !important;
}
.font-29 {
  font-size: 2.07142857rem !important;
}
.font-30 {
  font-size: 2.14285714rem !important;
}
.font-32 {
  font-size: 2.28571429rem !important;
}
.font-36 {
  font-size: 2.57142857rem !important;
}
.font-38 {
  font-size: 2.71428571rem !important;
}
.font-40 {
  font-size: 2.85714286rem !important;
}
.font-42 {
  font-size: 3rem !important;
}
.font-44 {
  font-size: 3.14285714rem !important;
}
.font-46 {
  font-size: 3.28571429rem !important;
}
.font-48 {
  font-size: 3.42857143rem !important;
}
.font-54 {
  font-size: 3.85714286rem !important;
}
.font-60 {
  font-size: 4.28571429rem !important;
}
.font-64 {
  font-size: 4.57142857rem !important;
}
.font-72 {
  font-size: 5.14285714rem !important;
}
.font-78 {
  font-size: 5.57142857rem !important;
}
.font-90 {
  font-size: 6.42857143rem !important;
}
.font-100 {
  font-size: 7.14285714rem !important;
}
.font-150 {
  font-size: 10.71428571rem !important;
}
.font-200 {
  font-size: 14.28571429rem !important;
}
/* -------- Letter Spacing ---------- */
.letter-space-0 {
  letter-spacing: 0px;
}
.letter-space-1 {
  letter-spacing: 1px;
}
.letter-space-2 {
  letter-spacing: 2px;
}
.letter-space-3 {
  letter-spacing: 3px;
}
.letter-space-4 {
  letter-spacing: 4px;
}
.letter-space-5 {
  letter-spacing: 5px;
}
.letter-space-6 {
  letter-spacing: 6px;
}
.letter-space-7 {
  letter-spacing: 7px;
}
.letter-space-8 {
  letter-spacing: 8px;
}
.letter-space-9 {
  letter-spacing: 9px;
}
.letter-space-10 {
  letter-spacing: 10px;
}
/* -------- z-index ---------- */
.z-index--1 {
  z-index: -1 !important;
}
.z-index-0 {
  z-index: 0 !important;
}
.z-index-1 {
  z-index: 1 !important;
}
.z-index-2 {
  z-index: 2 !important;
}
.z-index-3 {
  z-index: 3 !important;
}
.z-index-4 {
  z-index: 4 !important;
}
.z-index-5 {
  z-index: 5 !important;
}
.z-index-99 {
  z-index: 99 !important;
}
.z-index-111 {
  z-index: 111 !important;
}
.z-index-1111 {
  z-index: 1111 !important;
}
.z-index-9999 {
  z-index: 9999 !important;
}
/* -------- Line Height ---------- */
.line-height-0 {
  line-height: 0px;
}
.line-height-1 {
  line-height: 1;
}
.line-height-20 {
  line-height: 20px;
}
.line-height-50 {
  line-height: 50px;
}
.line-height-80 {
  line-height: 80px;
}
.line-height-100 {
  line-height: 100px;
}
.line-height-110 {
  line-height: 110px;
}
.line-height-120 {
  line-height: 120px;
}
.line-height-130 {
  line-height: 130px;
}
.line-height-150 {
  line-height: 150px;
}
.line-height-200 {
  line-height: 200px;
}
.line-height-1em {
  line-height: 1em;
}
.line-height-1 {
  line-height: 1 !important;
}
/* -------- Custom Margin Padding ---------- */
.mb-5 {
  margin-bottom: 5px !important;
}
.ml-5 {
  margin-left: 5px !important;
}
.mr-5 {
  margin-right: 5px !important;
}
.mt-5 {
  margin-top: 5px !important;
}
.mt-12 {
  margin-top: 12px !important;
}
.mb-25 {
  margin-bottom: 25px !important;
}
.pb-5 {
  padding-bottom: 5px !important;
}
.pl-5 {
  padding-left: 5px !important;
}
.pr-5 {
  padding-right: 5px !important;
}
.pt-5 {
  padding-top: 5px !important;
}
.p-25 {
  padding: 25px !important;
}
/* -------- Border ---------- */
.no-border {
  border: none !important;
}
.border-1px {
  border: 1px solid #eeeeee !important;
}
.border-2px {
  border: 2px solid #eeeeee !important;
}
.border-3px {
  border: 3px solid #eeeeee !important;
}
.border-4px {
  border: 4px solid #eeeeee !important;
}
.border-5px {
  border: 5px solid #eeeeee !important;
}
.border-left {
  border-left: 1px solid #dcdcdc !important;
}
.border-right {
  border-right: 1px solid #dcdcdc !important;
}
.border-top {
  border-top: 1px solid #dcdcdc !important;
}
.border-bottom {
  border-bottom: 1px solid #eeeeee !important;
}
.border-bottom-2px {
  border-bottom: 2px solid #eeeeee !important;
}
.border-bottom-gray {
  border-bottom: 1px solid #d3d3d3 !important;
}
.border-gray {
  border-color: #eeeeee !important;
}
/* -------- Border Radius ---------- */
.border-radius-5px {
  border-radius: 5px;
}
.border-radius-10px {
  border-radius: 10px;
}
.border-radius-15px {
  border-radius: 15px;
}
.border-radius-20px {
  border-radius: 20px;
}
.border-radius-25px {
  border-radius: 25px;
}
.border-radius-30px {
  border-radius: 30px;
}
.border-radius-35px {
  border-radius: 35px;
}
.border-radius-40px {
  border-radius: 40px;
}
.border-radius-45px {
  border-radius: 45px;
}
.border-radius-50px {
  border-radius: 50px;
}
/* -------- Position ---------- */
.relative {
  position: relative !important;
}
.absolute {
  position: absolute !important;
}
.absolute-inherit {
  position: inherit !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.position-static {
  position: static !important;
}
.position-fixed {
  position: fixed !important;
}
.position-inherit {
  position: inherit !important;
}
.position-unset {
  position: unset !important;
}
/* -------- Background Image ---------- */
.no-bg {
  background: none !important;
}
.bg-no-repeat {
  background-repeat: no-repeat !important;
}
.bg-img-fixed {
  background-attachment: fixed !important;
}
.bg-img-cover {
  background-size: cover !important;
}
.bg-img-center-bottom {
  background-position: center bottom !important;
}
.bg-img-center {
  background-position: center center !important;
}
.bg-img-left-bottom {
  background-repeat: no-repeat;
  background-position: left bottom;
}
.bg-img-right-top {
  background-repeat: no-repeat;
  background-position: right top;
}
.bg-img-left-top {
  background-repeat: no-repeat;
  background-position: left top;
}
.img-fullwidth {
  width: 100% !important;
}
/* -------- Absolute Image Positioning ---------- */
.overflow-visible {
  overflow: visible !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.overflow-x-hidden {
  overflow-x: hidden !important;
}
.overflow-y-hidden {
  overflow-y: hidden !important;
}
.box-absolute {
  position: absolute !important;
}
.img-absolute-parent {
  position: relative !important;
}
.img-absolute-parent img.img-absolute {
  position: absolute !important;
}
.img-absolute-parent img.img-pos-left {
  left: 0 !important;
}
.img-absolute-parent img.img-pos-top {
  top: 0 !important;
}
.img-absolute-parent img.img-pos-bottom {
  bottom: 0 !important;
}
.img-absolute-parent img.img-pos-right {
  right: 0 !important;
}
.img-absolute-parent img.img-pos-center {
  left: 0 !important;
  right: 0 !important;
  margin: 0 auto !important;
}
/* -------- Responsive Classes ---------- */
/* Small Devices, Tablets */
@media only screen and (max-width: 991px) {
  .sm-display-block {
    display: block !important;
  }
  .sm-text-center {
    text-align: center !important;
  }
  .sm-text-right {
    text-align: right !important;
  }
  .sm-text-left {
    text-align: left !important;
  }
  .sm-pull-none,
  .pull-left.flip.sm-pull-none,
  .pull-right.flip.sm-pull-none {
    float: none !important;
  }
  .sm-pull-left {
    float: left !important;
  }
  .sm-pull-right {
    float: right !important;
  }
  .sm-fullwidth {
    width: 100%;
  }
  .sm-height-auto {
    min-height: auto !important;
  }
  .sm-hide-bg-img {
    background: none !important;
  }
  .maxwidth400 {
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;
  }
  .maxwidth500 {
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
  }
  .maxwidth600 {
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
  }
}
/* Small Devices, Tablets */
@media only screen and (max-width: 767px) {
  .xs-display-block {
    display: block;
  }
  .xs-text-center {
    text-align: center !important;
  }
  .xs-text-right {
    text-align: right !important;
  }
  .xs-text-left {
    text-align: left !important;
  }
  .xs-fullwidth {
    width: 100%;
  }
  .xs-height-auto {
    min-height: auto !important;
  }
  .xs-hide-bg-img {
    background: none !important;
  }
  .xs-pull-none {
    float: none !important;
  }
  .xs-pull-left {
    float: left !important;
  }
  .xs-pull-right {
    float: right !important;
  }
  .xs-pull-center {
    display: table;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .xs-list-inline-none li {
    display: table;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 20px !important;
    padding-left: 0 !important;
  }
}
/* Extra Small Devices, Phones */
@media only screen and (max-width: 479px) {
  .xxs-text-center {
    text-align: center !important;
  }
  .xxs-height-auto {
    min-height: auto !important;
  }
}
/* -------- Opening Hourse ---------- */
.opening-hours ul li {
  padding-bottom: 10px !important;
}
/* -------- Multi Row Clearfix ---------- */
.multi-row-clearfix .col-xs-6:nth-child(2n + 3) {
  clear: left;
}
.multi-row-clearfix .col-xs-4:nth-child(3n + 4) {
  clear: left;
}
.multi-row-clearfix .col-xs-3:nth-child(4n + 5) {
  clear: left;
}
.multi-row-clearfix .col-xs-2:nth-child(6n + 7) {
  clear: left;
}
.multi-row-clearfix .col-xs-1:nth-child(12n + 13) {
  clear: left;
}
@media (min-width: 768px) {
  /* reset previous grid */
  .multi-row-clearfix .col-xs-6:nth-child(2n + 3) {
    clear: none;
  }
  .multi-row-clearfix .col-xs-4:nth-child(3n + 4) {
    clear: none;
  }
  .multi-row-clearfix .col-xs-3:nth-child(4n + 5) {
    clear: none;
  }
  .multi-row-clearfix .col-xs-2:nth-child(6n + 7) {
    clear: none;
  }
  .multi-row-clearfix .col-xs-1:nth-child(12n + 13) {
    clear: none;
  }
  /* clear first in row for small columns */
  .multi-row-clearfix .col-sm-6:nth-child(2n + 3) {
    clear: left;
  }
  .multi-row-clearfix .col-sm-4:nth-child(3n + 4) {
    clear: left;
  }
  .multi-row-clearfix .col-sm-3:nth-child(4n + 5) {
    clear: left;
  }
  .multi-row-clearfix .col-sm-2:nth-child(6n + 7) {
    clear: left;
  }
  .multi-row-clearfix .col-sm-1:nth-child(12n + 13) {
    clear: left;
  }
}
@media (min-width: 992px) {
  /* reset previous grid */
  .multi-row-clearfix .col-sm-6:nth-child(2n + 3) {
    clear: none;
  }
  .multi-row-clearfix .col-sm-4:nth-child(3n + 4) {
    clear: none;
  }
  .multi-row-clearfix .col-sm-3:nth-child(4n + 5) {
    clear: none;
  }
  .multi-row-clearfix .col-sm-2:nth-child(6n + 7) {
    clear: none;
  }
  .multi-row-clearfix .col-sm-1:nth-child(12n + 13) {
    clear: none;
  }
  /* clear first in row for medium columns */
  .multi-row-clearfix .col-md-6:nth-child(2n + 3) {
    clear: left;
  }
  .multi-row-clearfix .col-md-4:nth-child(3n + 4) {
    clear: left;
  }
  .multi-row-clearfix .col-md-3:nth-child(4n + 5) {
    clear: left;
  }
  .multi-row-clearfix .col-md-2:nth-child(6n + 7) {
    clear: left;
  }
  .multi-row-clearfix .col-md-1:nth-child(12n + 13) {
    clear: left;
  }
}
@media (min-width: 1200px) {
  /* reset previous grid */
  .multi-row-clearfix .col-md-6:nth-child(2n + 3) {
    clear: none;
  }
  .multi-row-clearfix .col-md-4:nth-child(3n + 4) {
    clear: none;
  }
  .multi-row-clearfix .col-md-3:nth-child(4n + 5) {
    clear: none;
  }
  .multi-row-clearfix .col-md-2:nth-child(6n + 7) {
    clear: none;
  }
  .multi-row-clearfix .col-md-1:nth-child(12n + 13) {
    clear: none;
  }
  /* clear first in row for large columns */
  .multi-row-clearfix .col-lg-6:nth-child(2n + 3) {
    clear: left;
  }
  .multi-row-clearfix .col-lg-4:nth-child(3n + 4) {
    clear: left;
  }
  .multi-row-clearfix .col-lg-3:nth-child(4n + 5) {
    clear: left;
  }
  .multi-row-clearfix .col-lg-2:nth-child(6n + 7) {
    clear: left;
  }
  .multi-row-clearfix .col-lg-1:nth-child(12n + 13) {
    clear: left;
  }
}

/*# sourceMappingURL=utility-classes.css.map */