.rdt_TableHeader {
  display: none !important;
}

.table_header {
  display: flex;
  padding: 1rem;
  padding-left: 0px;

  > div {
    flex: 1;
  }

  .table-header-buttons {
    display: flex;
    justify-content: flex-end;

    .upload-wrapper,
    button {
      margin-left: 5px;
    }

    button {
      min-width: 100px;
      margin-left: 15px;
    }
  }

  .SearchWrapper {
    width: 250px;
    position: relative;
    .tableSearchField {
      width: 100%;
    }

    .searchIconWrapper {
      position: absolute;
      top: 50%;
      right: 5px;
      transform: translate(-50%, -50%);
      color: #e2e4e6;
    }
  }

  .user-filterBtn-wrap {
    display: flex;
    justify-content: flex-end;
  }
}

.rdt_TableHeadRow {
  .rdt_TableCol {
    font-weight: 600;
    color: #495057;
    vertical-align: middle;
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 1.2rem;
    padding: 0.75rem;
  }
}

.table_bottom_wrap {
  display: flex;
  padding-top: 0.5rem;

  .page_dd_wrap {
    flex: 2;
    display: flex;
    align-items: center;

    label {
      padding-right: 10px;
      margin: 0;
      font-weight: 500;
    }

    select {
      width: 90px;
      border-radius: 3px;
    }
    .total-records {
      margin-left: 20px;
      font-weight: 500;
    }

    .perPage-wrap {
      display: flex;
    }
  }

  .pagination_wrap {
    flex: 5;
    display: flex;
    justify-content: flex-end;

    .pagination {
      margin: 0;
    }
  }
}

.rdt_Table {
  border: 1px solid #f5f6f8;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 1.3rem;
  .rdt_TableCol:not(:last-child),
  .rdt_TableCell:not(:last-child) {
    border-right: 1px solid #f5f6f8;
  }

  .rdt_TableCell {
    padding: 5px 0.75rem;
    font-size: 1.3rem;
    font-weight: 400;
  }

  .rdt_TableRow,
  .rdt_TableHeadRow {
    border-bottom-color: #f5f6f8 !important;
  }
}

@media only screen and (max-width: 767px) {
  .table_header {
    flex-direction: column;
    padding: 5px 0px 5px 0px;

    > div {
      padding: 5px 0px;
    }

    .table-header-buttons {
      justify-content: flex-start;

      button {
        margin-left: 0px;
        margin-right: 15px;
      }
    }

    .user-filterBtn-wrap {
      justify-content: flex-start;
    }
  }

  .table_bottom_wrap {
    flex-direction: column;

    > div {
      padding: 5px 0px;
      justify-content: center;
    }

    .pagination_wrap {
      justify-content: center;
    }

    .page_dd_wrap {
      display: block;

      > div {
        justify-content: center;
        margin-bottom: 5px;
        display: flex;
      }
    }
  }
}
