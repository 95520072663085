/* --------------------------------------
@Author: ThemeMascot
@URL: http://themeforest.net/user/ThemeMascot

COLOR : Orange
COLOR CODE: #FF670E
----------------------------------------- */
/* text-theme-color */
.text-theme-colored {
  color: #ff670e !important;
}

.darkTextColor {
  color: #000;
}

.segoeFont {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.widget .twitter-feed li::after {
  color: #ff670e;
}
.widget .tags a:hover,
.widget.dark .tags a:hover {
  background: #ff670e;
}
.widget .twitter-feed li::after,
.widget .address li i,
.widget.dark .nav-tabs li.active a,
.widget .post-title a:hover {
  color: #ff670e;
}
.widget .line-bottom:after {
  border: 1px solid #ff670e;
}
.product .price .amount {
  color: #ff670e;
}
/* text-hover-theme-color */
.text-hover-theme-colored:hover {
  color: #ff670e !important;
}
/* background-theme-color */
.bg-theme-colored {
  background-color: #ff670e !important;
}
.work-gallery .gallery-thumb .gallery-overlay {
  background: rgba(255, 87, 34, 0.7);
}
.bg-theme-colored-lighter2 {
  background-color: #ff6331 !important;
}
.bg-theme-colored-lighter3 {
  background-color: #ff6a3c !important;
}
.bg-theme-colored-lighter4 {
  background-color: #ff7246 !important;
}
.bg-theme-colored-darker2 {
  background-color: #ff4b13 !important;
}
.bg-theme-colored-darker3 {
  background-color: #ff4408 !important;
}
.bg-theme-colored-darker4 {
  background-color: #fd3d00 !important;
}
/* background-color-transparent */
.bg-theme-colored-transparent-9 {
  background-color: rgba(255, 87, 34, 0.9);
}
.bg-theme-colored-transparent-8 {
  background-color: rgba(255, 87, 34, 0.8);
}
.bg-theme-colored-transparent-7 {
  background-color: rgba(255, 87, 34, 0.7);
}
.bg-theme-colored-transparent-6 {
  background-color: rgba(255, 87, 34, 0.6);
}
.bg-theme-colored-transparent-5 {
  background-color: rgba(255, 87, 34, 0.5);
}
.bg-theme-colored-transparent-4 {
  background-color: rgba(255, 87, 34, 0.4);
}
.bg-theme-colored-transparent-3 {
  background-color: rgba(255, 87, 34, 0.3);
}
.bg-theme-colored-transparent-2 {
  background-color: rgba(255, 87, 34, 0.2);
}
.bg-theme-colored-transparent-1 {
  background-color: rgba(255, 87, 34, 0.1);
}
.bg-theme-colored-transparent,
.piechart-block {
  background-color: rgba(255, 87, 34, 0.75) !important;
}
/* bg-hover-theme-color */
.bg-hover-theme-colored:hover {
  background: #ff670e !important;
  border-color: #ff670e !important;
  color: #fff !important;
}
.bg-hover-theme-colored:hover h1,
.bg-hover-theme-colored:hover h2,
.bg-hover-theme-colored:hover h3,
.bg-hover-theme-colored:hover h4,
.bg-hover-theme-colored:hover h5,
.bg-hover-theme-colored:hover h6,
.bg-hover-theme-colored:hover p,
.bg-hover-theme-colored:hover a,
.bg-hover-theme-colored:hover i {
  color: #fff !important;
}
.portfolio-filter a.active,
.portfolio-filter a:hover {
  background: #ff670e;
}
.pagination.theme-colored li.active a {
  background: #ff670e;
  color: #fff !important;
}
.pagination.theme-colored > li > a,
.pagination.theme-colored > li > span {
  color: #ff670e !important;
}
/* box-shadow */
.box-shadow,
.icon-box .icon.icon-border-effect::after {
  -webkit-box-shadow: #ff670e;
  -moz-box-shadow: #ff670e;
  box-shadow: 0 0 0 3px #ff670e;
}
.outline-border,
.clients-logo img {
  outline: 2px solid;
  outline-offset: 5px;
}
/*Theme Colored Red Text-color*/
.work-gallery .gallery-bottom-part .title {
  color: #ff670e;
}
.work-gallery .gallery-thumb .styled-icons a:hover,
.work-gallery:hover .gallery-bottom-part {
  background: #ff670e;
}
.work-gallery .gallery-thumb .gallery-overlay {
  background: rgba(17, 17, 17, 0.7);
}
.outline-border {
  outline-color: #ff670e;
}
/* border-theme-color */
.border-theme-colored {
  border-color: #ff670e !important;
}
.border-top-theme-colored {
  border-top: 1px solid #ff670e !important;
}
.member-info {
  border-top: 3px outset #ff670e;
}
.border-bottom-theme-colored {
  border-bottom: 1px solid #ff670e !important;
}
.border-effect::after {
  border: 3px solid #abc502;
}
.border-theme-colored {
  border: 1px solid #ff670e !important;
}
/* background-color-transparent */
.bg-theme-colored-transparent,
.post .entry-meta.meta-absolute,
.schedule-box .thumb .overlay,
.hvr-bounce-to-left::before,
.hvr-bounce-to-right::before {
  background-color: rgba(255, 87, 34, 0.8) !important;
}
/*
 * background: theme-color
 * -----------------------------------------------
*/
.sidebar-widget .tags a:hover,
.sidebar .tags a:hover,
.drop-caps .colored-square,
.horizontal-tab-centered .nav-pills > li > a:hover,
.nav-pills > li > a:hover,
.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus,
.table.table-bordered.class-time td:hover,
.line-bottom::after,
.line-bottom-centered::after,
.line-top-right::after,
.icon-switcher {
  background: #ff670e;
}
.stylish-header,
.stylish-header:before {
  background: rgba(255, 87, 34, 0.9);
}
.header-nav-floating .menuzord.green .menuzord-menu > li.active > a,
.header-nav-floating .menuzord.green .menuzord-menu > li:hover > a,
.header-nav-floating .menuzord.green .menuzord-menu ul.dropdown li:hover > a {
  background-color: #ee3900;
}
.service-icon-box a {
  background: rgba(255, 87, 34, 0.9);
}
.service-icon-box:hover a {
  background: rgba(255, 87, 34, 0.95);
}
.testimonial.style1 .comment {
  background: rgba(255, 87, 34, 0.85);
}
.tag-sale {
  background-color: #ff670e;
}
.star-rating span::before {
  color: #ff670e !important;
}
/*
 * text: theme-color
 * -----------------------------------------------
*/
.title,
ul.list li::before,
.post .entry-content .entry-title {
  color: #ff670e;
}
/*
 * border: theme-color
 * -----------------------------------------------
*/
.clients-logo img {
  border: 1px solid #ff670e;
}
.lll {
  border: #ff670e;
}
.border-10px {
  border: 10px solid #ff670e;
}
/* border-left-color */
blockquote {
  border-left: 3px solid #ff670e;
}
/* border-top-color */
.stylish-header:after {
  -webkit-border-top: 80px solid #ff5f2c;
  -moz-border-top: 80px solid #ff5f2c;
  -ms-border-top: 80px solid #ff5f2c;
  -o-border-top: 80px solid #ff5f2c;
  border-top: 80px solid #ff5f2c;
}
/*
 * btn-default: theme-color
 * -----------------------------------------------
*/
.btn-default.btn-theme-colored {
  color: #ff670e;
  background-color: #fff;
  border-color: #ff670e;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.btn-default.btn-theme-colored:focus,
.btn-default.btn-theme-colored.focus {
  color: #ff670e;
  background-color: #e6e6e6;
  border-color: #a22700;
}
.btn-default.btn-theme-colored:hover {
  color: #ff670e;
  background-color: #e6e6e6;
  border-color: #e43700;
}
.btn-default.btn-theme-colored:active,
.btn-default.btn-theme-colored.active,
.open > .dropdown-toggle.btn-default.btn-theme-colored {
  color: #ff670e;
  background-color: #e6e6e6;
  border-color: #e43700;
}
.btn-default.btn-theme-colored:active:hover,
.btn-default.btn-theme-colored.active:hover,
.open > .dropdown-toggle.btn-default.btn-theme-colored:hover,
.btn-default.btn-theme-colored:active:focus,
.btn-default.btn-theme-colored.active:focus,
.open > .dropdown-toggle.btn-default.btn-theme-colored:focus,
.btn-default.btn-theme-colored:active.focus,
.btn-default.btn-theme-colored.active.focus,
.open > .dropdown-toggle.btn-default.btn-theme-colored.focus {
  color: #ff670e;
  background-color: #d4d4d4;
  border-color: #a22700;
}
.btn-default.btn-theme-colored:active,
.btn-default.btn-theme-colored.active,
.open > .dropdown-toggle.btn-default.btn-theme-colored {
  background-image: none;
}
.btn-default.btn-theme-colored.disabled:hover,
.btn-default.btn-theme-colored[disabled]:hover,
fieldset[disabled] .btn-default.btn-theme-colored:hover,
.btn-default.btn-theme-colored.disabled:focus,
.btn-default.btn-theme-colored[disabled]:focus,
fieldset[disabled] .btn-default.btn-theme-colored:focus,
.btn-default.btn-theme-colored.disabled.focus,
.btn-default.btn-theme-colored[disabled].focus,
fieldset[disabled] .btn-default.btn-theme-colored.focus {
  background-color: #fff;
  border-color: #ff670e;
}
.btn-default.btn-theme-colored .badge {
  color: #fff;
  background-color: #ff670e;
}
.btn-default.btn-theme-colored:hover,
.btn-default.btn-theme-colored:active,
.btn-default.btn-theme-colored:focus {
  background-color: #ff670e;
  border-color: #ff670e;
  color: #fff;
}
/*
 * btn-border
 * -----------------------------------------------
*/
.btn-border.btn-theme-colored {
  color: #ff670e;
  background-color: #fff;
  border-color: #ff670e;
}
.btn-border.btn-theme-colored:focus,
.btn-border.btn-theme-colored.focus {
  color: #ff670e;
  background-color: #e6e6e6;
  border-color: #a22700;
}
.btn-border.btn-theme-colored:hover {
  color: #ff670e;
  background-color: #e6e6e6;
  border-color: #e43700;
}
.btn-border.btn-theme-colored:active,
.btn-border.btn-theme-colored.active,
.open > .dropdown-toggle.btn-border.btn-theme-colored {
  color: #ff670e;
  background-color: #e6e6e6;
  border-color: #e43700;
}
.btn-border.btn-theme-colored:active:hover,
.btn-border.btn-theme-colored.active:hover,
.open > .dropdown-toggle.btn-border.btn-theme-colored:hover,
.btn-border.btn-theme-colored:active:focus,
.btn-border.btn-theme-colored.active:focus,
.open > .dropdown-toggle.btn-border.btn-theme-colored:focus,
.btn-border.btn-theme-colored:active.focus,
.btn-border.btn-theme-colored.active.focus,
.open > .dropdown-toggle.btn-border.btn-theme-colored.focus {
  color: #ff670e;
  background-color: #d4d4d4;
  border-color: #a22700;
}
.btn-border.btn-theme-colored:active,
.btn-border.btn-theme-colored.active,
.open > .dropdown-toggle.btn-border.btn-theme-colored {
  background-image: none;
}
.btn-border.btn-theme-colored.disabled:hover,
.btn-border.btn-theme-colored[disabled]:hover,
fieldset[disabled] .btn-border.btn-theme-colored:hover,
.btn-border.btn-theme-colored.disabled:focus,
.btn-border.btn-theme-colored[disabled]:focus,
fieldset[disabled] .btn-border.btn-theme-colored:focus,
.btn-border.btn-theme-colored.disabled.focus,
.btn-border.btn-theme-colored[disabled].focus,
fieldset[disabled] .btn-border.btn-theme-colored.focus {
  background-color: #fff;
  border-color: #ff670e;
}
.btn-border.btn-theme-colored .badge {
  color: #fff;
  background-color: #ff670e;
}
.btn-border.btn-theme-colored:hover,
.btn-border.btn-theme-colored:active,
.btn-border.btn-theme-colored:focus {
  background-color: #ee3900;
  border-color: #ee3900;
  color: #fff;
}
/*
 * btn-dark
 * -----------------------------------------------
*/
.btn-dark.btn-theme-colored {
  color: #fff;
  background-color: #ff670e;
  border-color: #ff670e;
}
.btn-dark.btn-theme-colored:focus,
.btn-dark.btn-theme-colored.focus {
  color: #fff;
  background-color: #ee3900;
  border-color: #a22700;
}
.btn-dark.btn-theme-colored:hover {
  color: #fff;
  background-color: #ee3900;
  border-color: #e43700;
}
.btn-dark.btn-theme-colored:active,
.btn-dark.btn-theme-colored.active,
.open > .dropdown-toggle.btn-dark.btn-theme-colored {
  color: #fff;
  background-color: #ee3900;
  border-color: #e43700;
}
.btn-dark.btn-theme-colored:active:hover,
.btn-dark.btn-theme-colored.active:hover,
.open > .dropdown-toggle.btn-dark.btn-theme-colored:hover,
.btn-dark.btn-theme-colored:active:focus,
.btn-dark.btn-theme-colored.active:focus,
.open > .dropdown-toggle.btn-dark.btn-theme-colored:focus,
.btn-dark.btn-theme-colored:active.focus,
.btn-dark.btn-theme-colored.active.focus,
.open > .dropdown-toggle.btn-dark.btn-theme-colored.focus {
  color: #fff;
  background-color: #ca3100;
  border-color: #a22700;
}
.btn-dark.btn-theme-colored:active,
.btn-dark.btn-theme-colored.active,
.open > .dropdown-toggle.btn-dark.btn-theme-colored {
  background-image: none;
}
.btn-dark.btn-theme-colored.disabled:hover,
.btn-dark.btn-theme-colored[disabled]:hover,
fieldset[disabled] .btn-dark.btn-theme-colored:hover,
.btn-dark.btn-theme-colored.disabled:focus,
.btn-dark.btn-theme-colored[disabled]:focus,
fieldset[disabled] .btn-dark.btn-theme-colored:focus,
.btn-dark.btn-theme-colored.disabled.focus,
.btn-dark.btn-theme-colored[disabled].focus,
fieldset[disabled] .btn-dark.btn-theme-colored.focus {
  background-color: #ff670e;
  border-color: #ff670e;
}
.btn-dark.btn-theme-colored .badge {
  color: #ff670e;
  background-color: #fff;
}
/*
 * btn-gray
 * -----------------------------------------------
*/
.btn-gray.btn-theme-colored {
  color: #fff;
  background-color: #ff7e55;
  border-color: #ff7e55;
}
.btn-gray.btn-theme-colored:focus,
.btn-gray.btn-theme-colored.focus {
  color: #fff;
  background-color: #ff670e;
  border-color: #d43300;
}
.btn-gray.btn-theme-colored:hover {
  color: #fff;
  background-color: #ff670e;
  border-color: #ff4f18;
}
.btn-gray.btn-theme-colored:active,
.btn-gray.btn-theme-colored.active,
.open > .dropdown-toggle.btn-gray.btn-theme-colored {
  color: #fff;
  background-color: #ff670e;
  border-color: #ff4f18;
}
.btn-gray.btn-theme-colored:active:hover,
.btn-gray.btn-theme-colored.active:hover,
.open > .dropdown-toggle.btn-gray.btn-theme-colored:hover,
.btn-gray.btn-theme-colored:active:focus,
.btn-gray.btn-theme-colored.active:focus,
.open > .dropdown-toggle.btn-gray.btn-theme-colored:focus,
.btn-gray.btn-theme-colored:active.focus,
.btn-gray.btn-theme-colored.active.focus,
.open > .dropdown-toggle.btn-gray.btn-theme-colored.focus {
  color: #fff;
  background-color: #fd3d00;
  border-color: #d43300;
}
.btn-gray.btn-theme-colored:active,
.btn-gray.btn-theme-colored.active,
.open > .dropdown-toggle.btn-gray.btn-theme-colored {
  background-image: none;
}
.btn-gray.btn-theme-colored.disabled:hover,
.btn-gray.btn-theme-colored[disabled]:hover,
fieldset[disabled] .btn-gray.btn-theme-colored:hover,
.btn-gray.btn-theme-colored.disabled:focus,
.btn-gray.btn-theme-colored[disabled]:focus,
fieldset[disabled] .btn-gray.btn-theme-colored:focus,
.btn-gray.btn-theme-colored.disabled.focus,
.btn-gray.btn-theme-colored[disabled].focus,
fieldset[disabled] .btn-gray.btn-theme-colored.focus {
  background-color: #ff7e55;
  border-color: #ff7e55;
}
.btn-gray.btn-theme-colored .badge {
  color: #ff7e55;
  background-color: #fff;
}
/*
 * btn-primary: theme-color
 * -----------------------------------------------
*/
.btn-theme-colored {
  color: #fff;
  background-color: #ff670e;
  border-color: #ff670e;
}
.btn-theme-colored:focus,
.btn-theme-colored.focus {
  color: #fff;
  background-color: #ee3900;
  border-color: #a22700;
}
.btn-theme-colored:hover {
  color: #fff;
  background-color: #ee3900;
  border-color: #e43700;
}
.btn-theme-colored:active,
.btn-theme-colored.active,
.open > .dropdown-toggle.btn-theme-colored {
  color: #fff;
  background-color: #ee3900;
  border-color: #e43700;
}
.btn-theme-colored:active:hover,
.btn-theme-colored.active:hover,
.open > .dropdown-toggle.btn-theme-colored:hover,
.btn-theme-colored:active:focus,
.btn-theme-colored.active:focus,
.open > .dropdown-toggle.btn-theme-colored:focus,
.btn-theme-colored:active.focus,
.btn-theme-colored.active.focus,
.open > .dropdown-toggle.btn-theme-colored.focus {
  color: #fff;
  background-color: #ca3100;
  border-color: #a22700;
}
.btn-theme-colored:active,
.btn-theme-colored.active,
.open > .dropdown-toggle.btn-theme-colored {
  background-image: none;
}
.btn-theme-colored.disabled:hover,
.btn-theme-colored[disabled]:hover,
fieldset[disabled] .btn-theme-colored:hover,
.btn-theme-colored.disabled:focus,
.btn-theme-colored[disabled]:focus,
fieldset[disabled] .btn-theme-colored:focus,
.btn-theme-colored.disabled.focus,
.btn-theme-colored[disabled].focus,
fieldset[disabled] .btn-theme-colored.focus {
  background-color: #ff670e;
  border-color: #ff670e;
}
.btn-theme-colored .badge {
  color: #ff670e;
  background-color: #fff;
}
/*
 * btn-transparent
 * -----------------------------------------------
*/
.btn-transparent {
  background-color: transparent;
  color: #fff;
}
.btn-transparent.btn-theme-colored {
  background-color: transparent;
}
.btn-transparent.btn-dark {
  background-color: #111111;
  border-color: #111111;
}
.btn-transparent.btn-dark:hover {
  background-color: #000000;
  border-color: #000000;
}
.btn-transparent.btn-dark.btn-theme-colored {
  background-color: #ff670e;
  border-color: #ff670e;
}
.btn-transparent.btn-dark.btn-theme-colored:hover {
  background-color: #ee3900;
  border-color: #ee3900;
}
.btn-transparent.btn-border {
  background-color: transparent;
  border-color: #eeeeee;
}
.btn-transparent.btn-border:hover {
  background-color: #eeeeee;
  color: #000;
}
.btn-transparent.btn-border.btn-theme-colored {
  background-color: transparent;
  border-color: #ff670e;
}
.btn-transparent.btn-border.btn-theme-colored:hover {
  background-color: #ff670e;
  color: #fff;
}
.btn-gray.btn-transparent {
  background-color: #d3d3d3;
  color: #333333;
}
.btn-gray.btn-transparent:hover {
  background-color: #ececec;
  color: #1a1a1a;
}
.btn-gray.btn-transparent.btn-theme-colored {
  background-color: #ff7e55;
  color: #fff;
}
.btn-gray.btn-transparent.btn-theme-colored:hover {
  background-color: #ee3900;
  color: #e6e6e6;
}
.btn-hover-theme-colored:hover {
  background-color: #ff670e;
  border-color: #ff670e;
  color: #fff;
}
/*
 * Shortcode: styled-icons Theme Colored
 * -----------------------------------------------
*/
.icon-theme-colored a {
  color: #ff670e;
}
.icon-theme-colored.icon-bordered a {
  border-color: #ff670e;
}
.icon-theme-colored.icon-bordered a:hover {
  background-color: #ff670e;
  color: #fff;
}
.icon-theme-colored.icon-dark a {
  background-color: #ff670e;
  color: #fff;
}
.icon-theme-colored.icon-dark a:hover {
  background-color: #ee3900;
  color: #e6e6e6;
}
.icon-theme-colored.icon-dark.icon-bordered a {
  border-color: #ff670e;
  color: #ff670e;
}
.icon-theme-colored.icon-dark.icon-bordered a:hover {
  background-color: #ff670e;
  border-color: #ff670e;
  color: #fff;
}
.icon-theme-colored.icon-gray a {
  color: #ff670e;
}
.icon-theme-colored.icon-gray a:hover {
  color: #ee3900;
}
.icon-theme-colored.icon-gray.icon-bordered a {
  color: #ff670e;
}
.icon-theme-colored.icon-gray.icon-bordered a:hover {
  background-color: #eeeeee;
  border-color: #eeeeee;
  color: #ff670e;
}
.icon-theme-colored a:hover {
  color: #ee3900;
}
.icon-hover-theme-colored a:hover {
  background-color: #ff670e;
  border-color: #ff670e;
  color: #fff;
}
.styled-icons.icon-hover-theme-colored a:hover {
  background-color: #ff670e;
  border-color: #ff670e;
  color: #fff;
}
/*
 * Shortcode: Icon Boxes Theme Colored
 * -----------------------------------------------
*/
.icon-box.iconbox-theme-colored .icon {
  color: #ff670e;
}
.icon-box.iconbox-theme-colored .icon.icon-border-effect::after {
  box-shadow: 0 0 0 3px #ff670e;
}
.icon-box.iconbox-theme-colored .icon.icon-bordered {
  border-color: #ff670e;
}
.icon-box.iconbox-theme-colored .icon.icon-bordered:hover {
  background-color: #ff670e;
  color: #fff;
}
.icon-box.iconbox-theme-colored .icon.icon-gray:hover {
  background-color: #ff670e;
  color: #fff;
}
.icon-box.iconbox-theme-colored .icon.icon-gray.icon-bordered {
  border-color: #eeeeee;
}
.icon-box.iconbox-theme-colored .icon.icon-gray.icon-bordered:hover {
  background-color: #ff670e;
  border-color: #ff670e;
  color: #fff;
}
.icon-box.iconbox-theme-colored .icon.icon-dark {
  background-color: #ff670e;
  color: #fff;
}
.icon-box.iconbox-theme-colored .icon.icon-dark:hover {
  color: #fff;
}
.icon-box.iconbox-theme-colored .icon.icon-dark.icon-bordered {
  background-color: transparent;
  border-color: #ff670e;
  color: #ff670e;
}
.icon-box.iconbox-theme-colored .icon.icon-dark.icon-bordered:hover {
  background-color: #ff670e;
  border-color: #ff670e;
  color: #fff;
}
.icon-box.iconbox-theme-colored .icon.icon-white {
  background-color: #fff;
  color: #ff670e;
}
.icon-box.iconbox-theme-colored .icon.icon-white:hover {
  background-color: #ff670e;
  color: #fff;
}
.icon-box.iconbox-theme-colored .icon.icon-white.icon-bordered {
  background-color: transparent;
  border-color: #ff670e;
  color: #fff;
}
.icon-box.iconbox-theme-colored .icon.icon-white.icon-bordered:hover {
  background-color: #ff670e;
  border-color: #ff670e;
  color: #fff;
}
.icon-box.iconbox-theme-colored.iconbox-border {
  border-color: #ff670e;
}
