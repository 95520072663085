@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');


body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

code {
  font-family: 'Montserrat', sans-serif;
}
